import React, { useEffect, useState } from "react";
import {  useParams } from "react-router-dom";
import error from "../../assets/error.png";
import error404 from "../../assets/404.png";
import Typography from '@mui/material/Typography';

export default function ErrorED() {
  
  const [errorMessage, setErrorMessage] = useState(["Errore"])
  var {errorCode } = useParams();  
  
  useEffect(() => {          
    console.log("if",errorCode);     
    if(errorCode!==undefined){
        if(errorCode==="404"){
            setErrorMessage("File non trovato");
        }
        else if(errorCode==="200"){
            setErrorMessage("Nessun fascicolo presente in modalità preview");
        }
        else if(errorCode==="201"){
            setErrorMessage("E' necessario bloccare il fascicolo prima di poterlo consolidare");
        }
        else{
            setErrorMessage("Errore");
        }
    }
    else{
        errorCode="-1";
        setErrorMessage("Errore");
    }
}, []);



  return (
    <div className="content" style = {{alignItems: "center", display: "flex", height: "100%",  justifyContent: "center"}}>
      <div className="AppLogin" style = {{height: "50%"}}>
          {errorCode ==="404" ?(
            <img src={error404} className="logo1" alt="Logo" />
          ):(
            <img src={error} className="logo1" alt="Logo" />
          )}
          <Typography variant="h4" style={{textAlign: "center",paddingBottom:"10px",paddingTop:"20px"}}>
                {errorMessage}
              </Typography>                
      </div>
      </div>
  );
  
  }  
