import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import {ThreeDots} from 'react-loader-spinner';
//import { toast } from 'react-toastify';
import {properties} from "../../utils/properties";
import SweetAlert from "react-bootstrap-sweetalert";
import '../../assets/bootstrap-custom.css';

export default function AttachmentsDownloader() {   
    const [alert, setAlert] = useState(null); 
    let {idDossier} = useParams();
    
    const showAlert = () => {
        console.log("showing alert");
        setAlert(
          <SweetAlert
          warning
          style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
          title="Scaricare gli allegati della seduta?"
          onConfirm={() => handleDownloading()}
          onCancel={() => { navigate(-1) }}        
          confirmBtnCssClass="custom-btn custom-btn-success" 
          cancelBtnCssClass="custom-btn custom-btn-cancel"
          confirmBtnText="Si"
          cancelBtnText="No"
          showCancel
        >
        </SweetAlert>
        );
    };

    const handleStarting = () => {                
        console.log("handleStarting");        
        //setAlert(null);    
        showAlert();

    }

    const handleDownloading = () => {  
        console.log("handleDownloading");   
        setAlert(null);  
        if(window.location.href.endsWith("/last")||window.location.href.endsWith("/last/")){        
            console.log("ends with last");   
            resolveLast();
        }
        else{
            console.log("ends with an id");   
            //scaricaAllegati();
            handleDownloads();
        }
    }



    const navigate = useNavigate();
    const resolveLast = function(){        
        //axios.get(properties.productionUrl+'/rest/getLast?edited')
        let endpoint = properties.productionUrl+"/php_demo/core/rest/getLast.php?edited";                         
        axios({
            method: 'get',
            url: endpoint,                
            withCredentials: true,                 
        })
        
        .then(res => {        
            idDossier=res.data;
            //scaricaAllegati();        
            handleDownloads();
        })
    }
    /*
    const scaricaAllegati = function(){       
        let endpoint = properties.productionUrl+'/postIDSF?IDFDL='+idDossier;
        axios({
            method: 'get',
            url: endpoint
        })
        .then(function (response) {
            console.log(response);                 
            
            navigate("/fascicoli/"+idDossier,{ replace: true });  
        })
        .catch(function (response) {            
            console.log("CATCH",response);
        });

    }
    */

    const handleDownloads = () => {
        //const issueName = `Seduta del ${format(new Date(_inviatationDate), 'dd-MM-yyyy')}`
        
        console.log("handleDownloads");                
        //let endpoint = properties.productionUrl+"/rest/doCons";                        
        let endpoint = properties.productionUrl+"/php_demo/core/rest/doCons.php";      
        var bodyFormData=new FormData();                      
        bodyFormData.append('action', 'scaricaAllegati');    
        bodyFormData.append('idf', idDossier);    
        axios({
            method: 'post',
            url: endpoint,
            data: bodyFormData,
            withCredentials: true,  
            headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(function (response) {            
            localStorage.removeItem("meeting");
            localStorage.removeItem("meetingContent");
            localStorage.removeItem("meetingVersion");   
            //TODO MOSTRARE      SCARICAMENTO OK      
            setAlert(
                <SweetAlert
                warning
                closeOnClickOutside ={true}
                showCancel ={false}
                showConfirm={false}
                style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
                title={"Gli allegati sono stati scaricati correttamente."}
                onConfirm={() => {
                    setAlert(null);
                    navigate("/fascicoli/"+idDossier,{ replace: true });
                }}           
                onCancel={() => {
                    setAlert(null);
                    navigate("/fascicoli/"+idDossier,{ replace: true });
                }}
              >
              </SweetAlert>
              );   
            //navigate("/fascicoli/"+idDossier,{ replace: true });  
        })
        .catch(function (response) {   
            //TODO MOSTRARE      SCARICAMENTO KO         
            //toast.error("Si è verificato un errore pubblicando la seduta!");   
            setAlert(
                <SweetAlert
                warning
                closeOnClickOutside ={true}
                showCancel ={false}
                showConfirm={false}
                style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
                title={"Si è verificato un errore scaricando gli allegati. "}
                onConfirm={() => {
                    setAlert(null);
                    //navigate("/fascicoli/"+idDossier,{ replace: true });
                }}           
                onCancel={() => {
                    setAlert(null);
                    //navigate("/fascicoli/"+idDossier,{ replace: true });
                }}
              >
              </SweetAlert>
              );            
        });
    }








  useEffect(() => {    
    console.log(window.location.href); 
    console.log(idDossier);   
    handleStarting();

    }, []);





return (
  <div key={uuidv4()}> 
        
            <div key={uuidv4()} style={{ width:"25%",   display: "flex", 
            marginLeft: "auto", marginRight: "auto" }}> 
             <ThreeDots color="#30720f" height='350' width='150'/>
             </div> 
             <div>
                {alert}        
            </div>
    </div>
    );
}
