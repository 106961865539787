import React, { useState, useEffect, useRef, useContext } from 'react'
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom'
import axios from 'axios'
import RouterContext from "../../context/RouterContext";
import { Tree, getBackendOptions } from "@minoru/react-dnd-treeview";
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import Select from 'react-select';
import {properties} from "../../utils/properties";
import {AiOutlineFileWord} from "react-icons/ai";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
import { List } from 'react-virtualized';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import { Fab, ListItem, Tooltip } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import qs from 'qs';
import "../TreeViewSection/sessionManagerStyles.css";
import NoEncryptionGmailerrorredOutlinedIcon from '@mui/icons-material/NoEncryptionGmailerrorredOutlined';
import {
  CreateIssuePostBody,
  getPathWithParentId,
  checkDuplicatedNode,
  getInvitationDate,
  convertResToTree,
  getJsonDate
} from "../../utils/utils"

import '../../assets/bootstrap-custom.css';

import dayjs from 'dayjs';
import 'dayjs/locale/it';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';



/*import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { format } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import itLocale from "date-fns/locale/it";
*/



import CustomNode from "../TreeViewSection/CustomNode3"


//import styles from "assets/jss/views/sessionManagerStyles"




export default function SessionManager() {
  const { pageRouter, setPageRouter } = useContext(RouterContext);
  
  //const history = useHistory();
  const navigate = useNavigate();
  const treeRef = useRef(null);
  let {idMeeting } = useParams();
  const inputElement = useRef(null);
  const [alert, setAlert] = useState(null)
  const [lockedFile, setLockedFile] = useState(false)

  const [invitationDate, setInvitationDate] = useState(null);
  const [convocationDate, setConvocationDate] = useState(null);
  const [presidentName, setPresidentName] = useState("");
  const [convocationTime, setConvocationTime] = useState('');
  const [recipients, setRecipients] = useState([])
  const [auditors, setAuditors] = useState([])

  const [treeData, setTreeData] = useState([])
  const [newNodeId, setNewNodeId] = useState("")
  const [availableDates, setAvailableDates] = useState([])
  const [isCreated, setIsCreated] = useState(false)

  const organiValues =[ 
    {value: "10", label:"Comitato di Gestione"},
    {value: "20", label:"Consiglio Generale"},
    {value: "30", label:"Collegio Sindacale"} 
    //{value: "40", label:"Altri Organi"}
];




  const MenuList = props => {
    const rows = props.children;
    const rowRenderer = ({ key, index, isScrolling, isVisible, style }) => (
      <div key={key} style={style}>{rows[index]}</div>
    );
  
    return (
      <List
        style={{ width: '100%' }}
        width={300}
        height={300}
        rowHeight={30}
        rowCount={rows.length}
        rowRenderer={rowRenderer}
      />
    )
  }

  const handleOpenAll = () => treeRef.current.openAll();
  //const handleOpen = (id) => treeRef.current.open(id);
  //const handleOpen = (id) => treeRef.current.close(id);
  

/*
  const handleOpenAll3 = () => {
    if (treeRef.current) {
       treeData.forEach((node) => {
        
        //console.log("WWWWWWW!",node, treeRef,treeData)
        treeRef.current.open(node.id);
      });
    }
  };
*/

  const handleOpenAll4 = (id) => {
    //console.log("HOA4",id);
    treeRef.current.open(id);   
  };


 


  const customStyles = {
    control: base => ({
      ...base,
      textAlign: 'left',
      height: 55,
      minHeight: 55
    })
  };

const onFabClick= (e ) => {           
  //FAB aggiunta ambito
  console.log("OFC",e);    
  handleMakePublic();    
};

const onFabClick2= (e ) => {           
  //FAB aggiunta ambito
  console.log("OFC2",e);
  //history.push('/fileMgr');
  navigate('/fileMgr3');
};

const onFabClick3= (e ) => {           
  //FAB aggiunta ambito
  //WORD
  console.log("OFC3",e);
  if (e.target.tagName.toLowerCase() == 'a')
  return;
  handleRenderWord(inputElement); 
};

const onFabClick4= (e ) => {           
  //FAB aggiunta sblocca la seduta  
  console.log("OFC4",e);
  handleSblocca();
  /*if (e.target.tagName.toLowerCase() == 'a')
  return;
  handleRenderWord(inputElement); 
  */
};


function handleChange1(event, idMeeting) {
  if (event && event.value)
  {
     console.log(event, idMeeting);
     if( event.value=="20"){
          //CONSIGLIO GENERALE
          console.log("GO TOWARDS CONSIGLIO GENERALE");
          //history.push({                
            navigate("/folders2");
     }
     else{
        if( event.value=="10"){
            //COMITATO DI GESTIONE
            console.log("GO TOWARDS COMITATO DI GESTIONE");
            navigate("/folders");
        }
        if( event.value=="30"){
          //COLLEGIO SINDACALE
          console.log("REMAINS ON COLLEGIO SINDACALE");
          
      }
     }

     
   }
}


const handleCreateIssue = (_inviatationDate) => {
  
    const values = {
      invitationDate: _inviatationDate,
      convocationDate: convocationDate,
      convocationTime: convocationTime,
      presidentName: presidentName,
      recipients: recipients,
      auditors: auditors,
    }
    console.log(values);
    //axios.post(`${process.env.REACT_APP_PROXY_URL}`, qs.stringify(CreateIssuePostBody({
    /**
     axios.post(properties.productionUrl+'/issueManager', qs.stringify(CreateIssuePostBody({
      invitationDate: convocationDate,
      
      //convocationDate: format(new Date(_inviatationDate), 'yyyy-MM-dd'),
      convocationDate: _inviatationDate.format('YYYY-MM-DD'),
      convocationTime: convocationTime,
      presidentName: presidentName,
      recipients: recipients,
      auditors: auditors
    })))
     */

    const operationParams=CreateIssuePostBody({
      invitationDate: convocationDate,      
      //convocationDate: format(new Date(_inviatationDate), 'yyyy-MM-dd'),
      convocationDate: _inviatationDate.format('YYYY-MM-DD'),
      convocationTime: convocationTime,
      presidentName: presidentName,
      recipients: recipients,
      auditors: auditors
    });

    let endpoint = properties.productionUrl+"/php_demo/core/issueManagerCollegio.php";                             
    //var bodyFormData=new FormData();                      
    //bodyFormData.append('action', 'createIssue');       
    axios({
          method: 'post',
          url: endpoint,
          data: operationParams, 
          withCredentials: true, 
          headers: {'Content-Type': 'multipart/form-data' }
      })
      .then(res => {
        console.log("HCI RES",res.data)
        if (res.data.status === 'ok') {
          setTreeData([
            {
              "id": 1,
              "parent": 0,
              //"isOpen": true,
              "droppable": true,
              "text": res.data.result.issueName,
              "realPath": ""
            }
          ])
          toast.success("Nuova seduta creata correttamente")
          setLockedFile(res.data.result.lockedForFileInsert)
        } else {
          setTreeData([])
        }
        setAlert(null)
      })
      .catch(err => {
        console.log(err)
        setTreeData([])
      })
  
    }


  const confirmCreateIssue = (_inviatationDate, lastDate) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
        title="Vuoi davvero creare una nuova seduta?"
        onConfirm={() => handleCreateIssue(_inviatationDate)}
        onCancel={() => { setAlert(null); setInvitationDate(lastDate) }}
        confirmBtnCssClass="custom-btn custom-btn-success" 
        cancelBtnCssClass="custom-btn custom-btn-cancel"      
        confirmBtnText="Si"
        cancelBtnText="No"
        showCancel
      >
      </SweetAlert>
    );
  };

  const fetchLastIssue = () => {
    const operationName = "getLastIssue"

    const operationParams =
    {
      app_user: "app",
      app_password: "meeting",
      operation: operationName
    };
    //axios.post(`${process.env.REACT_APP_PROXY_URL}`, qs.stringify(operationParams))
    //axios.post(properties.productionUrl+'/issueManager', qs.stringify(operationParams))
    let endpoint = properties.productionUrl+"/php_demo/core/issueManagerCollegio.php";                             
    //var bodyFormData=new FormData();                      
    //bodyFormData.append('action', 'check');    
    axios({
        method: 'post',
        url: endpoint,
        data: operationParams, 
        withCredentials: true, 
        headers: {'Content-Type': 'multipart/form-data' }
    })
      .then(res => {
        console.log(res)
        setInvitationDate(getInvitationDate(res.data.convocationDate))
        setPresidentName(res.data.presidentName)
        setConvocationDate(getInvitationDate(res.data.invitationDate))
        setConvocationTime(res.data.convocationTime.replace('.', ':'))
        setRecipients(res.data.recipients)
        setAuditors(res.data.auditors)
        
        console.log("SM",res.data.dirTree,convertResToTree(res.data.dirTree));
        setTreeData(convertResToTree(res.data.dirTree))
        setLockedFile(res.data.lockedForFileInsert)
        
        setNewNodeId("");
        //console.log("VER2",new Date(res.data.convocationDate).toLocaleDateString('it-IT'));        
        //console.log("VER1",res.data.convocationDate, new Date(res.data.convocationDate));
        console.log("VER3",getInvitationDate(res.data.convocationDate));
        var a=res.data.formattedConvocationDate.split("/");
        /*if(a[0]<=9)
          a[0]="0"+a[0];
        if (a[1]<=9)
        a[1]="0"+a[1];*/
        if(a[0].length==1)
          a[0]="0"+a[0];
        if (a[1].length==1)
          a[1]="0"+a[1];
        console.log("APRO ........", a);        
        navigate("/folders3/"+a[0]+"-"+a[1]+"-"+a[2],{ replace: true });    
        //console.log("APRO ..")       
        //handleOpenAll();
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() =>{
        handleOpenAll();
      })
  }

  const handleDropTree = (newTree, { dragSourceId, dropTargetId }) => {

    const targetNode = treeData.find(node => node.id === dropTargetId)

    const operationName = "updateIssue";
    const params = {
      "operation": "MOVE",
      "args": {
        "frompath": treeData.find(node => node.id === dragSourceId).realPath,
        "topath": `${targetNode.realPath}${targetNode.realPath !== '' ? '/' : ''}${targetNode.text}`,
        "nodeName": treeData.find(node => node.id === dragSourceId).text,
        "position": 0
      }
    }
    const operationParams = {
      app_user: "app",
      app_password: "meeting",
      operation: operationName,
      issueName: treeData[0].text,
      params: JSON.stringify(params)
    };

    //axios.post(`${process.env.REACT_APP_PROXY_URL}`, qs.stringify(operationParams))
    //axios.post(properties.productionUrl+'/issueManager', qs.stringify(operationParams))
    let endpoint = properties.productionUrl+"/php_demo/core/issueManagerCollegio.php";                             
    var bodyFormData=new FormData();                      
    bodyFormData.append('action', 'check');    
    axios({
        method: 'post',
        url: endpoint,
        data: operationParams, 
        withCredentials: true, 
        headers: {'Content-Type': 'multipart/form-data' }
    })
      .then(res => {
        console.log(res)
        if (res.data.status === 'failure') {
          toast.error("Error occured!")
        }
        fetchLastIssue()
      })
      .catch(err => {
        console.log(err)
        toast.error("Error occured!")
        fetchLastIssue()
      })

  }

  const handleDeleteTree = (id) => {

    const operationName = "deleteChild";
    const params = {
      "path": treeData.find(tree => tree.id === id).realPath,
      "childName": treeData.find(node => node.id === id).text
    };
    const operationParams = {
      app_user: "app",
      app_password: "meeting",
      operation: operationName,
      issueName: treeData[0].text,
      params: JSON.stringify(params)
    };

    
    //axios.post(properties.productionUrl+'/issueManager', qs.stringify(operationParams))
    let endpoint = properties.productionUrl+"/php_demo/core/issueManagerCollegio.php";                             
    var bodyFormData=new FormData();                      
    bodyFormData.append('action', 'check');    
    axios({
        method: 'post',
        url: endpoint,
        data: operationParams, 
        withCredentials: true, 
        headers: {'Content-Type': 'multipart/form-data' }
    })
      .then((res) => {
        console.log("SM2",res.data, res.data.result.dirTree, convertResToTree(res.data.result.dirTree));
        setTreeData(convertResToTree(res.data.result.dirTree))
      })
      .catch((err) => {
        console.log(err)
        toast.error("Failed to delete")
      })
  }

  const handleCreateTree = (parentId) => {
    console.log("HCT");
    
    var cc="std_collegio";
    for (var j = 0; j < treeData.length; j++) {
      if (treeData[j]["id"] === parentId && treeData[j]["id"]!= 1 ) {
        cc=treeData[j].data.color;   
      }
    }
    

    setIsCreated(true)
    const params = {
      "path": getPathWithParentId(treeData, parentId),
      //"childName": "ODG",
      "childName": "   ",
      "attr": { "rel": "hidden_folder", "flabel": cc, "custom": "[]" },
      "pos": null
    };
    const operationParams = {
      app_user: "app",
      app_password: "meeting",
      operation: "addChild",
      issueName: treeData[0].text,
      params: JSON.stringify(params)
    };
    console.log("handleCreateTree",operationParams,parentId, treeData);
    
    //axios.post(`${process.env.REACT_APP_PROXY_URL}`, qs.stringify(operationParams))
    //axios.post(properties.productionUrl+'/issueManager', qs.stringify(operationParams))
    let endpoint = properties.productionUrl+"/php_demo/core/issueManagerCollegio.php";                             
    var bodyFormData=new FormData();   
    var idTmp;                   
    bodyFormData.append('action', 'check');    
    axios({
        method: 'post',
        url: endpoint,
        data: operationParams, 
        withCredentials: true, 
        headers: {'Content-Type': 'multipart/form-data' }
    })
      .then(res => {
        console.log("QWE",res.data)
        setNewNodeId(res.data.result.newChild.attr.id)
        setTreeData(convertResToTree(res.data.result.updatedIssue.dirTree))
        idTmp=parentId;
        //treeRef.current.openAll();
      })
      .catch(() => {
        toast.error("Server Error")
      })
      .finally(() => {
        handleOpenAll4(idTmp);
        //handleOpenAll();
        
        console.log("TUTTO APERTO");
      })
  }

  const handleChangeText = (id, value) => {

    if(value.includes("/")){
      //toast.error("Non è possibile utilizzare il carattere / nel nome di una cartella");
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
          title="Non è possibile utilizzare il carattere / nel nome di una cartella"
          onConfirm={() =>  setAlert(null)}
          confirmBtnCssClass="custom-btn custom-btn-success" 
          cancelBtnCssClass="custom-btn custom-btn-cancel"      
          confirmBtnText="Ok" 
        >
        </SweetAlert>
      );
      setNewNodeId(id)
      return;
    }

    if (checkDuplicatedNode(treeData, id, value)) {
      toast.error("Error occured!")
      setNewNodeId(id)
      return;
    }

    const operationName = "updateIssue";
    const params = {
      "operation": "RENAME",
      "args": {
        "path": treeData.find(tree => tree.id === id).realPath,
        "oldName": treeData.find(node => node.id === id).text,
        "newName": value
      }
    };
    const operationParams = {
      app_user: "app",
      app_password: "meeting",
      operation: operationName,
      issueName: treeData[0].text,
      params: JSON.stringify(params)
    };
    console.log("handleChangeText",operationParams,params, treeData, id, value);
    //axios.post(`${process.env.REACT_APP_PROXY_URL}`, qs.stringify(operationParams))
    //axios.post(properties.productionUrl+'/issueManager', qs.stringify(operationParams))
    let endpoint = properties.productionUrl+"/php_demo/core/issueManagerCollegio.php";                             
    var bodyFormData=new FormData();                      
    bodyFormData.append('action', 'check');    
    axios({
        method: 'post',
        url: endpoint,
        data: operationParams, 
        withCredentials: true, 
        headers: {'Content-Type': 'multipart/form-data' }
    })
      .then(res => {
        console.log(res)
        if (res.data.status === 'ok') {
          fetchLastIssue()
        }
      })
      .catch((err) => {
        console.log(err)
        setNewNodeId("")
        toast.error("Failed to update")
        fetchLastIssue()
      })
  }

  const handleChangeColor = (id, color) => {

    const operationName = "updateIssue";
    const params = {
      "operation": "UPDATE",
      "args": {
        "path": treeData.find(tree => tree.id === id).realPath,
        "updatechildren": true,
        "nodeName": treeData.find(tree => tree.id === id).text,
        "attr": {
          "flabel": color,
          "rel": `${color}_folder`
        }
      }
    };
    const operationParams = {
      app_user: "app",
      app_password: "meeting",
      operation: operationName,
      issueName: treeData[0].text,
      params: JSON.stringify(params)
    };

    console.log("233233233",params, operationParams);
    //axios.post(`${process.env.REACT_APP_PROXY_URL}`, qs.stringify(operationParams))
    //axios.post(properties.productionUrl+'/issueManager', qs.stringify(operationParams))
    let endpoint = properties.productionUrl+"/php_demo/core/issueManagerCollegio.php";                             
    var bodyFormData=new FormData();                      
    bodyFormData.append('action', 'check');    
    axios({
        method: 'post',
        url: endpoint,
        data: operationParams, 
        withCredentials: true, 
        headers: {'Content-Type': 'multipart/form-data' }
    })
      .then(res => {
        console.log(res)
        if (res.data.status === "ok") {
          const selectedItem = treeData.find(item => item.id === id)
          const newTree = treeData.map(node => {
            if (node.id === id || node.parent === id || node.realPath.includes(`${selectedItem.realPath}/${selectedItem.text}`)) {
              return {
                ...node,
                data: {
                  ...node.data,
                  color: color                                   
                }
              }
            }

            return node;
          })

          setTreeData(newTree);
        } else {
          toast.error("Error occured!")
        }
      })
      .catch(err => {
        console.log(err)
        toast.error("Error occured!")
      })
  }

  const handleLoadIssue = (issueName) => {
    const operationName = "loadIssue";
    console.log("HandleLoadIssue7", issueName);
    console.log("handleIssue-77", issueName.length, issueName);
    const params = null;
    const operationParams = {
      app_user: "app",
      app_password: "meeting",
      operation: operationName,
      issueName: issueName,
      params: JSON.stringify(params)
    };

    //axios.post(`${process.env.REACT_APP_PROXY_URL}`, qs.stringify(operationParams))
    //axios.post(properties.productionUrl+'/issueManager', qs.stringify(operationParams))
    let endpoint = properties.productionUrl+"/php_demo/core/issueManagerCollegio.php";                             
    //var bodyFormData=new FormData();                      
    //bodyFormData.append('action', 'check');    
    axios({
        method: 'post',
        url: endpoint,
        data: operationParams, 
        withCredentials: true, 
        headers: {'Content-Type': 'multipart/form-data' }
    }).then(res => {
        console.log("HANDLELOADISSUE",res);
        console.log("TEST",res.data.result);
        setInvitationDate(getInvitationDate(res.data.result.convocationDate));
        setPresidentName(res.data.result.presidentName);
        setConvocationDate(getInvitationDate(res.data.result.invitationDate));
        setConvocationTime(res.data.result.convocationTime.replace('.', ':'));
        setRecipients(res.data.result.recipients);
        setAuditors(res.data.result.auditors);
        setTreeData(convertResToTree(res.data.result.dirTree));
        setLockedFile(res.data.result.lockedForFileInsert);
        //console.log("APRO.....botch");
        //handleOpenAll();
      })
      .catch(() => {
        toast.error("Failed to load issue")
      })
      .finally(() =>{
        handleOpenAll();
      })
  }

  const handleIssueExists = (_inviatationDate, lastDate) => {
    //console.log("HIE",_inviatationDate, lastDate);
    
    const operationName = "issueExists";
    const params = null;
    //_inviatationDate.format('DD-MM-YYYY');
    //const issueName = `Seduta del ${format(new Date(_inviatationDate), 'dd-MM-yyyy')}`
    const issueName = `Seduta del `+_inviatationDate.format('DD-MM-YYYY');
    //console.log("handleIssueExists", _inviatationDate, lastDate,issueName);
    //console.log("handleIssueExists-1", issueName.length, issueName);
    console.log("handleIssueExists-1", _inviatationDate, lastDate,issueName);
    console.log("handleIssueExists-11", issueName.length, issueName);
    const operationParams = {
      app_user: "app",
      app_password: "meeting",
      operation: operationName,
      issueName: issueName,
      params: JSON.stringify(params)
    };

    //axios.post(`${process.env.REACT_APP_PROXY_URL}`, qs.stringify(operationParams))
    //axios.post(properties.productionUrl+'/issueManager', qs.stringify(operationParams))
    let endpoint = properties.productionUrl+"/php_demo/core/issueManagerCollegio.php";                             
    
    axios({
        method: 'post',
        url: endpoint,
        data: operationParams, 
        withCredentials: true, 
        headers: {'Content-Type': 'multipart/form-data' }
    })
      .then(res => {
        console.log(res);
        if (res.data.result) {
          handleLoadIssue(issueName);
          console.log("APRO TUTTO");
          //treeRef.current.openAll();
        } else {
          confirmCreateIssue(_inviatationDate, lastDate);
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(()=>{
        console.log("APRO TUTTO");
        handleOpenAll();
      })
      
  }

  const handleIssueExistsStartingFromDateLabel = (selectedDate) => {
    
    const operationName = "issueExists";
    const params = null;
    const issueName = 'Seduta del '+ selectedDate;
    console.log("handleIssueExists-2", selectedDate, issueName);
    console.log("handleIssueExists-22", issueName.length, issueName);
    const operationParams = {
      app_user: "app",
      app_password: "meeting",
      operation: operationName,
      issueName: issueName,
      params: JSON.stringify(params)
    };

    //axios.post(`${process.env.REACT_APP_PROXY_URL}`, qs.stringify(operationParams))
    //axios.post(properties.productionUrl+'/issueManager', qs.stringify(operationParams))
    let endpoint = properties.productionUrl+"/php_demo/core/issueManagerCollegio.php";                              
    axios({
        method: 'post',
        url: endpoint,
        data: operationParams, 
        withCredentials: true, 
        headers: {'Content-Type': 'multipart/form-data' }
    }).
      then(res => {
        console.log(res);
        if (res.data.result) {
          handleLoadIssue(issueName)
        } 
      })
      .catch(err => {
        console.log(err)
      })
   
  }

  const handleUnlockIssueForFileInsert = () => {
    const operationName = "unlockIssueForFileInsert";
    var issueName = treeData[0].text;
    const params = null
    const operationParams = {
      app_user: "app",
      app_password: "meeting",
      operation: operationName,
      issueName: issueName,
      params: JSON.stringify(params)
    };

    //axios.post(`${process.env.REACT_APP_PROXY_URL}`, qs.stringify(operationParams))
    //axios.post(properties.productionUrl+'/issueManager', qs.stringify(operationParams))
    let endpoint = properties.productionUrl+"/php_demo/core/issueManagerCollegio.php";                             

    axios({
        method: 'post',
        url: endpoint,
        data: operationParams, 
        withCredentials: true, 
        headers: {'Content-Type': 'multipart/form-data' }
    })
      .then(res => {
        console.log(res)
        setLockedFile(false)
        toast.success("Issue locked successfully!")
      })
      .catch(err => {
        console.log(err)
        toast.error("Failed to lock issue")
      })
  }



  const handleLockIssueForFileInsert = () => {
    const operationName = "lockIssueForFileInsert";
    var issueName = treeData[0].text;
    const params = null
    const operationParams = {
      app_user: "app",
      app_password: "meeting",
      operation: operationName,
      issueName: issueName,
      params: JSON.stringify(params)
    };

    //axios.post(`${process.env.REACT_APP_PROXY_URL}`, qs.stringify(operationParams))
    //axios.post(properties.productionUrl+'/issueManager', qs.stringify(operationParams))
    let endpoint = properties.productionUrl+"/php_demo/core/issueManagerCollegio.php";                             

    axios({
        method: 'post',
        url: endpoint,
        data: operationParams, 
        withCredentials: true, 
        headers: {'Content-Type': 'multipart/form-data' }
    })
      .then(res => {
        console.log(res)
        setLockedFile(true)
        toast.success("Issue locked successfully!")
      })
      .catch(err => {
        console.log(err)
        toast.error("Failed to lock issue")
      })
  }

  const handleRenderWord = (inputElement) => {
    const operationName = "renderIssue";
    var issueName = treeData[0].text;
    const params = null
    const operationParams = {
      app_user: "app",
      app_password: "meeting",
      operation: operationName,
      issueName: issueName,
      params: JSON.stringify(params)
    };

    //axios.post(`${process.env.REACT_APP_PROXY_URL}`, qs.stringify(operationParams))
    //axios.post(properties.productionUrl+'/issueManager', qs.stringify(operationParams))
    let endpoint = properties.productionUrl+"/php_demo/core/issueManagerCollegio.php";                             

    axios({
        method: 'post',
        url: endpoint,
        data: operationParams, 
        withCredentials: true, 
        headers: {'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
        console.log("renderWord Result" + JSON.stringify(res));
        
        if (res.data.status === "ok" && res.data.result.docHref != undefined)
        {
            console.log("href" + res.data.result.docHref);
            
            inputElement.current.href=properties.productionUrl+res.data.result.docHref;
            inputElement.current.click();
        }
        else {
            toast.error("Errore nella generazione dell'indice");
        }
      })
      .catch(err => {
        console.log(err)
        toast.error("Errore nella generazione dell'indice");
      })
  }

  const handleSblocca = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
        title="Attenzione"
        onConfirm={() => { handleUnlockIssueForFileInsert(); setAlert(null) }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass="custom-btn custom-btn-success" 
        cancelBtnCssClass="custom-btn custom-btn-cancel"      
        confirmBtnText="Si"
        cancelBtnText="No"
        showCancel
      >
        <div>
          <Typography variant="body1">
            la seduta verrà sbloccata per consentire di modificarne la struttura.
          </Typography>
          <Typography>
            Una volta effettuate le modifiche, ricordarsi di rendere pubblica la seduta stessa per rendere attivi i cambiamenti.
          </Typography>
          <Typography>Continuare?</Typography>
        </div>
      </SweetAlert>
    );
  }


  const handleMakePublic = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
        title="Attenzione"
        onConfirm={() => { handleLockIssueForFileInsert(); setAlert(null) }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass="custom-btn custom-btn-success" 
        cancelBtnCssClass="custom-btn custom-btn-cancel"      
        confirmBtnText="Si"
        cancelBtnText="No"
        showCancel
      >
        <div>
          <Typography variant="body1">
            la seduta verrà resa disponibile per l'aggiunta dei file e non sarà più possibile modificarne la struttura.
          </Typography>         
          <Typography>Continuare?</Typography>
        </div>
      </SweetAlert>
    );
  }

  const handleUpdateIssue = (e) => {

    const values = {
      invitationDate: invitationDate,
      convocationDate: convocationDate,
      convocationTime: convocationTime,
      presidentName: presidentName,
      recipients: recipients,
      auditors: auditors,
      [e.target.name]: e.target.value
    }

    const long_options = { year: 'numeric', month: 'long', day: 'numeric' };

    const operationName = "updateIssue";
    const params = {
      "operation": "UPDATEINFO",
      "args": {
        ...values,
        "convocationTime": values.convocationTime.replace(':', '.'),
        "invitationDate": new Date(values.convocationDate).toLocaleDateString('it-IT', long_options),
        "convocationDate": new Date(values.invitationDate).toLocaleDateString('it-IT', long_options)
      }
    };

    const operationParams = {
      app_user: "app",
      app_password: "meeting",
      operation: operationName,
      issueName: treeData[0].text,
      params: JSON.stringify(params)
    };

    //axios.post(`${process.env.REACT_APP_PROXY_URL}`, qs.stringify(operationParams))
    //axios.post(properties.productionUrl+'/issueManager', qs.stringify(operationParams))
    let endpoint = properties.productionUrl+"/php_demo/core/issueManagerCollegio.php";                             
    var bodyFormData=new FormData();                      
    bodyFormData.append('action', 'check');    
    axios({
        method: 'post',
        url: endpoint,
        data: operationParams, 
        withCredentials: true, 
        headers: {'Content-Type': 'multipart/form-data' }
    })
      .then(res => {
        console.log(res)
        toast.success("Issue updated")
      })
      .catch(err => {
        console.log(err)
        toast.error("Failed to updated")
      })

  }

  const handleChangePresidentName = (e) => {
    console.log("HCPN",e);
    //if (e.key === 'Enter') {
      handleUpdateIssue(e)
    //}
  }

  const handleInvitationDateChange = (date) => {
    
    var rr=date.format('DD-MM-YYYY');
    console.log("HIDC",date,rr);
    navigate("/folders3/"+rr,{ replace: true });       
    let lastDate = invitationDate;
    setInvitationDate(date);
    handleIssueExists(date, lastDate);
    
    /*
    var rr=format(new Date(date), 'dd-MM-yyyy');
    navigate("/folders/"+rr,{ replace: true });       
    let lastDate = invitationDate;
    setInvitationDate(date);
    handleIssueExists(date, lastDate);
    */
  };

  const handleConvocationDateChange = (date) => {
    setConvocationDate(date)
    handleUpdateIssue({ target: { name: 'convocationDate', value: date } })
  }

  const getAvailableIssueDates = () => {
    console.log("GAID");
    const operationName = "getAvailableIssueDates";
    const operationParams = {
      app_user: "app",
      app_password: "meeting",
      operation: operationName
    };
    //axios.post(`${process.env.REACT_APP_PROXY_URL}`, qs.stringify(operationParams))
    //axios.post(properties.productionUrl+'/issueManager', qs.stringify(operationParams))
    let endpoint = properties.productionUrl+"/php_demo/core/issueManagerCollegio.php";                             
    var bodyFormData=new FormData();                      
    bodyFormData.append('action', 'check');    
    axios({
        method: 'post',
        url: endpoint,
        data: operationParams, 
        withCredentials: true, 
        headers: {'Content-Type': 'multipart/form-data' }
    })
      .then(res => {        
        if (!!res.data) {
          console.log("init",res.data);
          let __availableDates=[];
          //const _availableDates = res.data.map(node => {
            /*if (node.includes('_')) {
              //return `${node.substring(0, 4)}-${node.substring(4, 6)}-${node.substring(6, 8)}`
              return dayjs(`${node.substring(0, 4)}-${node.substring(4, 6)}-${node.substring(6, 8)}`)
            } else {
              //return `${node.split('-')[2]}-${node.split('-')[1]}-${node.split('-')[0]}`
              return dayjs(`${node.split('-')[2]}-${node.split('-')[1]}-${node.split('-')[0]}`)
            }*/
          /*  if (!node.includes('_')) {              
              //return `${node.split('-')[2]}-${node.split('-')[1]}-${node.split('-')[0]}`
              return dayjs(`${node.split('-')[2]}-${node.split('-')[1]}-${node.split('-')[0]}`)
            }
          })*/
          res.data.map(node => {
            if (!node.includes('_')) {
              //return `${node.substring(0, 4)}-${node.substring(4, 6)}-${node.substring(6, 8)}`              
              __availableDates.push(dayjs(`${node.split('-')[2]}-${node.split('-')[1]}-${node.split('-')[0]}`));
            }
          })
          /*  if (!node.includes('_')) {              
              //return `${node.split('-')[2]}-${node.split('-')[1]}-${node.split('-')[0]}`
              return dayjs(`${node.split('-')[2]}-${node.split('-')[1]}-${node.split('-')[0]}`)
            }
          console.log("AvailableDates",_availableDates);
          setAvailableDates(_availableDates)
          */
          console.log("AvailableDates",__availableDates);
          setAvailableDates(__availableDates)
        } else {
          console.log("else12");
          setAvailableDates([])
        }
        
      })
      .catch(() => {
        setAvailableDates([])
      })
  }




  const handleMove = (direction, id) => {   
   
    const sourceNode = treeData.find(node => node.id === id)
    const pos = treeData.filter(node => node.parent === sourceNode.parent).findIndex(node => node.id === id)    
    if ((pos === 0 && direction === 'up') || (direction === 'down' && pos === (treeData.filter(node => node.parent === sourceNode.parent).length - 1))) {      
      return;
    }
    const params = {
      "operation": "MOVE",
      "args": {
        "frompath": sourceNode.realPath,
        "topath": sourceNode.realPath,
        "nodeName": sourceNode.text,
        "position": direction === 'up' ? pos - 1 : pos + 1
      }
    }
    const operationParams = {
      app_user: "app",
      app_password: "meeting",
      operation: "updateIssue",
      issueName: treeData[0].text,
      params: JSON.stringify(params)
    };
    //axios.post(`${process.env.REACT_APP_PROXY_URL}`, qs.stringify(operationParams))
    //axios.post(properties.productionUrl+'/issueManager', qs.stringify(operationParams))
    let endpoint = properties.productionUrl+"/php_demo/core/issueManagerCollegio.php";                                
    axios({
        method: 'post',
        url: endpoint,
        data: operationParams, 
        withCredentials: true, 
        headers: {'Content-Type': 'multipart/form-data' }
    })
      .then(res => {
        console.log(res)
        if (res.data.result.status === 'ok') {
          fetchLastIssue()
        } else {
          toast.error('Failed to move')
        }
      })
      .catch(() => {
        toast.error('Failed to move')
      })
  }


  //
  function handleEditableBlur(event) {    
    console.log("BLUR",event);                        
    handleChangePresidentName(event);    
}
  
    

/*  const handleOnKeyDown= event => {
    handleKeyPress(event)
}*/

/*
const shouldDisableDate = (date) => {
  return !availableDates.some(eventDate => eventDate.isSame(date, 'day'));
};
*/

function handleKeyPress(event){
  //if(event.keyCode === 13){
    console.log("KP",event);    
    if(event.key === 'Enter'){
      console.log(event);
      event.target.blur();       
  }
}
  //

  useEffect(() => {
        
  //CHECK AUTHENTICATION
  var xhr = new XMLHttpRequest();                
  //xhr.open('POST', properties.productionUrl+'/rest/ping',true);
  xhr.open('POST', properties.productionUrl+'/php_demo/core/rest/ping.php',true);
  xhr.withCredentials = true;
  xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

  xhr.onload = function (e) {
      if (xhr.readyState === 4) {
          if (xhr.status === 200) {                    
              console.log("OK",xhr);              
          } else {
              console.error("Error 1", xhr);   
              localStorage.clear();                 
              navigate("/login");
          }
      }
  };

  xhr.onerror = function (e) {
    console.error("Error 2", xhr);
    localStorage.clear();
    navigate("/login");
  };
       
  let urlEncodedData = null; 
  xhr.send(urlEncodedData);
  //CHECK AUTHENTICATION
  
  console.log("initial",idMeeting);     
  if(idMeeting!=undefined){
      if(idMeeting=="last"){
        console.log("if",idMeeting);     
        fetchLastIssue();
      }
      else {
        //if(idMeeting.startsWith("Seduta")){
          if(idMeeting.length==10){
            console.log("elseif",idMeeting);
            //fetchLastIssue();
            handleIssueExistsStartingFromDateLabel(idMeeting);
        }    
        else{
          console.log("elseelse",idMeeting);
          fetchLastIssue();
        }
      }
    }
    else{
      console.log("altro else",idMeeting);
      fetchLastIssue();
    }

    getAvailableIssueDates();
    
  }, [invitationDate]) 

  return (
     <div className="content">
       
       {!lockedFile ?(
         <Tooltip title="Rendi Pubblico" placement="left">
            <Fab aria-label="add"                 
                //variant="contained" 
                onClick={(event) => {onFabClick(event) }}                
                style={{
                    margin: 0,
                    background: "#666999",
                    top: 'auto',
                    right: 10,
                    bottom: 25,
                    //color: '0xFF0000',
                    left: 'auto',
                    position: 'fixed',}}>                    
                    <SendIcon />                    
            </Fab>  
          </Tooltip>
       ):(
         <div>
           <Tooltip title="Gestione dei file" placement="left">
              <Fab aria-label="add"                 
              //variant="contained" 
              onClick={(event) => {onFabClick2(event) }}                
              style={{
                  margin: 0,
                  //background: "#30720f",
                  background: "#A2EE7D",
                  top: 'auto',
                  right: 10,
                  bottom: 25,
                  //color: '0xFF0000',
                  left: 'auto',
                  position: 'fixed',}}>                    
                  <AttachFileIcon />                    
              </Fab>  
            </Tooltip>         
            <Tooltip title="Sblocca la seduta per poterne modificare la struttura" placement="left">
                <Fab aria-label="add"                 
                //variant="contained" 
                onClick={(event) => {onFabClick4(event) }}                
                style={{
                    margin: 0,
                    //background: "#30720f",
                    background: "#A2EE7D",
                    top: 'auto',
                    right: 10,
                    bottom: 90,
                    //color: '0xFF0000',
                    left: 'auto',
                    position: 'fixed',}}>                    
                    <NoEncryptionGmailerrorredOutlinedIcon />                   
                </Fab>  
            </Tooltip>

        </div>
       )

       }                 
      <Container maxWidth="xl">

      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it" >
          <Grid container spacing={2}>

              <Grid item xs={12} sm={6} md={8}>
              <Box display="flex" alignItems="center" height="100%">
                <Typography variant="body1" style={{fontWeight: "bold"}}>
                  Organo relativo alla riunione:
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>             
               <Select key={uuidv4()}     
                  isSearchable= {false}                                  
                  component={{MenuList}}  
                  fullWidth                  
                  styles={customStyles}                  
                  onChange={(event) => handleChange1(event, idMeeting)}
                  defaultValue={{
                    label: "Collegio Sindacale",
                    value: "30"
                  }}     
                  options={organiValues}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: '#a2ee7d',
                      primary: 'black',
                    },
                  })}            
                /> 
            </Grid>

            <Grid item xs={12} sm={6} md={8}>
              <Box display="flex" alignItems="center" height="100%">
                <Typography variant="body1" style={{fontWeight: "bold"}}>
                  Data della riunione:
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <DatePicker
                      disableToolbar     
                      id="invitationDate"                                                                   
                      onKeyDown={(e) => {
                          e.preventDefault();
                      }}
                      value={dayjs(invitationDate)}
                      //shouldDisableDate={shouldDisableDate}
                      onChange={handleInvitationDateChange}
                      sx={{width:'100%', backgroundColor:"white", "& .MuiOutlinedInput-root": {
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "black"
                          },
                         
                      } }}
                        
                      slotProps={{                          
                          field: {
                              readOnly: true
                          },
                          day: ({ day, selectedDate, isInCurrentMonth }) => {
                            const isEventDate = availableDates.some((eventDate) => eventDate.isSame(day, 'day'));
                            const isSelected =  day.isSame(selectedDate, 'day');
                            
                            return {
                              sx: {
                                '&.Mui-selected': {
                                  backgroundColor: '#a3a5c2 !important', 
                                  color: 'white',
                                  fontWeight: 'bold',
                                  '&:hover': {
                                    backgroundColor: '#666999 !important', 
                                  },
                                },
                                ...(isEventDate && !isSelected && {
                                  backgroundColor: '#666999', 
                                  color: 'white', 
                                  borderRadius: '50%',
                                  fontWeight: 'bold',
                                  '&:hover': {
                                    backgroundColor: '#3d3f5c', 
                                  },
                                }),                        
                              },
                             
                            };
                          }                                                
                      }}                                                             
                    />
            </Grid>
              
            <Grid item xs={12} sm={6} md={8}>
              <Box display="flex" alignItems="center" height="100%">
                <Typography variant="body1"  style={{fontWeight: "bold"}}>
                  Ora della riunione:
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}> 
              <TextField
                variant="outlined"
                fullWidth
                type="time"
                name="convocationTime"
                sx={{backgroundColor: "white", 
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: 'black',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'black', 
                  },
                },
                }}             
                value={convocationTime}
                onChange={(e) => { setConvocationTime(e.target.value); handleUpdateIssue(e) }}
                disabled={lockedFile}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={8}>
              <Box display="flex" alignItems="center" height="100%">
                <Typography variant="body1" style={{fontWeight: "bold"}}>
                  Data della convocazione:
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                  <DatePicker
                      disableToolbar     
                       disabled={lockedFile}
                      id="convocationDate"                                                                 
                      onKeyDown={(e) => {
                          e.preventDefault();
                      }}
                      value={dayjs(convocationDate)}
                      //shouldDisableDate={shouldDisableDate}
                      onChange={handleConvocationDateChange}
                      sx={{width:'100%', backgroundColor:"white", "& .MuiOutlinedInput-root": {
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "black"
                          },
                         
                      } }}
                        
                      slotProps={{                          
                          field: {
                              readOnly: true
                          },
                          day: ({ day, selectedDate, isInCurrentMonth }) => {
                            const isEventDate = availableDates.some((eventDate) => eventDate.isSame(day, 'day'));
                            const isSelected =  day.isSame(selectedDate, 'day');
                            
                            return {
                              sx: {
                                '&.Mui-selected': {
                                  backgroundColor: '#a3a5c2 !important', 
                                  color: 'white',
                                  fontWeight: 'bold',
                                  '&:hover': {
                                    backgroundColor: '#666999 !important', 
                                  },
                                }                                                   
                              },
                             
                            };
                          }                                                
                      }}                                                             
                    />

            </Grid>
            {/*
            <Grid item xs={12} sm={6} md={8}>
              <Box display="flex" alignItems="center" height="100%">
                <Typography variant="body1" style={{fontWeight: "bold"}}>
                  Nome del Presidente:
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                fullWidth
                value={presidentName}
                sx={{backgroundColor: "white", 
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: 'black',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'black', 
                  },
                },
                }}       
                //onKeyDown={handleChangePresidentName}
                onBlur={(event) => handleEditableBlur(event)}
                //onChange={(event) => handleEditableChange(event, item)}
                onKeyDown={(event) => handleKeyPress(event)}
                onChange={(e) => setPresidentName(e.target.value)}
                disabled={lockedFile}
              />
            </Grid>
            */}
              
            <Grid item xs={12} >
             
              <Typography variant="body1"  style={{fontWeight: "bold",textAlign: "left",paddingBottom:"5px",paddingTop:"15px"}}>
              Componenti Collegio Sindacale:
              </Typography>

              <Autocomplete
                multiple
                id="tags-filled"
                // options={recipients.map((option) => option.title)}
                options={[]}
                //size="large"
                getOptionLabel={option => option.title}
                freeSolo
                value={recipients}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip variant="outlined" style= {{fontSize: 16}} label={option} {...getTagProps({ index })} />
                  ))
                }
                onChange={(_, value) => {
                  setRecipients(value);
                  handleUpdateIssue({ target: { name: 'recipients', value: value } })
                }}
                //sx={{
                //  fontSize: "16px"
                //}}
                disabled={lockedFile}
                renderInput={(params) => (
                  <TextField
                    {...params}                          
                    //inputProps={{style: {fontSize: 16}}}  
                    //InputProps={{ style: { fontSize: `30 !important` } }}        
                    variant="outlined"
                    sx={{backgroundColor: "white", 
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: 'black',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'black', 
                      },
                    },
                    }} 
                  />
                
                )}
              />

            </Grid>


            <Grid item xs={12}>
              <Typography variant="body1" style={{fontWeight: "bold",textAlign: "left",paddingBottom:"5px",paddingTop:"10px"}}>
              Altri Partecipanti:
              </Typography>

              <Autocomplete
                multiple
                id="tags-filled"
                options={[]}
                freeSolo
                value={auditors}
                onChange={(_, value) => {
                  setAuditors(value);
                  handleUpdateIssue({ target: { name: 'auditors', value: value } })
                }
                }
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip variant="outlined" style= {{fontSize: 16}} label={option} {...getTagProps({ index })} />
                  ))
                }
                disabled={lockedFile}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    sx={{backgroundColor: "white", 
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: 'black',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'black', 
                      },
                    },
                    }} 
                  />
                )}
              />
           
            </Grid>

            

            <Grid item xs={12}>
              <Box mt={4}>
                <Typography variant="body1" style={{fontWeight: "bold",textAlign: "left",paddingBottom:"5px"}}>
                  Edita la struttura del documento spostando e creando le cartelle:
                </Typography>
              </Box>
              <Box>
                <Box style={{paddingTop:"10px", backgroundColor:"white", paddingLeft:"20px", borderRadius: "4px", border: "1px solid rgb(196,196,196)",}}>
                <DndProvider backend={HTML5Backend} options={getBackendOptions()}>
                  <Tree
                    ref={treeRef}
                    tree={treeData}
                    //isOpen={true}
                    rootId={0}
                    
                    sort={false}
                    
                    render={(node, options) => (                           
                       <CustomNode
                          node={node}
                          {...options}
                          //isOpen={true}
                          //initialOpen={true}
                          handleDelete={handleDeleteTree}
                          handleAddNewNode={handleCreateTree}
                          handleChangeText={handleChangeText}
                          handleChangeColor={handleChangeColor}
                          newNodeId={newNodeId}
                          lockedFile={lockedFile}
                          isCreated={isCreated}
                          handleMove={handleMove} />                      
                    )}
                  
                    onDrop={handleDropTree}
                    
                    classes={{
                      root: "treeRoot",
                      draggingSource: "draggingSource",
                      container: "treeContainer",
                      dropTarget: "dropTarget",                      
                    }}

                    initialOpen={true}
                  />
                  </DndProvider>
                </Box>
              </Box>
            </Grid>
            
          </Grid>
        </LocalizationProvider>
        {alert}
      </Container>
      </div>
  );
}


