import React, { useState, useEffect, useCallback, createContext, useContext,useRef } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

import {properties} from "../../utils/properties";
import cultura from '../../assets/cultura.png';
import persone from '../../assets/persone.png';
import { useNavigate } from "react-router-dom";
import pianeta from '../../assets/pianeta.png';
import altro from '../../assets/altro.png';
import direzioni from '../../assets/direzioni.png';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import { useParams } from "react-router-dom";
import {ThreeDots} from 'react-loader-spinner';
import { encode } from 'js-base64';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import InputLabel from '@mui/material/InputLabel';
import {MenuItem as MenuItemMUI} from '@mui/material';
import {FormControl,Box} from '@mui/material';
import Select from '@mui/material/Select';
import LockIcon from '@mui/icons-material/Lock';
import WarningIcon from '@mui/icons-material/Warning';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import EngineeringIcon from '@mui/icons-material/Engineering';
import LockClockIcon from '@mui/icons-material/LockClock';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import PrintIcon from '@mui/icons-material/Print';
import VpnKeyOffIcon from '@mui/icons-material/VpnKeyOff';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import Filter2Icon from '@mui/icons-material/Filter2';
import Filter3Icon from '@mui/icons-material/Filter3';
import { v4 as uuidv4 } from 'uuid';

import {
    SimpleTreeItemWrapper,
    SortableTree,
    //FolderTreeItemWrapper,    
  } from 'dnd-kit-sortable-tree';
import { IndexProvider } from "../../context/IndexContext";

import SweetAlert from "react-bootstrap-sweetalert";
import './bootstrap-custom.css';


const SortableContext = createContext();

export default function Indice() {
    let { idSeduta } = useParams();   
    const dataSeduta = idSeduta ;
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(true);            
    const [secondLevelButtons, setSecondLevelButtons]= useState(false);      
    const [forthLevelButtons, setForthLevelButtons]= useState(false);                      
    const [dialogPratica, setDialogPratica] = useState(false);      
    const [dialogMovePratica, setDialogMovePratica] = useState(false);        
    const [dialogAmbito, setDialogAmbito] = useState(false);        
    const [dialogMoveAmbito, setDialogMoveAmbito] = useState(false);        
    const [dialogDocumento, setDialogDocumento] = useState(false);        
    const [dialogMoveDocumento, setDialogMoveDocumento] = useState(false);        
    const [item, setItem] = useState([]);    
    const [itemChanged, setItemChanged] = useState(false);             
    const [menuLocked, setMenuLocked] = useState([0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]);             
    const [menuItemSelected, setMenuItemSelected] = useState(-1);             
    const [colorItemSelected, setColorItemSelected] = useState("#E42568");             
    const [printable, setPrintable] = useState("Cultura");          
    const [mingrantAvailable, setMingrantAvailable] = useState(false);             
    const [sector, setSector] = useState("cultura");             
    const [totaleObiettivi, setTotaleObiettivi] = useState(["€ 0.00","€ 0.00","€ 0.00"]);                 
    const [movedSector, setMovedSector] = useState('');
    const [alert1, setAlert1] = useState(null);

    const canBeSeen = function(indiceObiettivo) {   
      if( properties.verboseLog==1)
        console.log("CBS", indiceObiettivo);
      const a=localStorage.getItem("indice");
      if( properties.verboseLog==1)
        console.log("CBS", indiceObiettivo, a);
      if(a.includes(indiceObiettivo))
        return true;
      else
        return false;
    }

    const checkItem = function(item) {   
      let phase=0;
      if(data.Fase != undefined)
        phase=data.Fase;
        
      if(item.Tipo=="Documento"){
        if( properties.verboseLog==1)
          console.log("CHECK ITEM","Documento",item);
        if (item.Descrizione=="Nuovo Documento"||item.Descrizione.trim()==""){
			    return false;			    
		    }
      }
      if(item.Tipo=="Ambito"){
        if( properties.verboseLog==1)
          console.log("CHECK ITEM","AMBITO",item);
        if (item.Descrizione=="Nuovo Ambito"||item.Descrizione.trim()==""){
			    return false;			    
		    }
      }
      if(item.Tipo=="Pratica" &&phase!=0){
        if( properties.verboseLog==1)
          console.log("CHECK ITEM","PRATICA",item);
        if (item.Ente=="Ente"||item.Ente.trim()==""){
          if( properties.verboseLog==1)
            console.log("CHECK ITEM","ENTE FAILED",item);
			    return false;
		    }
        if (item.IdRichiesta=="ID Pratica"||item.IdRichiesta.trim()==""){
          if( properties.verboseLog==1)
            console.log("CHECK ITEM","ID FAILED",item);
          return false;
		    }
        if (item.Localizzazione=="Localizzazione"||item.Localizzazione.trim()==""){
          if( properties.verboseLog==1)
            console.log("CHECK ITEM","LOC FAILED",item);
			    return false;
		    }
        if (item.Descrizione=="Descrizione"||item.Descrizione.trim()==""){
          if( properties.verboseLog==1)
            console.log("CHECK ITEM","DESC FAILED",item);
			    return false;			    
		    }
      }
      return true;
    }

    const checkAttributo= function( tipo, attributo, valore) {
      if( properties.verboseLog==1)
        console.log("CHECK ATTRIBUTO",tipo, attributo, valore);
      let phase=0;
      if(data.Fase != undefined)
        phase=data.Fase;
            
      if(tipo=="Documento"){        
        if (attributo=="Descrizione"){
          if(valore=="Nuovo Documento"||valore.trim()==""){
			      return false;			    
		      }
        }
      }
      if(tipo=="Ambito"){ 
        if (attributo=="Descrizione"){
          if(valore=="Nuovo Ambito"||valore.trim()==""){
			      return false;			    
		      }
        }
      }
      if(tipo=="Pratica" &&phase!=0){        
        if (attributo=="Ente"){
          if (valore=="Ente"||valore.trim()==""){          
			      return false;
		      }
        }
        if (attributo=="IdRichiesta"||attributo=="idRichiesta"){
          if (valore=="ID Pratica"||valore.trim()==""){     
            return false;
		      }
        }
        if (attributo=="Localizzazione"){
          if (valore=="Localizzazione"||valore.trim()==""){          
			      return false;
		      }
        }
        if (attributo=="Descrizione"){
          if (valore=="Descrizione"||valore.trim()==""){          
			      return false;			    
		      }
        }
      }
      return true;
    }


    const isItemCoerente = function(item) {   
      if( properties.verboseLog==1)
        console.log("IICOERENTE",item,sector,data.Fase);
                        
      if(sector=='cultura'){
        if(menuLocked[0]==3)
          return !checkItem(item);
        else
          return false;
      }
      if(sector=='attrattivita'){
        if(menuLocked[1]==3)
          return !checkItem(item);
        else
          return false;
      }
      if(sector=='competenze'){
        if(menuLocked[2]==3)
          return !checkItem(item);
        else
          return false;
      }
      if(sector=='custodia'){        
        if(menuLocked[3]==3)
          return !checkItem(item);
        else
          return false;
      }

      if(sector=='partecipazione'){
        if(menuLocked[4]==3)
          return !checkItem(item);
        else
          return false;
      }
      if(sector=='persone'){
        if(menuLocked[5]==3)
          return !checkItem(item);
        else
          return false;
      }

      if(sector=='abitare'){
        if(menuLocked[6]==3)
          return !checkItem(item);
        else
          return false;
      }
      if(sector=='lavoro'){
        if(menuLocked[7]==3)
          return !checkItem(item);
        else
          return false;
      }
      if(sector=='educazione'){
        if(menuLocked[8]==3)
          return !checkItem(item);
        else
          return false;
      }
      if(sector=='comunita'){
        if(menuLocked[9]==3)
          return !checkItem(item);
        else
          return false;
      }
      if(sector=='inclusione'){
        if(menuLocked[10]==3)
          return !checkItem(item);
        else
          return false;
      }
      if(sector=='pianeta'){
        if(menuLocked[11]==3)
          return !checkItem(item);
        else
          return false;
      }
      if(sector=='ricerca'){
        if(menuLocked[12]==3)
          return !checkItem(item);
        else
          return false;
      }
      if(sector=='innovazione'){
        if(menuLocked[13]==3)
          return !checkItem(item);
        else
          return false;
      }
      if(sector=='benessere'){
        if(menuLocked[14]==3)
          return !checkItem(item);
        else
          return false;
      }
      if(sector=='internazionalizzazione'){
        if(menuLocked[15]==3)
          return !checkItem(item);
        else
          return false;
      }
      if(sector=='ambiente'){
        if(menuLocked[16]==3)
          return !checkItem(item);
        else
          return false;
      }
      if(sector=='direzione_pianificazione'){
        if(menuLocked[17]==3)
          return !checkItem(item);
        else
          return false;
      }
      if(sector=='direzione_innovazione_impatto'){
        if(menuLocked[18]==3)
          return !checkItem(item);
        else
          return false;
      }
      if(sector=='convenzioni_progetti_speciali'){
        if(menuLocked[19]==3)
          return !checkItem(item);
        else
          return false;
      }                        
    }

    const isBloccataMissione = function() {    
      if( properties.verboseLog==1)
        console.log("IBM",localStorage.getItem("indice"),localStorage.getItem("indice").includes("1"))
      if(sector=='cultura'){
        if(!localStorage.getItem("indice").includes("1")){          
          return true;
        }
        if(menuLocked[0]==2 || menuLocked[0]==4 )
          return true;
        else
          return false;
      }

      if(sector=='attrattivita'){
        if(!localStorage.getItem("indice").includes("1")){          
          return true;
        }
        if(menuLocked[1]==2 || menuLocked[1]==4)
          return true;
        else
          return false;
      }
      if(sector=='competenze'){
        if(!localStorage.getItem("indice").includes("1")){          
          return true;
        }
        if(menuLocked[2]==2 || menuLocked[2]==4)
          return true;
        else
          return false;
      }
      if(sector=='custodia'){
        if(!localStorage.getItem("indice").includes("1")){          
          return true;
        }
        if(menuLocked[3]==2 || menuLocked[3]==4)
          return true;
        else
          return false;
      }

      if(sector=='partecipazione'){
        if(!localStorage.getItem("indice").includes("1")){          
          return true;
        }
        if(menuLocked[4]==2 || menuLocked[4]==4)
          return true;
        else
          return false;
      }
      if(sector=='persone'){
        if(!localStorage.getItem("indice").includes("2")){          
          return true;
        }
        if(menuLocked[5]==2 || menuLocked[5]==4)
          return true;
        else
          return false;
      }

      if(sector=='abitare'){
        if(!localStorage.getItem("indice").includes("2")){          
          return true;
        }
        if(menuLocked[6]==2 || menuLocked[6]==4)
          return true;
        else
          return false;
      }
      if(sector=='lavoro'){
        if(!localStorage.getItem("indice").includes("2")){          
          return true;
        }
        if(menuLocked[7]==2 || menuLocked[7]==4)
          return true;
        else
          return false;
      }
      if(sector=='educazione'){
        if(!localStorage.getItem("indice").includes("2")){          
          return true;
        }
        if(menuLocked[8]==2 || menuLocked[8]==4)
          return true;
        else
          return false;
      }
      if(sector=='comunita'){
        if(!localStorage.getItem("indice").includes("2")){          
          return true;
        }
        if(menuLocked[9]==2 || menuLocked[9]==4)
          return true;
        else
          return false;
      }
      if(sector=='inclusione'){
        if(!localStorage.getItem("indice").includes("2")){          
          return true;
        }
        if(menuLocked[10]==2 || menuLocked[10]==4)
          return true;
        else
          return false;
      }
      if(sector=='pianeta'){
        if(!localStorage.getItem("indice").includes("3")){          
          return true;
        }
        if(menuLocked[11]==2 || menuLocked[11]==4)
          return true;
        else
          return false;
      }
      if(sector=='ricerca'){
        if(!localStorage.getItem("indice").includes("3")){          
          return true;
        }
        if(menuLocked[12]==2 || menuLocked[12]==4)
          return true;
        else
          return false;
      }
      if(sector=='innovazione'){
        if(!localStorage.getItem("indice").includes("3")){          
          return true;
        }
        if(menuLocked[13]==2 || menuLocked[13]==4)
          return true;
        else
          return false;
      }
      if(sector=='benessere'){
        if(!localStorage.getItem("indice").includes("3")){          
          return true;
        }
        if(menuLocked[14]==2 || menuLocked[14]==4)
          return true;
        else
          return false;
      }
      if(sector=='internazionalizzazione'){
        if(!localStorage.getItem("indice").includes("3")){          
          return true;
        }
        if(menuLocked[15]==2 || menuLocked[15]==4)
          return true;
        else
          return false;
      }
      if(sector=='ambiente'){
        if(!localStorage.getItem("indice").includes("3")){          
          return true;
        }
        if(menuLocked[16]==2 || menuLocked[16]==4)
          return true;
        else
          return false;
      }
      if(sector=='direzione_pianificazione'){
        if(!localStorage.getItem("indice").includes("4")){          
          return true;
        }
        if(menuLocked[17]==2 || menuLocked[17]==4)
          return true;
        else
          return false;
      }
      if(sector=='direzione_innovazione_impatto'){
        if(!localStorage.getItem("indice").includes("5")){          
          return true;
        }
        if(menuLocked[18]==2 || menuLocked[18]==4)
          return true;
        else
          return false;
      }
      if(sector=='convenzioni_progetti_speciali'){
        if(!localStorage.getItem("indice").includes("6")){          
          return true;
        }
        if(menuLocked[19]==2 || menuLocked[19]==4)
          return true;
        else
          return false;
      }                
      return false;
    };

    const isBloccatoObiettivo = function(obiettivo) {                       
      if( properties.verboseLog==1)
        console.log("CALLED IS BLOCCATOOBIETTIVO");
      if(obiettivo=='cultura'){        
        if((menuLocked[0]==2 || menuLocked[0]==4) && (menuLocked[1]==2 || menuLocked[1]==4)&&(menuLocked[2]==2 || menuLocked[2]==4) && (menuLocked[3]==2 || menuLocked[3]==4) &&(menuLocked[4]==2 || menuLocked[4]==4))
          return true;
        else
          return false;
      }      
      if(obiettivo=='persone'){
        if( properties.verboseLog==1)
          console.log("persone", menuLocked)
        if((menuLocked[5]==2 || menuLocked[5]==4)&&(menuLocked[6]==2 || menuLocked[6]==4)&&(menuLocked[7]==2 || menuLocked[7]==4)&&(menuLocked[8]==2 || menuLocked[8]==4)&&(menuLocked[9]==2 || menuLocked[9]==4)&&(menuLocked[10]==2 || menuLocked[10]==4))
          return true;
        else
          return false;
      }

      if(obiettivo=='pianeta'){
        if((menuLocked[11]==2 || menuLocked[11]==4)&&(menuLocked[12]==2 || menuLocked[12]==4)&&(menuLocked[13]==2 || menuLocked[13]==4)&&(menuLocked[14]==2 || menuLocked[14]==4)&&(menuLocked[15]==2 || menuLocked[15]==4)&&(menuLocked[16]==2 || menuLocked[16]==4))
          return true;
        else
          return false;
      }
      
      if(obiettivo=='direzione_pianificazione'){
        if(menuLocked[17]==2 || menuLocked[17]==4)
          return true;
        else
          return false;
      }
      if(obiettivo=='direzione_innovazione_impatto'){
        if(menuLocked[18]==2 || menuLocked[18]==4)
          return true;
        else
          return false;
      }
      if(obiettivo=='convenzioni_progetti_speciali'){
        if(menuLocked[19]==2 || menuLocked[19]==4)
          return true;
        else
          return false;
      }                
      return false;
    };

    const deleteItem = function(id) {              
      if( properties.verboseLog==1)
        console.log("DELETE",id,item);
      setLoading(true);
      doPostDelete(id);      
    }

    const closeDialogPratica = function() {              
      if( properties.verboseLog==1)
        console.log("PRATICA",item);
      setDialogPratica(false);
      setDialogMovePratica(false);
    }

    const closeDialogAmbito = function() {              
      if( properties.verboseLog==1)
        console.log("AMBITO",item);
      setDialogAmbito(false);
      setDialogMoveAmbito(false);
    }

    const closeDialogDocumento = function() {              
      if( properties.verboseLog==1)
        console.log("DOCUMENTO",item);
      setDialogDocumento(false);
      setDialogMoveDocumento(false);
    }

    const handleChange = (event) => {
      setItemChanged(true);      
      
      setMovedSector(event.target.value);
      if( properties.verboseLog==1)
        console.log("HC",event, event.target.value);
    };

    const saveDialogItem = function() {      
      if(itemChanged){
        if( properties.verboseLog==1)
          console.log("something changed in the item",item.IpotesiIntervento);
        if(item.IpotesiIntervento=="" ||item.IpotesiIntervento=="0.00"|| item.IpotesiIntervento=="0.00€"||item.IpotesiIntervento=="0.00 €"||item.IpotesiIntervento=="€0.00"
          ||item.IpotesiIntervento=="€ 0.00"||item.IpotesiIntervento=="€ 0.00 " ||item.IpotesiIntervento=="0" ||item.IpotesiIntervento=="€0"||item.IpotesiIntervento=="€ 0" ){
            if( properties.verboseLog==1)
              console.log("IPOTESI A ZERO",item);
          item.IpotesiIntervento="0.00";
          setAlert1(
            <SweetAlert
            warning
            closeOnClickOutside ={true}
            showCancel ={true}
            showConfirm={true}
            style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
            
            title={"La pratica prevede un'Ipotesi d'Intervento priva di importo, procedere lo stesso?"}
            onConfirm={() => {
                setAlert1(null);
                setLoading(true);
                doPostUpdateMinimal(item);
                setItemChanged(false);
                setDialogPratica(false);
                setDialogAmbito(false);
                setDialogDocumento(false);
                doPostStatiMissioniETotaliObiettivi();
            }}           
            onCancel={() => {
                setAlert1(null);                
            }}
            confirmBtnText={"Si"}    
            focusCancelBtn= {true}                    
            cancelBtnText={"No"}                       
            confirmBtnCssClass="custom-btn custom-btn-success" 
            cancelBtnCssClass="custom-btn custom-btn-cancel"                                  
          >
          </SweetAlert>
          );
        } else {
          setLoading(true);
          doPostUpdateMinimal(item);
          setItemChanged(false);
          setDialogPratica(false);
          setDialogAmbito(false);
          setDialogDocumento(false);
          doPostStatiMissioniETotaliObiettivi();
        }        
      }
      else{
        if(item.IpotesiIntervento=="" ||item.IpotesiIntervento=="0.00"|| item.IpotesiIntervento=="0.00€"||item.IpotesiIntervento=="0.00 €"||item.IpotesiIntervento=="€0.00"||item.IpotesiIntervento=="€ 0.00"||item.IpotesiIntervento=="€ 0.00 " ||item.IpotesiIntervento=="0" ||item.IpotesiIntervento=="€0"||item.IpotesiIntervento=="€ 0" ){
          if( properties.verboseLog==1)
            console.log("IPOTESI A ZERO",item);          
          setAlert1(
            <SweetAlert
            warning
            closeOnClickOutside ={true}
            showCancel ={true}
            showConfirm={true}
            style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
            
            title={"La pratica prevede un'Ipotesi d'Intervento priva di importo, procedere lo stesso?"}
            onConfirm={() => {
                setAlert1(null);   
                setItemChanged(false);
                setDialogPratica(false);
                setDialogAmbito(false);
                setDialogDocumento(false);                
            }}           
            onCancel={() => {
                setAlert1(null);                
            }}
            confirmBtnText={"Si"}    
            focusCancelBtn= {true}                    
            cancelBtnText={"No"}                       
            confirmBtnCssClass="custom-btn custom-btn-success" 
            cancelBtnCssClass="custom-btn custom-btn-cancel"                                  
          >
          </SweetAlert>
          );
        }
      }      
      if( properties.verboseLog==1)
        console.log(" SAVE ITEM",item);      
    }

    const saveDialogItemNoPratica = function() {
      if(itemChanged){
        if( properties.verboseLog==1)
          console.log("something changed in the item",item);
        setLoading(true);
        doPostUpdateMinimal(item);
        setItemChanged(false);
        
      }
      if( properties.verboseLog==1)
        console.log(" SAVE ITEM",item);
      setDialogPratica(false);
      setDialogAmbito(false);
      setDialogDocumento(false);
      doPostStatiMissioniETotaliObiettivi();
    }

    const saveDialogMoveItem = function() {
      if( properties.verboseLog==1)
        console.log(" SAVE MOVE ITEM",item, movedSector,itemChanged);
      if(itemChanged){
        if( properties.verboseLog==1)
          console.log("something changed in the item",item);        
        doPostMove(item.id,movedSector);
        setItemChanged(false);
      }            
      setDialogMovePratica(false);
      setDialogMoveAmbito(false);
      setDialogMoveDocumento(false);
      doPostStatiMissioniETotaliObiettivi();
    }

    const openDialog = function(tipo) {
      if(tipo=='Pratica'){
          if( properties.verboseLog==1)
            console.log("PRATICA",item);
          setDialogPratica(true);
          setDialogAmbito(false);
          setDialogDocumento(false);
      }
      if(tipo=='Ambito'){
          if( properties.verboseLog==1)
            console.log("AMBITO",item);
          setDialogAmbito(true);
          setDialogPratica(false);
          setDialogDocumento(false);
      }
      if(tipo=='Documento'){
          if( properties.verboseLog==1)
            console.log("DOCUMENTO",item);
          setDialogDocumento(true);
          setDialogPratica(false);
          setDialogAmbito(false);
      }
    }

    const openMoveDialog = function(tipo) {
      if(tipo=='Pratica'){
          if( properties.verboseLog==1)
            console.log("M PRATICA",item, item.Settore);          
          setDialogMovePratica(true);
          setDialogMoveAmbito(false);
          setDialogMoveDocumento(false);
      }
      if(tipo=='Ambito'){
          if( properties.verboseLog==1)
            console.log("M AMBITO",item);
          setDialogMoveAmbito(true);
          setDialogMovePratica(false);
          setDialogMoveDocumento(false);
      }
      if(tipo=='Documento'){
          if( properties.verboseLog==1)
            console.log("M DOCUMENTO",item);
          setDialogMoveDocumento(true);
          setDialogMovePratica(false);
          setDialogMoveAmbito(false);
      }
    }

    let Euro = new Intl.NumberFormat('it-IT',{
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const updateUI = function(jsonData) {  
      if( properties.verboseLog==1)    
        console.log("UPDATEUI-CV");
      setData(jsonData);    
      if( properties.verboseLog==1)
        console.log("UPDATE UI",jsonData)  ;
      setCards(jsonData.Indice);
      setMingrantAvailable(jsonData.Minigrant);
      if( properties.verboseLog==1)
        console.log("OUI",jsonData,jsonData.Indice);            
      setLoading(false);
    }

  
    const doPostDelete = function(idtoBeRemoved) {
      if( properties.verboseLog==1){
          console.log("sector",localStorage.getItem("Settore"));
          console.log("indice version",localStorage.getItem("IndiceVersion"));
          console.log("seduta", JSON.parse(localStorage.getItem("Indice")).Seduta);          
      }
      var xhr = new XMLHttpRequest();        
      
      xhr.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/gestioneIndice.php',true);
      xhr.withCredentials = true;
      xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

      xhr.onload = function (e) {      
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {         
                    if( properties.verboseLog==1)                     
                      console.log("OK92",xhr);
                    var responseText = xhr.responseText;                    
                    var obj=JSON.parse(responseText);
                    if( properties.verboseLog==1)
                      console.log("OK193",obj,responseText);                      
                    if (obj.status === "207") {  
                      console.log("Errore 207");  
                      alert("La pagina verrà ricaricata perchè un altro utente sta apportando modifiche alla stessa sezione...");      
                    }               
              doPostSedute2();              
              doPostStatiMissioniETotaliObiettivi();
              }
                else {                                
                    alert("Errore recuperando i dati dell'indice");
                    setLoading(false);
                    navigate("/indice/init",{ replace: true });                  
                }
            }
        };

        xhr.onerror = function (e) {
            console.error("Error 2", xhr.statusText);
        };

        var stringato= idtoBeRemoved;
        var tmp=JSON.parse(localStorage.getItem("Indice")).Seduta;
        tmp=tmp.replaceAll("/","");
        let urlEncodedData = "index=" + tmp+ "&indexVersion="+localStorage.getItem("IndiceVersion")+"&sector="+sector+"&item="+stringato+"&action=deleteFromIndice";
        if( properties.verboseLog==1)
          console.log("WERE",urlEncodedData);
        xhr.send(urlEncodedData);  
    }

    const doPostMove = function(idtoBeRemoved,destination) {                
      var xhr = new XMLHttpRequest();              
      xhr.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/gestioneIndice.php',true);
      xhr.withCredentials = true;
      xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    
      xhr.onload = function (e) {      
          if (xhr.readyState === 4) {
              if (xhr.status === 200) {  
                  if( properties.verboseLog==1)                            
                    console.log("OK392",xhr);
                  var responseText = xhr.responseText;                    
                  var obj=JSON.parse(responseText);
                  if( properties.verboseLog==1)
                    console.log("OK393",obj,responseText);                      
                  if (obj.status === "207") {  
                    console.log("207");  
                    alert("La pagina verrà ricaricata perchè un altro utente sta apportando modifiche alla stessa sezione...");      
                  }               
                  doPostSedute2();
                  doPostStatiMissioniETotaliObiettivi();
            }
              else {                               
                  alert("Errore recuperando i dati dell'indice");
                  setLoading(false);
                  navigate("/indice/init",{ replace: true });                
              }
          }
      };
    
      xhr.onerror = function (e) {
          console.error("Error 2", xhr.statusText);
      };
    
    var stringato= idtoBeRemoved;
    var tmp=JSON.parse(localStorage.getItem("Indice")).Seduta;
    tmp=tmp.replaceAll("/","");    
    let urlEncodedData = "index=" + tmp+ "&indexVersion="+localStorage.getItem("IndiceVersion")+"&sector="+sector+"&item="+stringato+"&destination="+destination+"&action=moveInsideIndice";
    if( properties.verboseLog==1)
      console.log("WERE",urlEncodedData);
    xhr.send(urlEncodedData);
      
    }

    const doPostUpdate = function(dateToBeAdded) {
      if( properties.verboseLog==1){
        console.log("sector",localStorage.getItem("Settore"));
        console.log("indice version",localStorage.getItem("IndiceVersion"));
        console.log("seduta", JSON.parse(localStorage.getItem("Indice")).Seduta);
        console.log("CheckValues",dateToBeAdded);
      }
        
      var xhr = new XMLHttpRequest();        
    
      xhr.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/gestioneIndice.php',true);
      xhr.withCredentials = true;
      xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

      xhr.onload = function (e) {      
        if (xhr.readyState === 4) {
            if (xhr.status === 200) {                              
                //console.log("OK92",xhr);
                var responseText = xhr.responseText;                    
                var obj=JSON.parse(responseText);
                if( properties.verboseLog==1)
                  console.log("OK493",obj,responseText);                      
                if (obj.status === "207") {  
                  console.log("207");  
                  alert("La pagina verrà ricaricata perchè un altro utente sta apportando modifiche alla stessa sezione...");      
                }               
           doPostSedute2();
           doPostStatiMissioniETotaliObiettivi();
          }
            else {                             
                alert("Errore recuperando i dati dell'indice");
                setLoading(false);
                navigate("/indice/init",{ replace: true });              
            }
        }
    };

    xhr.onerror = function (e) {
        console.error("Error 2", xhr.statusText);
    };


  var stringato=JSON.stringify(dateToBeAdded);  
  stringato=encode(stringato);
  var tmp=JSON.parse(localStorage.getItem("Indice")).Seduta;
  tmp=tmp.replaceAll("/","");  
  let urlEncodedData = "index=" + tmp+ "&indexVersion="+localStorage.getItem("IndiceVersion")+"&sector="+sector+"&data="+stringato+"&action=updateIndiceValues";
  if( properties.verboseLog==1)
    console.log("WERE",urlEncodedData);
  xhr.send(urlEncodedData);
    
  }

    const doPostAdd = function(dateToBeAdded) {
      if( properties.verboseLog==1){
        console.log("sector",localStorage.getItem("Settore"));
        console.log("indice version",localStorage.getItem("IndiceVersion"));
        console.log("seduta", JSON.parse(localStorage.getItem("Indice")).Seduta);
        console.log("CheckValues",dateToBeAdded);
      }
      var xhr = new XMLHttpRequest();        
  
      xhr.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/gestioneIndice.php',true);
      xhr.withCredentials = true;
      xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

      xhr.onload = function (e) {      
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {   
              if( properties.verboseLog==1)                      
                console.log("OK92",xhr);
              var responseText = xhr.responseText;                    
              var obj=JSON.parse(responseText);
              if( properties.verboseLog==1)
                console.log("OK593",obj,responseText);                      
              if (obj.status === "207") {  
                console.log("207");  
                alert("La pagina verrà ricaricata perchè un altro utente sta apportando modifiche alla stessa sezione...");      
              }                  
         doPostSedute2();         
        }
          else {                           
              alert("Errore recuperando i dati dell'indice");
              setLoading(false);
              navigate("/indice/init",{ replace: true });            
          }
      }
  };

  xhr.onerror = function (e) {
      console.error("Error 2", xhr.statusText);
  };


var stringato=dateToBeAdded;
var tmp=JSON.parse(localStorage.getItem("Indice")).Seduta;
tmp=tmp.replaceAll("/","");
let urlEncodedData = "index=" + tmp+ "&indexVersion="+localStorage.getItem("IndiceVersion")+"&sector="+sector+"&data="+stringato+"&action=addToIndice";
if( properties.verboseLog==1)
  console.log("WERE",urlEncodedData);
xhr.send(urlEncodedData);
  
    }

    const doPostSwitchMinigrant = function() {
      if( properties.verboseLog==1){
          console.log("sector",localStorage.getItem("Settore"));
          console.log("indice version",localStorage.getItem("IndiceVersion"));
          console.log("seduta", JSON.parse(localStorage.getItem("Indice")).Seduta);          
          }
          
      var xhr = new XMLHttpRequest();              
      xhr.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/gestioneIndice.php',true);
      xhr.withCredentials = true;
      xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    
      xhr.onload = function (e) {      
          if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                  if( properties.verboseLog==1)
                    console.log("OK92",xhr);
                  var responseText = xhr.responseText;                    
                  var obj=JSON.parse(responseText);
                  if( properties.verboseLog==1)
                    console.log("OK693",obj,responseText);                      
                  if (obj.status === "207") {  
                    console.log("207");  
                    alert("La pagina verrà ricaricata perchè un altro utente sta apportando modifiche alla stessa sezione...");      
                  }               
                  doPostSedute2();
              }
              else {                               
                  alert("Errore recuperando i dati dell'indice");
                  setLoading(false);
                  navigate("/indice/init",{ replace: true });                
              }
          }
      };
    
      xhr.onerror = function (e) {
          console.error("Error 2", xhr.statusText);
      };              
      var tmp=JSON.parse(localStorage.getItem("Indice")).Seduta;
      tmp=tmp.replaceAll("/","");      
      let urlEncodedData = "index=" + tmp+ "&indexVersion="+localStorage.getItem("IndiceVersion")+"&sector="+sector+"&data=Minigrant"+"&action=switchMinigrant";
      if( properties.verboseLog==1)
        console.log("WERE",urlEncodedData);
      xhr.send(urlEncodedData);      
    }
    
    const doPostCambiaFaseIndice = function() {
        if( properties.verboseLog==1){
          console.log("sector",localStorage.getItem("Settore"));
          console.log("indice version",localStorage.getItem("IndiceVersion"));
          console.log("seduta", JSON.parse(localStorage.getItem("Indice")).Seduta);          
        }
      
        var xhr = new XMLHttpRequest();                
        xhr.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/gestioneIndice.php',true);
        xhr.withCredentials = true;
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

        xhr.onload = function (e) {      
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {                              
                    if( properties.verboseLog==1)
                      console.log("OK92",xhr);
                    var responseText = xhr.responseText;                    
                    var obj=JSON.parse(responseText);
                    if( properties.verboseLog==1)
                      console.log("OK1193",obj,responseText);                                                        
                    setAlert1(
                      <SweetAlert
                      warning
                      closeOnClickOutside ={true}
                      showCancel ={false}
                      showConfirm={false}
                      style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
                      title={"L'indice è stato correttamente portato alla fase successiva."}
                      onConfirm={() => {
                          setAlert1(null);                            
                      }}           
                      onCancel={() => {
                          setAlert1(null);                          
                      }}
                    >
                    </SweetAlert>
                    );  
                    doPostSedute2();
                    doPostStatiMissioniETotaliObiettivi();
              }
                else {       
                  if (xhr.status === 406) {
                    setAlert1(
                      <SweetAlert
                      warning
                      closeOnClickOutside ={true}
                      showCancel ={false}
                      showConfirm={false}
                      style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
                      title={"Una o più missioni/direzioni non sono state bloccate correttamente e, pertanto, non è possibile cambiare fase. Controllate le anomalie e riprovate"}
                      onConfirm={() => {
                          setAlert1(null);                            
                      }}           
                      onCancel={() => {
                          setAlert1(null);                          
                      }}
                    >
                    </SweetAlert>
                    );
                    doPostStatiMissioniETotaliObiettivi();
                    setLoading(false);
                  }
                  else{
                    alert("Errore effettuando la richiesta");
                    setLoading(false);                    
                  }
                }
            }
        };

        xhr.onerror = function (e) {
            console.error("Error 2", xhr.statusText);
        };
     
        var tmp=JSON.parse(localStorage.getItem("Indice")).Seduta;
        tmp=tmp.replaceAll("/","");
        let urlEncodedData = "index=" + tmp+"&action=cambiaFaseIndiceSeduta";
        if( properties.verboseLog==1)
          console.log("REQ",urlEncodedData);
        xhr.send(urlEncodedData);  
    }

    const doPostUpdateMinimal = function(dateToBeAdded) {
        if( properties.verboseLog==1){
          console.log("sector",localStorage.getItem("Settore"));
          console.log("indice version",localStorage.getItem("IndiceVersion"));
          console.log("seduta", JSON.parse(localStorage.getItem("Indice")).Seduta);
          console.log("CheckValues",dateToBeAdded);
        }
        var xhr = new XMLHttpRequest();        
        
        xhr.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/gestioneIndice.php',true);
        xhr.withCredentials = true;
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

        xhr.onload = function (e) {      
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {      
                    if( properties.verboseLog==1)                        
                      console.log("OK92",xhr);
                    var responseText = xhr.responseText;                    
                    var obj=JSON.parse(responseText);
                    if(properties.verboseLog==1)
                      console.log("OK893",obj,responseText);                                    
                    if (obj.status === "207") {  
                      console.log("207");  
                      alert("La pagina verrà ricaricata perchè un altro utente sta apportando modifiche alla stessa sezione...");      
                    }               
              doPostSedute2();              
              }
                else {                           
                    alert("Errore recuperando i dati dell'indice");
                    setLoading(false);
                    navigate("/indice/init",{ replace: true });            
                }
            }
        };

        xhr.onerror = function (e) {
            console.error("Error 2", xhr.statusText);
        };

        var stringato=JSON.stringify(dateToBeAdded);
        stringato=encode(stringato);
        var tmp=JSON.parse(localStorage.getItem("Indice")).Seduta;
        tmp=tmp.replaceAll("/","");
        let urlEncodedData = "index=" + tmp+ "&indexVersion="+localStorage.getItem("IndiceVersion")+"&sector="+sector+"&item="+stringato+"&action=changeIndiceValues";;
        if( properties.verboseLog==1)
          console.log("DOPOST UPDATE MINIMAL", urlEncodedData);
        xhr.send(urlEncodedData);  
    }
   
    const doPostPrintToExcel = function() {
      if( properties.verboseLog==1)
        console.log("doing POST PRINT TO EXCEL");
      var xhr2 = new XMLHttpRequest();                
      var tmp=idSeduta.substring(8)+ idSeduta.substring(5,7)+idSeduta.substring(0,4);      
      xhr2.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/gestioneIndice.php',true);
      xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
      xhr2.withCredentials = true;
      xhr2.responseType = 'blob';
      xhr2.onload = function (e) {      
          if (xhr2.readyState === 4) {
              if (xhr2.status === 200) {     
                  if( properties.verboseLog==1)
                    console.log("OK92",xhr2);
                  const blob = new Blob([xhr2.response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });                              
                  const downloadUrl = window.URL.createObjectURL(blob);                            
                  const a = document.createElement('a');
                  a.href = downloadUrl;
                  a.download = "indice_"+tmp+".xlsx";
                  document.body.appendChild(a);
                  a.click();                            
                  a.remove();                            
                  window.URL.revokeObjectURL(downloadUrl);                
                  setLoading(false);                                  
              } 
              else {                    
                  console.log("Errore 2671 stampando su excel");                      
              }
          }
      };

      xhr2.onerror = function (e) {
          console.error("Error 22", xhr2.statusText);
      };            
      let urlEncodedData = "index="+tmp+"&action=printToExcel";
      if( properties.verboseLog==1)
        console.log("DATA",urlEncodedData);
      xhr2.send(urlEncodedData);            
    }

    const doPostStatiMissioniETotaliObiettivi = function() {
        if( properties.verboseLog==1)
          console.log("doing POST TOTALE OBIETTIVI E STATO MISSIONI");
        var xhr2 = new XMLHttpRequest();                
        var tmp=idSeduta.substring(8)+ idSeduta.substring(5,7)+idSeduta.substring(0,4);
        setLoading(true);        
        xhr2.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/gestioneIndice.php',true);
        xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
        xhr2.withCredentials = true;
        xhr2.onload = function (e) {      
            if (xhr2.readyState === 4) {
                if (xhr2.status === 200) {   
                    if( properties.verboseLog==1)                           
                      console.log("OK92",xhr2);                  
                    var responseText = xhr2.responseText;                    
                    var obj=JSON.parse(responseText);
                    if( properties.verboseLog==1)
                      console.log("OK1493",obj);     
                    var tre1= JSON.parse(obj.amounts);                  
                    if( properties.verboseLog==1)
                      console.log("TRE1",tre1);     
                    const up11=[];
                    up11.push(Euro.format(tre1[0]));
                    up11.push(Euro.format(tre1[1]));
                    up11.push(Euro.format(tre1[2]));
                    setTotaleObiettivi(up11);      
                    
                    var tre= JSON.parse(obj.status);
                    if( properties.verboseLog==1)
                      console.log("TRE2",tre);
                    const values = [];
                    values.push(tre['cultura']);
                    values.push(tre['attrattivita']);
                    values.push(tre['competenze']);
                    values.push(tre['custodia']);
                    values.push(tre['partecipazione']);
                    values.push(tre['persone']);
                    values.push(tre['abitare']);
                    values.push(tre['lavoro']);
                    values.push(tre['educazione']);
                    values.push(tre['comunita']);
                    values.push(tre['inclusione']);
                    values.push(tre['pianeta']);
                    values.push(tre['ricerca']);
                    values.push(tre['innovazione']);
                    values.push(tre['benessere']);
                    values.push(tre['internazionalizzazione']);
                    values.push(tre['ambiente']);
                    values.push(tre['direzione_pianificazione']);
                    values.push(tre['direzione_innovazione_impatto']);
                    values.push(tre['convenzioni_progetti_speciali']);
                    setMenuLocked(values);                                   
                    setLoading(false);                                  
                } 
                else {                    
                    console.log("Errore 2671: Recuperando dati in getInfoStatoTotali");                      
                }
            }
        };
    
        xhr2.onerror = function (e) {
            console.error("Error 22", xhr2.statusText);
        };            
        let urlEncodedData = "index="+tmp+"&action=getInfoStatoTotali";
        if( properties.verboseLog==1)
          console.log("DATA",urlEncodedData);
        xhr2.send(urlEncodedData);              
    }
    
    const doPostLockObiettivo = function(obiettivo) {
        if( properties.verboseLog==1)
          console.log("doing POST LOCK OBIETTIVO"+obiettivo);
        var xhr2 = new XMLHttpRequest();                
        var tmp=idSeduta.substring(8)+ idSeduta.substring(5,7)+idSeduta.substring(0,4);
        xhr2.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/gestioneIndice.php',true);
        xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
        xhr2.withCredentials = true;
        xhr2.onload = function (e) {      
            if (xhr2.readyState === 4) {
                if (xhr2.status === 200) {                              
                    if( properties.verboseLog==1)
                      console.log("OK92",xhr2);
                    var responseText = xhr2.responseText;                    
                    var obj=JSON.parse(responseText);
                    if( properties.verboseLog==1)
                      console.log("OK2493",obj);                         
                    setAlert1(
                      <SweetAlert
                      warning
                      closeOnClickOutside ={true}
                      showCancel ={false}
                      showConfirm={false}
                      style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
                      title={"L'Obiettivo o la Direzione è stata bloccata con successo."}
                      onConfirm={() => {
                          setAlert1(null);                            
                      }}           
                      onCancel={() => {
                          setAlert1(null);                          
                      }}
                    >
                    </SweetAlert>
                    );  
                  doPostSedute2();
                  setLoading(false);             
                  doPostStatiMissioniETotaliObiettivi();                      
                } 
                else {                    
                    console.log("Errore 677: Recuperando indice in doPostSedute2");    
                    setLoading(false);                                                    
                    setAlert1(
                      <SweetAlert
                      warning
                      closeOnClickOutside ={true}
                      showCancel ={false}
                      showConfirm={false}
                      style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
                      title={"Una o più pratiche non sono complete e, pertanto, non possono essere bloccate. Controllate le anomalie e riprovate"}
                      onConfirm={() => {
                          setAlert1(null);                            
                      }}           
                      onCancel={() => {
                          setAlert1(null);                          
                      }}
                    >
                    </SweetAlert>
                    );
                    doPostSedute2();
                    doPostStatiMissioniETotaliObiettivi();                          
                }
            }
        };
    
        xhr2.onerror = function (e) {
            console.error("Error 22", xhr2.statusText);
        };            
        let urlEncodedData = "index="+tmp+"&obiettivo="+obiettivo+"&action=lockObiettivo";                    
        if( properties.verboseLog==1)
          console.log("DATA",urlEncodedData);
        xhr2.send(urlEncodedData);              
    }  

    const doPostUnlockObiettivo = function(obiettivo) {
          if( properties.verboseLog==1)
            console.log("doing POST UNLOCK OBIETTIVO"+obiettivo);
          var xhr2 = new XMLHttpRequest();                
          var tmp=idSeduta.substring(8)+ idSeduta.substring(5,7)+idSeduta.substring(0,4);              
          xhr2.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/gestioneIndice.php',true);
          xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
          xhr2.withCredentials = true;
          xhr2.onload = function (e) {      
              if (xhr2.readyState === 4) {
                  if (xhr2.status === 200) {                              
                      if( properties.verboseLog==1)
                        console.log("OK92",xhr2);
                      var responseText = xhr2.responseText;                    
                      var obj=JSON.parse(responseText);
                      if( properties.verboseLog==1)
                        console.log("OK4493",obj);                           
                      setAlert1(
                        <SweetAlert
                        warning
                        closeOnClickOutside ={true}
                        showCancel ={false}
                        showConfirm={false}
                        style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
                        title={"L'Obiettivo o la Direzione è stato sbloccata correttamente."}
                        onConfirm={() => {
                            setAlert1(null);                            
                        }}           
                        onCancel={() => {
                          setAlert1(null);                          
                        }}
                      >
                      </SweetAlert>
                    );  
                    setLoading(false);             
                    doPostStatiMissioniETotaliObiettivi();                           
                  } 
                  else {                    
                      console.log("Errore 477: Recuperando indice in doPostSedute2");    
                      setLoading(false);                                                    
                      setAlert1(
                        <SweetAlert
                        warning
                        closeOnClickOutside ={true}
                        showCancel ={false}
                        showConfirm={false}
                        style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
                        title={"Una o più pratiche non sono complete e, pertanto, non possono essere bloccate. Controllate le anomalie e riprovate"}
                        onConfirm={() => {
                            setAlert1(null);                            
                        }}           
                        onCancel={() => {
                            setAlert1(null);                          
                        }}
                      >
                      </SweetAlert>
                      );                      
                      doPostStatiMissioniETotaliObiettivi();                                              
                  }
              }
          };
      
          xhr2.onerror = function (e) {
              console.error("Error 22", xhr2.statusText);
          };            
          let urlEncodedData = "index="+tmp+"&obiettivo="+obiettivo+"&action=unlockObiettivo";                    
          if( properties.verboseLog==1)
            console.log("DATA",urlEncodedData);
          xhr2.send(urlEncodedData);              
    }      

    function formatDateToDDMMYYYY(data) {
        const [year, month, day] = data.split("-"); 
        return `${day}${month}${year}`; 
    }

    const doPostSedute = function(settore) {
      if( properties.verboseLog==1)
        console.log("doing sedute post CV");
      var xhr2 = new XMLHttpRequest();                      
      xhr2.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/gestioneIndice.php',true);
      xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
      xhr2.withCredentials = true;
      xhr2.onload = function (e) {      
          if (xhr2.readyState === 4) {
              if (xhr2.status === 200) {    
                  if( properties.verboseLog==1)                          
                    console.log("OK92",xhr2);
                  var responseText = xhr2.responseText;                    
                  var obj=JSON.parse(responseText);
                  if( properties.verboseLog==1)
                    console.log("OK9893",obj, obj.status);          
                  if(obj.status==="204" ){                                                   
                      console.log("OK INDICE 204",xhr2);                        
                      const jsonListIndici = JSON.parse(localStorage.getItem("Indice"));
                      updateUI(jsonListIndici);    
                      setLoading(false);                
                  }              
                  else{
                      //status 201
                      console.log("OK INDICE 201",xhr2);  
                      
                      try{                        
                          localStorage.setItem("Indice", obj.content);
                          localStorage.setItem("IndiceVersion", obj.date);                                      
                      }
                      catch(error) {
                          console.log("LOCALSTORAGE ISSUE", error);
                      }                          
                      const jsonListIndici = JSON.parse(obj.content);                  
                      updateUI(jsonListIndici);   
                      setLoading(false);                     
                  }
              } 
              else {                    
                  console.log("Errore 9371: Recuperando indice in doPostSedute2");                      
              }
          }
      };
  
      xhr2.onerror = function (e) {
          console.error("Error 22", xhr2.statusText);
      };
      
      let urlEncodedData = null;      
      if( properties.verboseLog==1)
        console.log( "QUINDI00");
      const currentData=localStorage.getItem("IndiceVersion");    
      if( properties.verboseLog==1)
        console.log("CURRENTDATA",currentData);          
      urlEncodedData = "indexVersion=" + currentData+"&sector="+settore+"&index="+formatDateToDDMMYYYY(dataSeduta)+"&action=checkIndiceSeduta";          
      if( properties.verboseLog==1)
        console.log("DATA",urlEncodedData);
         
      if( properties.verboseLog==1)
        console.log("CALVIEW",urlEncodedData);
      xhr2.send(urlEncodedData);
      }

    const doPostSedute7 = function() {
        //chiamata solo dallo use effect
        var xhr2 = new XMLHttpRequest();                
        
        xhr2.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/gestioneIndice.php',true);
        xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
        xhr2.withCredentials = true;
        xhr2.onload = function (e) {      
            if (xhr2.readyState === 4) {
                if (xhr2.status === 200) {        
                    if( properties.verboseLog==1)                      
                      console.log("OK92",xhr2);
                    var responseText = xhr2.responseText;                    
                    var obj=JSON.parse(responseText);
                    if( properties.verboseLog==1)
                      console.log("OK8693",obj, obj.status);                          
                    if(obj.status==="204" ){                             

                        console.log("OK INDICE7 204",xhr2);                        
                        const jsonListIndici = JSON.parse(localStorage.getItem("Indice"));
                        updateUI(jsonListIndici);                            
                        doPostStatiMissioniETotaliObiettivi();
                        setLoading(false);                
                    }              
                    else{
                        //status 201
                        console.log("OK INDICE7 201",xhr2);  
                        
                        try{
                            localStorage.setItem("Indice", obj.content);
                            localStorage.setItem("IndiceVersion", obj.date);                                      
                        }
                        catch(error) {
                            console.log("LOCALSTORAGE ISSUE", error);
                        }                          
                        const jsonListIndici = JSON.parse(obj.content);                    
                        updateUI(jsonListIndici);   
                        setLoading(false);                     
                    }
                } 
                else {                    
                    console.log("Errore 3871: Recuperando indice in doPostSedute2");                      
                }
            }
        };
    
        xhr2.onerror = function (e) {
            console.error("Error 22", xhr2.statusText);
        };
        
        let urlEncodedData = null;
        var sectoring="cultura";
        if (localStorage.indice!=='' &&localStorage.indice!==undefined){
          if( properties.verboseLog==1)
            console.log("WWWERE",localStorage.indice);
          if(canBeSeen("1")){
            sectoring="cultura";
            setMenuItemSelected(74);
            setColorItemSelected("#E42568");
            setPrintable("Cultura");
          }
          else if(canBeSeen("2")){
            sectoring="persone";
            setMenuItemSelected(67);
            setColorItemSelected("#F8B902");            
            setPrintable("Persone");
          }
          else if(canBeSeen("3")){
            sectoring="pianeta";
            setMenuItemSelected(85);
            setColorItemSelected("#338FD2");
            setPrintable("Pianeta");
          }
          else if(canBeSeen("4")){
            sectoring="direzione_pianificazione";
            setMenuItemSelected(90);
            setColorItemSelected("#FAD480");
            setPrintable("DIREZIONE PIANIFICAZIONE, STUDI E VALUTAZIONE");
          }
          else if(canBeSeen("5")){
            sectoring="direzione_innovazione_impatto";
            setMenuItemSelected(95);
            setColorItemSelected("#FAD480");
            setPrintable("DIREZIONE INNOVAZIONE DI IMPATTO");
          }
          else {
            sectoring="cultura";
            setMenuItemSelected(74);
            setColorItemSelected("#E42568");
            setPrintable("Cultura");
          }
        }
        if( properties.verboseLog==1)
          console.log("WWWWWWWWW",sectoring);
        setSector(sectoring);
        
        if( properties.verboseLog==1)
          console.log( "QUINDI00");
        const currentData=localStorage.getItem("IndiceVersion");            
                    
        urlEncodedData = "indexVersion=" + currentData+"&sector="+sectoring+"&index="+formatDateToDDMMYYYY(dataSeduta)+"&action=checkIndiceSeduta";
        if( properties.verboseLog==1)
          console.log("DATA",urlEncodedData);
    
        if( properties.verboseLog==1)
          console.log("CALVIEW2",urlEncodedData);
        xhr2.send(urlEncodedData);
        }

    const doPostSedute2 = function() {
      if( properties.verboseLog==1)
        console.log("doing sedute post CV");
      var xhr2 = new XMLHttpRequest();                
    
      xhr2.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/gestioneIndice.php',true);
      xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
      xhr2.withCredentials = true;
      xhr2.onload = function (e) {      
          if (xhr2.readyState === 4) {
              if (xhr2.status === 200) {    
                  if( properties.verboseLog==1)                          
                    console.log("OK92",xhr2);
                  var responseText = xhr2.responseText;                    
                  var obj=JSON.parse(responseText);
                  if( properties.verboseLog==1)
                    console.log("OK8693",obj, obj.status);                          
                  if(obj.status==="204" ){                             
                      console.log("OK INDICE2 204",xhr2);                        
                      const jsonListIndici = JSON.parse(localStorage.getItem("Indice"));
                      updateUI(jsonListIndici);                          
                      doPostStatiMissioniETotaliObiettivi();
                      setLoading(false);                
                  }              
                  else{
                      //status 201
                      console.log("OK INDICE2 201",xhr2);                        
                      try{                      
                          localStorage.setItem("Indice", obj.content);
                          localStorage.setItem("IndiceVersion", obj.date);                                      
                      }
                      catch(error) {
                          console.log("LOCALSTORAGE ISSUE", error);
                      }                          
                      const jsonListIndici = JSON.parse(obj.content);                  
                      updateUI(jsonListIndici);   
                      setLoading(false);                     
                  }
              } 
              else {                    
                  console.log("Errore 3871: Recuperando indice in doPostSedute2");                      
              }
          }
      };

      xhr2.onerror = function (e) {
          console.error("Error 22", xhr2.statusText);
      };
      
      let urlEncodedData = null;
            
      if( properties.verboseLog==1)
        console.log( "QUINDI00");
      const currentData=localStorage.getItem("IndiceVersion");          
      urlEncodedData = "indexVersion=" + currentData+"&sector="+sector+"&index="+formatDateToDDMMYYYY(dataSeduta)+"&action=checkIndiceSeduta";
      if( properties.verboseLog==1)
        console.log("DATA",urlEncodedData);
      
      if( properties.verboseLog==1)
        console.log("CALVIEW3",urlEncodedData);
      xhr2.send(urlEncodedData);
      }

    const conversionFase= function( index){
        if(index=="0")          
          return "PROVVISORIO -";
        if(index=="1")          
          return "BOZZA -";
        if(index=="2")          
          return "CHIUSURA -";
    }

    const isPossibleNextFase= function( index){
        if(index=="0")
          return true;
        if(index=="1")
          return true;
        if(index=="2")
          return false;
    }

    const conversionSector= function( sector){
      if(sector=="Cultura")
        return "cultura";
      if(sector=="Attrattività")  
        return "attrattivita";
      if(sector=="Competenze")  
        return "competenze";
      if(sector=="Custodia")  
        return "custodia";
      if(sector=="Partecipazione")  
        return "partecipazione";
      if(sector=="Persone")  
        return "persone";
      if(sector=="Abitare")  
        return "abitare";
      if(sector=="Lavoro")  
        return "lavoro";
      if(sector=="Educazione")  
        return "educazione";
      if(sector=="Comunità")  
        return "comunita";
      if(sector=="Inclusione")  
        return "inclusione";
      if(sector=="Pianeta")  
        return "pianeta";
      if(sector=="Ricerca")  
        return "ricerca";
      if(sector=="Innovazione")  
        return "innovazione";     
      if(sector=="Benessere")  
        return "benessere";
      if(sector=="Internazionalizzazione")  
        return "internazionalizzazione";
      if(sector=="Ambiente")  
        return "ambiente";
      if(sector=="DIREZIONE PIANIFICAZIONE, STUDI E VALUTAZIONE")  
        return "direzione_pianificazione";   
      if(sector=="DIREZIONE INNOVAZIONE DI IMPATTO")  
        return "direzione_innovazione_impatto";     
      if(sector=="CONVENZIONI, PROGETTI SPECIALI, ULTERIORI PRATICHE E DOCUMENTI")  
        return "convenzioni_progetti_speciali";  
      return "unknown";                 
    }

    const onChanged = function( treeData) {
        //chiamata quando viene spostato un oggetto all'interno dell'albero -> non impatta calcolo totali
        if( properties.verboseLog==1)
          console.log("ON CHANGED",treeData);
        setLoading(true);
        doPostUpdate(treeData);
    }

    const onFabClick= (id, e ) => {                   
        if( properties.verboseLog==1)
          console.log("OFC",e, id,data.IndiceAttrattivita);  
        if(id=='excel'){            
          setLoading(true);
          doPostPrintToExcel();
        }  
        if(id=='cultura'){            
          setLoading(true);
          if(isBloccatoObiettivo("cultura")){
            if( properties.verboseLog==1)
              console.log("DEVO SSBLOCCARE");
            doPostUnlockObiettivo("cultura");
          } else{
            if( properties.verboseLog==1)
              console.log("DEVO BLOCCARE");
            doPostLockObiettivo("cultura");
          }
        }    
        if(id=='persone'){            
          setLoading(true);
          if(isBloccatoObiettivo("persone"))
            doPostUnlockObiettivo("persone");
          else
            doPostLockObiettivo("persone");          
        }                          
        if(id=='pianeta'){            
          setLoading(true);
          if(isBloccatoObiettivo("pianeta"))
            doPostUnlockObiettivo("pianeta");
          else
            doPostLockObiettivo("pianeta");          
        }                  
        if(id=='pianificazione_studi'){            
          setLoading(true);
          if(isBloccatoObiettivo("direzione_pianificazione"))
            doPostUnlockObiettivo("direzione_pianificazione");
          else
            doPostLockObiettivo("direzione_pianificazione");          
        }        
        if(id=='innovazione_impatto'){            
          setLoading(true);
          if(isBloccatoObiettivo("direzione_innovazione_impatto"))
            doPostUnlockObiettivo("direzione_innovazione_impatto");
          else
            doPostLockObiettivo("direzione_innovazione_impatto");          
        }            
        if(id=='convenzioni_progetti_speciali'){            
          setLoading(true);
          if(isBloccatoObiettivo("convenzioni_progetti_speciali"))
            doPostUnlockObiettivo("convenzioni_progetti_speciali");
          else
            doPostLockObiettivo("convenzioni_progetti_speciali");          
        }     
        if(id=='tutto'){            
          setLoading(true);
          doPostLockObiettivo("all");
        }         
        if(id=='pratica'){            
            setLoading(true);
            doPostAdd("Pratica");                        
        }
        if(id=='documento'){           
            setLoading(true);
            doPostAdd("Documento");
        }
        if(id=='ambito'){           
            setLoading(true);
            doPostAdd("Ambito");
        }
        if(id=='minigrant'){
          if( properties.verboseLog==1)
            console.log("minigrant");
          setLoading(true);
          doPostSwitchMinigrant();
        }
        if(id=='cambiaFase'){
          if( properties.verboseLog==1)
            console.log("cambia Fase");
          setLoading(true);
          doPostCambiaFaseIndice();          
        }
        if(id!='excel'){            
          setSecondLevelButtons(false);          
          doPostStatiMissioniETotaliObiettivi();
        }  
    };

    useEffect(() => {     
        doPostStatiMissioniETotaliObiettivi();
        setLoading(true);
        if(!window.cordova){        
          //CHECK AUTHENTICATION
              var xhr = new XMLHttpRequest();                              
              xhr.open('POST', properties.productionUrl+properties.productionUrlSuffix +'/core/rest/ping.php',true);
              xhr.withCredentials = true;
              xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
      
              xhr.onload = function (e) {
                  if (xhr.readyState === 4) {
                      if (xhr.status === 200) {     
                        if( properties.verboseLog==1)                    
                          console.log("OK",xhr);                                               
                        doPostSedute7();
                        if( properties.verboseLog==1)
                          console.log("Chiamaato", "7");                      
                  } else {
                      console.error("Error 1", xhr);   
                      localStorage.clear();
                      setLoading(false);                             
                      navigate("/indice/login");  
                  }
              }
              };      
              xhr.onerror = function (e) {
                  console.error("Error 2", xhr);
                  localStorage.clear();                
                  navigate("/indice/login");  
              };               
              let urlEncodedData = null; 
              xhr.send(urlEncodedData);
          //CHECK AUTHENTICATION
          }
          if( properties.verboseLog==1){
            console.log ("UE", cards);
            console.log(calculateTotalCost(cards));
          }          
    }, []);


    const calculateTotalCost = (data) => {
      let totalCost = 0;
  
      const traverse = (obj) => {  
          if (obj && typeof obj === 'object') {
              if (obj.hasOwnProperty('IpotesiIntervento')) {
                if( properties.verboseLog==1)
                  console.log("EE",obj['IpotesiIntervento']);
                if(obj['IpotesiIntervento']!='')                                    
                    totalCost += parseFloat(obj.IpotesiIntervento);
              }                
              for (let key in obj) {
                  if (obj.hasOwnProperty(key) && typeof obj[key] === 'object') {
                      traverse(obj[key]); // Recursive call
                  }
              }
          }
      };  
      traverse(data);     
      return totalCost;
    };

    const handleMenuItemClick  = function(e,value, labelText, menuItemNumber) {           
        if( properties.verboseLog==1)
          console.log("HMIC",value);
        localStorage.removeItem("Indice");
        localStorage.removeItem("IndiceVersion");
        setSecondLevelButtons(false);
        
        setForthLevelButtons(false);
        setSector(value);
        setLoading(true);        
        doPostSedute(value);
        e.stopPropagation();  
        setMenuItemSelected(menuItemNumber);
            
        if(value=='cultura'||value=='attrattivita'||value=='competenze'||value=='custodia'||value=='partecipazione')
          setColorItemSelected("#E42568");
        else if(value=='persone'||value=='abitare'||value=='lavoro'||value=='educazione'||value=='comunita'||value=='inclusione')
          setColorItemSelected("#F8B902");
        else if(value=='pianeta'||value=='ricerca'||value=='innovazione'||value=='benessere'||value=='internazionalizzazione'||value=='ambiente')
          setColorItemSelected("#338FD2");
        else if(value=='convenzioni_progetti_speciali')
          setColorItemSelected("#DDDDDD");
        else
          setColorItemSelected("#FAD480");
        setPrintable(labelText);
        if( properties.verboseLog==1){
          console.log('handleMenuItemClick', value,e);
          console.log('handleMenuItemClick2', sector);
        }
        
        doPostStatiMissioniETotaliObiettivi();
        if( properties.verboseLog==1)
          console.log("INDEX:"+fromSectorToPosition(value));
    };

    const fromSectorToPosition  = function(value){
      let pos=0;
      if(value=='cultura')
        pos=0;
      if(value=='attrattivita')
      pos=1;
      if(value=='competenze')
      pos=2;
      if(value=='custodia')
      pos=3;
      if(value=='partecipazione')
      pos=4;
      
      if(value=='persone')
      pos=5;
      if(value=='abitare')
      pos=6;
      if(value=='lavoro')
      pos=7;
      if(value=='educazione')
      pos=8;
      if(value=='comunita')
      pos=9;
      if(value=='inclusione')
      pos=10;

      
      if(value=='pianeta')
      pos=11;
      if(value=='ricerca')
      pos=12;
      if(value=='innovazione')
      pos=13;
      if(value=='benessere')
      pos=14;
      if(value=='internazionalizzazione')
      pos=15;
      if(value=='ambiente')
      pos=16;
    
      if(value=='direzione_pianificazione')
      pos=17;

      if(value=='direzione_innovazione_impatto')
      pos=18;
      
      if(value=='convenzioni_progetti_speciali')
      pos=19;

      return pos;
    }

    const isPossibleToMoveInto  = function(value){
       var pos=fromSectorToPosition(value);
       var ids=localStorage.getItem("indice");       
       if( properties.verboseLog==1)
          console.log("WW", pos, ids, value, menuLocked[pos]);
       if(menuLocked[pos]==2 || menuLocked[pos]==4)
          return false;

       if ((pos<5) && (ids.includes("1"))){      
          return true;       
       }
       if ((pos>4) && (pos<11) && (ids.includes("2"))){      
          return true;       
       }

       if ((pos>10) && (pos<17) && (ids.includes("3"))){      
          return true;       
       }
       if ((pos==17)  && (ids.includes("4"))){      
          return true;       
       }
       if ((pos==18)  && (ids.includes("5"))){      
          return true;       
       }
       if ((pos==19)  && (ids.includes("6"))){      
          return true;       
       }
       return false;
    }
    
    return (
      <div>
         {dialogMovePratica ? (
           <Dialog open={dialogMovePratica} 
              onClose={closeDialogPratica}            
              maxWidth={"xl"} fullWidth={true} >
           <DialogTitle className= "textBlackSedute" sx={{fontWeight:'bold'}}>Sposta Pratica</DialogTitle>
           <DialogContent>           
              <div style={{border: "1px solid #d3d3d3", display:'flex', marginTop:'10px', marginBottom:'20px', width:'100%'}}>                   
                <div style={{width:"100%"}}>
                  <div style={{display:'flex', width:'100%'}}> <p className= "textBlackSedute" style={{paddingLeft:"10px",color:"black", marginTop:"10px",marginBottom:"10px" }}> (Pratica 
                    {item.IdRichiesta !='' && item.IdRichiesta !='idRichiesta'  ?(
                    <>, {item.IdRichiesta}</>  ):(<></>)}) <b style={{marginLeft:"10px"}}> {item.Ente} </b></p>              
                    {item.Localizzazione !='' && item.Localizzazione !='Localizzazione'  ? (
                    <p className= "textBlackSedute" style={{marginTop:"10px",marginBottom:"10px"}}>,  <b style={{marginLeft:"10px", textTransform:'capitalize'}}> {item.Localizzazione} </b></p> ):(<></>)} 
                  </div>
                  <div style={{display:'flex',marginLeft:"10px", width:'100%'}}> <p className= "textBlackSedute" style={{color:"black",marginTop:"10px",marginBottom:"10px"}}> <b> {item.Descrizione} </b></p>              
                  </div>                
                  <div style={{display:'flex',marginLeft:"10px",width:'100%'}}> <p className= "textBlackSedute" style={{color:"black",marginTop:"10px",marginBottom:"10px"}}> <b>€ {Euro.format(item.IpotesiIntervento)} </b></p>
                  {item.Note !='' && item.Note !='Note'  ?(
                    <p className= "textBlackSedute" style={{marginTop:"10px",marginBottom:"10px"}}> * {item.Note}</p>  ):(<></>)}              
                  </div>
              </div>              
            </div>
            <Box> 
          <FormControl fullWidth>          
                <InputLabel id="simple-select-label">Sposta in</InputLabel>
                <Select labelId="simple-select-label"  style={{fontWeight:"bold"}} defaultValue={conversionSector(item.Settore)} label="Sposta in" onChange={handleChange}>
                    <MenuItemMUI disabled={!isPossibleToMoveInto('cultura')} value={'cultura'}>Cultura</MenuItemMUI>
                    <MenuItemMUI disabled={!isPossibleToMoveInto('attrattivita')}value={'attrattivita'}>Attrattività</MenuItemMUI>                    
                    <MenuItemMUI disabled={!isPossibleToMoveInto('competenze')}value={'competenze'}>Competenze</MenuItemMUI>
                    <MenuItemMUI disabled={!isPossibleToMoveInto('custodia')}value={'custodia'}>Custodia</MenuItemMUI>
                    <MenuItemMUI disabled={!isPossibleToMoveInto('partecipazione')}value={'partecipazione'}>Partecipazione</MenuItemMUI>

                    <MenuItemMUI disabled={!isPossibleToMoveInto('persone')}value={'persone'}>Persone</MenuItemMUI>
                    <MenuItemMUI disabled={!isPossibleToMoveInto('abitare')}value={'abitare'}>Abitare</MenuItemMUI>                    
                    <MenuItemMUI disabled={!isPossibleToMoveInto('lavoro')}value={'lavoro'}>Lavoro</MenuItemMUI>
                    <MenuItemMUI disabled={!isPossibleToMoveInto('educazione')}value={'educazione'}>Educazione</MenuItemMUI>
                    <MenuItemMUI disabled={!isPossibleToMoveInto('comunita')}value={'comunita'}>Comunità</MenuItemMUI>
                    <MenuItemMUI disabled={!isPossibleToMoveInto('inclusione')}value={'inclusione'}>Inclusione</MenuItemMUI>

                    <MenuItemMUI disabled={!isPossibleToMoveInto('pianeta')}value={'pianeta'}>Pianeta</MenuItemMUI>  
                    <MenuItemMUI disabled={!isPossibleToMoveInto('ricerca')}value={'ricerca'}>Ricerca</MenuItemMUI>                    
                    <MenuItemMUI disabled={!isPossibleToMoveInto('innovazione')}value={'innovazione'}>Innovazione</MenuItemMUI>
                    <MenuItemMUI disabled={!isPossibleToMoveInto('benessere')}value={'benessere'}>Benessere</MenuItemMUI>
                    <MenuItemMUI disabled={!isPossibleToMoveInto('internazionalizzazione')}value={'internazionalizzazione'}>Internazionalizzazione</MenuItemMUI>
                    <MenuItemMUI disabled={!isPossibleToMoveInto('ambiente')}value={'ambiente'}>Ambiente</MenuItemMUI>
      
                    <MenuItemMUI disabled={!isPossibleToMoveInto('direzione_pianificazione')}value={'direzione_pianificazione'}>DIREZIONE PIANIFICAZIONE, STUDI E VALUTAZIONE</MenuItemMUI>  
                    <MenuItemMUI disabled={!isPossibleToMoveInto('direzione_innovazione_impatto')}value={'direzione_innovazione_impatto'}>DIREZIONE INNOVAZIONE DI IMPATTO</MenuItemMUI>                    
                    <MenuItemMUI disabled={!isPossibleToMoveInto('convenzioni_progetti_speciali')}value={'convenzioni_progetti_speciali'}>CONVENZIONI, PROGETTI SPECIALI, ULTERIORI PRATICHE E DOCUMENTI</MenuItemMUI>
                </Select>
          </FormControl>
          </Box> 
           </DialogContent>
           <DialogActions>
             <Button onClick={saveDialogMoveItem} style={{fontSize:18}}
             color="warning" size='large'>
               Salva
             </Button>
             <Button onClick={closeDialogPratica} style={{fontSize:18}}
             color="primary" size='large'>
               Chiudi
             </Button>
           </DialogActions>
         </Dialog>
          ):(<></>)}

{dialogMoveDocumento ? (
           <Dialog open={dialogMoveDocumento} 
              onClose={closeDialogDocumento}            
              maxWidth={"xl"} fullWidth={true} >
           <DialogTitle className= "textBlackSedute" sx={{fontWeight:'bold'}}>Sposta Documento</DialogTitle>
           <DialogContent>           
              <div className= "textBlackSedute" style={{border: "1px solid #d3d3d3", display:'flex', marginTop:'10px', marginBottom:'20px',  width:'100%'}}>                   
              <div style={{width:"100%"}}>
              <div style={{display:'flex', marginLeft:"10px", width:'100%'}}> <p className= "textBlackSedute" style={{color:"black",marginTop:"10px",marginBottom:"10px"}}>(Documento) <b style={{marginLeft:"10px"}}>{item.Descrizione} </b>  </p>           
              </div>
            {item.Note !='' && item.Note !='Note' && item.Note !='Note Documento' ?(
            <div style={{display:'flex', marginLeft:"10px",width:'100%'}}>
            <p className= "textBlackSedute" style={{color:"black",marginTop:"10px",marginBottom:"10px"}}><i >{item.Note}</i>  </p>           
            </div>):(
                <div></div>)
            }
            </div>              
            </div>
            <Box> 
          <FormControl fullWidth>          
                <InputLabel id="simple-select-label">Sposta in</InputLabel>
                <Select labelId="simple-select-label"  style={{fontWeight:"bold"}} defaultValue={conversionSector(item.Settore)} label="Sposta in" onChange={handleChange}>
                    <MenuItemMUI disabled={!isPossibleToMoveInto('cultura')} value={'cultura'}>Cultura</MenuItemMUI>
                    <MenuItemMUI disabled={!isPossibleToMoveInto('attrattivita')}value={'attrattivita'}>Attrattività</MenuItemMUI>                    
                    <MenuItemMUI disabled={!isPossibleToMoveInto('competenze')}value={'competenze'}>Competenze</MenuItemMUI>
                    <MenuItemMUI disabled={!isPossibleToMoveInto('custodia')}value={'custodia'}>Custodia</MenuItemMUI>
                    <MenuItemMUI disabled={!isPossibleToMoveInto('partecipazione')}value={'partecipazione'}>Partecipazione</MenuItemMUI>

                    <MenuItemMUI disabled={!isPossibleToMoveInto('persone')}value={'persone'}>Persone</MenuItemMUI>
                    <MenuItemMUI disabled={!isPossibleToMoveInto('abitare')}value={'abitare'}>Abitare</MenuItemMUI>                    
                    <MenuItemMUI disabled={!isPossibleToMoveInto('lavoro')}value={'lavoro'}>Lavoro</MenuItemMUI>
                    <MenuItemMUI disabled={!isPossibleToMoveInto('educazione')}value={'educazione'}>Educazione</MenuItemMUI>
                    <MenuItemMUI disabled={!isPossibleToMoveInto('comunita')}value={'comunita'}>Comunità</MenuItemMUI>
                    <MenuItemMUI disabled={!isPossibleToMoveInto('inclusione')}value={'inclusione'}>Inclusione</MenuItemMUI>

                    <MenuItemMUI disabled={!isPossibleToMoveInto('pianeta')}value={'pianeta'}>Pianeta</MenuItemMUI>  
                    <MenuItemMUI disabled={!isPossibleToMoveInto('ricerca')}value={'ricerca'}>Ricerca</MenuItemMUI>                    
                    <MenuItemMUI disabled={!isPossibleToMoveInto('innovazione')}value={'innovazione'}>Innovazione</MenuItemMUI>
                    <MenuItemMUI disabled={!isPossibleToMoveInto('benessere')}value={'benessere'}>Benessere</MenuItemMUI>
                    <MenuItemMUI disabled={!isPossibleToMoveInto('internazionalizzazione')}value={'internazionalizzazione'}>Internazionalizzazione</MenuItemMUI>
                    <MenuItemMUI disabled={!isPossibleToMoveInto('ambiente')}value={'ambiente'}>Ambiente</MenuItemMUI>
      
                    <MenuItemMUI disabled={!isPossibleToMoveInto('direzione_pianificazione')}value={'direzione_pianificazione'}>DIREZIONE PIANIFICAZIONE, STUDI E VALUTAZIONE</MenuItemMUI>  
                    <MenuItemMUI disabled={!isPossibleToMoveInto('direzione_innovazione_impatto')}value={'direzione_innovazione_impatto'}>DIREZIONE INNOVAZIONE DI IMPATTO</MenuItemMUI>                    
                    <MenuItemMUI disabled={!isPossibleToMoveInto('convenzioni_progetti_speciali')}value={'convenzioni_progetti_speciali'}>CONVENZIONI, PROGETTI SPECIALI, ULTERIORI PRATICHE E DOCUMENTI</MenuItemMUI>
                 </Select>
          </FormControl>
          </Box> 
           </DialogContent>
           <DialogActions>
             <Button onClick={saveDialogMoveItem} style={{fontSize:18}}
             color="warning" size='large'>
               Salva
             </Button>
             <Button onClick={closeDialogDocumento} style={{fontSize:18}}
             color="primary" size='large'>
               Chiudi
             </Button>
           </DialogActions>
         </Dialog>
          ):(<></>)}

{dialogMoveAmbito ? (
           <Dialog open={dialogMoveAmbito} 
              onClose={closeDialogAmbito}            
              maxWidth={"xl"} fullWidth={true} >
           <DialogTitle className= "textBlackSedute"sx={{fontWeight:'bold'}}>Sposta Ambito</DialogTitle>
           <DialogContent>           
              <div style={{border: "1px solid #d3d3d3", display:'flex', marginTop:'10px', marginBottom:'20px', fontSize:'large', width:'100%'}}>                   
                <div style={{width:"100%"}}>
                    <div style={{display:'flex',width:'100%'}}> <p className= "textBlackSedute" style={{paddingLeft:"10px",color:"black"}}>(Ambito) <b style={{marginLeft:"10px"}}> {item.Descrizione} </b></p>           
                    </div>              
                </div>              
              </div>
            <Box> 
          <FormControl fullWidth>          
                <InputLabel id="simple-select-label">Sposta in</InputLabel>
                <Select labelId="simple-select-label"  style={{fontWeight:"bold"}} defaultValue={conversionSector(item.Settore)} label="Sposta in" onChange={handleChange}>
                <MenuItemMUI disabled={!isPossibleToMoveInto('cultura')} value={'cultura'}>Cultura</MenuItemMUI>
                    <MenuItemMUI disabled={!isPossibleToMoveInto('attrattivita')}value={'attrattivita'}>Attrattività</MenuItemMUI>                    
                    <MenuItemMUI disabled={!isPossibleToMoveInto('competenze')}value={'competenze'}>Competenze</MenuItemMUI>
                    <MenuItemMUI disabled={!isPossibleToMoveInto('custodia')}value={'custodia'}>Custodia</MenuItemMUI>
                    <MenuItemMUI disabled={!isPossibleToMoveInto('partecipazione')}value={'partecipazione'}>Partecipazione</MenuItemMUI>

                    <MenuItemMUI disabled={!isPossibleToMoveInto('persone')}value={'persone'}>Persone</MenuItemMUI>
                    <MenuItemMUI disabled={!isPossibleToMoveInto('abitare')}value={'abitare'}>Abitare</MenuItemMUI>                    
                    <MenuItemMUI disabled={!isPossibleToMoveInto('lavoro')}value={'lavoro'}>Lavoro</MenuItemMUI>
                    <MenuItemMUI disabled={!isPossibleToMoveInto('educazione')}value={'educazione'}>Educazione</MenuItemMUI>
                    <MenuItemMUI disabled={!isPossibleToMoveInto('comunita')}value={'comunita'}>Comunità</MenuItemMUI>
                    <MenuItemMUI disabled={!isPossibleToMoveInto('inclusione')}value={'inclusione'}>Inclusione</MenuItemMUI>

                    <MenuItemMUI disabled={!isPossibleToMoveInto('pianeta')}value={'pianeta'}>Pianeta</MenuItemMUI>  
                    <MenuItemMUI disabled={!isPossibleToMoveInto('ricerca')}value={'ricerca'}>Ricerca</MenuItemMUI>                    
                    <MenuItemMUI disabled={!isPossibleToMoveInto('innovazione')}value={'innovazione'}>Innovazione</MenuItemMUI>
                    <MenuItemMUI disabled={!isPossibleToMoveInto('benessere')}value={'benessere'}>Benessere</MenuItemMUI>
                    <MenuItemMUI disabled={!isPossibleToMoveInto('internazionalizzazione')}value={'internazionalizzazione'}>Internazionalizzazione</MenuItemMUI>
                    <MenuItemMUI disabled={!isPossibleToMoveInto('ambiente')}value={'ambiente'}>Ambiente</MenuItemMUI>
      
                    <MenuItemMUI disabled={!isPossibleToMoveInto('direzione_pianificazione')}value={'direzione_pianificazione'}>DIREZIONE PIANIFICAZIONE, STUDI E VALUTAZIONE</MenuItemMUI>  
                    <MenuItemMUI disabled={!isPossibleToMoveInto('direzione_innovazione_impatto')}value={'direzione_innovazione_impatto'}>DIREZIONE INNOVAZIONE DI IMPATTO</MenuItemMUI>                    
                    <MenuItemMUI disabled={!isPossibleToMoveInto('convenzioni_progetti_speciali')}value={'convenzioni_progetti_speciali'}>CONVENZIONI, PROGETTI SPECIALI, ULTERIORI PRATICHE E DOCUMENTI</MenuItemMUI>
               </Select>
          </FormControl>
          </Box> 
          <div style={{display:'flex', marginLeft:"10px",width:'100%'}}>
                <p className= "textBlackSedute" style={{color:"black",marginTop:"10px",marginBottom:"10px"}}><i >* insieme all'ambito verranno spostate anche eventuali pratiche e documenti presenti</i>  </p>           
              </div>  
           </DialogContent>
           <DialogActions>
             <Button onClick={saveDialogMoveItem} style={{fontSize:18}}
             color="warning" size='large'>
               Salva
             </Button>
             <Button onClick={closeDialogAmbito} style={{fontSize:18}}
             color="primary" size='large'>
               Chiudi
             </Button>
             
           </DialogActions>
         </Dialog>
          ):(<></>)}

         {dialogPratica && data.Fase!=0 ? (
           <Dialog open={dialogPratica} 
              onClose={closeDialogPratica}            
              maxWidth={"xl"} fullWidth={true} >
           <DialogTitle className= "textBlackSedute" sx={{fontWeight:'bold'}}>Modifica Pratica</DialogTitle>
           <DialogContent>
           <div style={{width:"100%"}}>
              <div style={{display:'flex', marginTop:'10px',width:'100%'}}>          
         
                <TextField  label="Numero Pratica o Id ROL"
                    style={{marginLeft:"10px", width:"25%"}}
                    defaultValue={item.IdRichiesta!='' ? item.IdRichiesta: "idRichiesta"}
                    variant="outlined"
                    onFocus={(e) => e.target.select()} 
                    required
                    size='large'
                    onChange={(e) => {                      
                      //console.log("AAAWWW",pratica.IdRichiesta,e.target.value);    
                      //item.IdRichiesta=e.target.value; 
                      let ad=e.target.value;
                      ad=ad.replaceAll(";",",");
                      ad=ad.replaceAll("-",",");
                      ad=ad.replaceAll("+",",");                      
                      ad=ad.replaceAll(", ",",");                      
                      ad=ad.replaceAll(","," ");                      
                      item.IdRichiesta=ad; 
                      setItemChanged(true); }}
                    inputProps={{style: {fontSize: 18}}} // font size of input text
                    InputLabelProps={ {style: {fontSize: 16, color: !checkAttributo("Pratica","idRichiesta", item.IdRichiesta) ? 'red' : 'black'} }} // font size of input label
                    FormHelperTextProps={{style: {fontSize: 14}}} // font size of input label
                    //focused
                    //helperText="Identifico ROL o ID Sime"
                />

                <TextField label="Ente Richiedente"
                    style={{marginLeft:"20px", width:"75%"}}
                    defaultValue={item.Ente!='' ? item.Ente: "Ente"}
                    variant="outlined"
                    //color="warning"
                    required
                    size='large'
                    onFocus={(e) => e.target.select()} 
                    onChange={(e) => {                      
                      //console.log("AAAWWW",pratica.IdRichiesta,e.target.value);   
                      item.Ente=e.target.value; 
                      setItemChanged(true); }}
                    inputProps={{style: {fontSize: 18}}} // font size of input text
                    InputLabelProps={ {style: {fontSize: 16, color: !checkAttributo("Pratica","Ente", item.Ente) ? 'red' : 'black'}}} // font size of input label
                    FormHelperTextProps={{style: {fontSize: 16}}} // font size of input label
                    //focused
                    //helperText="Identifico ROL o ID Sime"
                />
                </div>
                <div style={{display:'flex', marginTop:'20px',width:'100%'}}>   
                <TextField label="Localizzazione"
                    style={{marginLeft:"10px", width:"35%", minWidth:"150px", width:"100%",textTransform:'capitalize'}}
                    defaultValue={item.Localizzazione!='' ? item.Localizzazione: "Localizzazione"}
                    variant="outlined"
                    //color="warning"
                    required
                    size='large'
                    onFocus={(e) => e.target.select()} 
                    onChange={(e) => {                      
                      //console.log("AAAWWW",pratica.IdRichiesta,e.target.value);    
                      item.Localizzazione=e.target.value;
                      setItemChanged(true); }}
                    inputProps={{style: {fontSize: 18}}} // font size of input text
                    InputLabelProps={ {style: {fontSize: 16,  color: !checkAttributo("Pratica","Localizzazione", item.Localizzazione) ? 'red' : 'black'}}} // font size of input label
                    FormHelperTextProps={{style: {fontSize: 16}}} // font size of input label
                    //focused
                    //helperText="Identifico ROL o ID Sime"
                />
                </div>
                
                <div style={{display:'flex', marginTop:'20px',width:'100%'}}>   
                <TextField label="Titolo Iniziativa"
                    style={{marginLeft:"10px", width:"100%", minWidth:"150px", width:"100%",textTransform:'capitalize'}}
                    defaultValue={item.Descrizione!='' ? item.Descrizione: "Descrizione"}
                    variant="outlined"
                    //color="warning"
                    required
                    size='large'
                    rows="4"
                    onFocus={(e) => e.target.select()} 
                    onChange={(e) => {                      
                      //console.log("AAAWWW",pratica.IdRichiesta,e.target.value);    
                      item.Descrizione=e.target.value;
                      setItemChanged(true); }}
                    inputProps={{style: {fontSize: 18}}} // font size of input text
                    InputLabelProps={ {style: {fontSize: 16,color: !checkAttributo("Pratica","Descrizione", item.Descrizione) ? 'red' : 'black'}}} // font size of input label
                    FormHelperTextProps={{style: {fontSize: 16}}} // font size of input label
                    multiline
                    //focused
                    //helperText="Identifico ROL o ID Sime"
                />                
              </div> 
              <div style={{display:'flex', marginTop:'20px',width:'100%'}}>                  
              
              <TextField label="Ipotesi Intervento €"
                    style={{marginLeft:"10px", width:"100%", minWidth:"150px", width:"100%",textTransform:'capitalize'}}
                    defaultValue={item.IpotesiIntervento!='' ? Euro.format(item.IpotesiIntervento): "€ 0.00 "}
                    variant="outlined"
                    //color="warning"
                    required
                    size='large'
                    onFocus={(e) => e.target.select()} 
                    onChange={(e) => {                      
                      //console.log("AAAWWW",pratica.IdRichiesta,e.target.value);    
                      item.IpotesiIntervento=e.target.value;
                      setItemChanged(true); }}
                    inputProps={{style: {fontSize: 18}}} // font size of input text
                    InputLabelProps={ {style: {fontSize: 16}}} // font size of input label
                    FormHelperTextProps={{style: {fontSize: 16}}} // font size of input label
                    //focused
                    //helperText="Identifico ROL o ID Sime"
                />           
               </div>
               <div style={{display:'flex', marginTop:'20px',width:'100%'}}>   

               <TextField label="Note"
                    style={{marginLeft:"10px", width:"100%", minWidth:"150px", width:"100%",textTransform:'capitalize'}}
                    defaultValue={item.Note!='' ? item.Note: "Note"}
                    variant="outlined"
                    //color="warning"
                    //required
                    size='large'
                    onFocus={(e) => e.target.select()} 
                    rows="4"
                    onChange={(e) => {                      
                      //console.log("AAAWWW",pratica.IdRichiesta,e.target.value);    
                      item.Note=e.target.value;
                      setItemChanged(true); }}
                    inputProps={{style: {fontSize: 18}}} // font size of input text
                    InputLabelProps={ {style: {fontSize: 16}}} // font size of input label
                    FormHelperTextProps={{style: {fontSize: 16}}} // font size of input label
                    multiline
                    //focused
                    //helperText="Identifico ROL o ID Sime"
                />                                             
              </div>
          </div>
           </DialogContent>
           <DialogActions>
             <Button onClick={saveDialogItem} style={{fontSize:18}}
             color="warning" size='large'>
               Salva
             </Button>
             <Button onClick={closeDialogPratica} style={{fontSize:18}}
             color="primary" size='large'>
               Chiudi
             </Button>
           </DialogActions>
         </Dialog>
          ):(<></>)}
         
         {dialogPratica && data.Fase==0 ? (
           <Dialog open={dialogPratica} 
              onClose={closeDialogPratica}            
              maxWidth={"xl"} fullWidth={true} >
           <DialogTitle className= "textBlackSedute" sx={{fontWeight:'bold'}}>Modifica Pratica</DialogTitle>
           <DialogContent>
           <div style={{width:"100%"}}>
              <div style={{display:'flex', marginTop:'10px',width:'100%'}}>          
         
                <TextField label="Numero Pratica o Id ROL"
                    style={{marginLeft:"10px", width:"25%"}}
                    defaultValue={item.IdRichiesta!='' ? item.IdRichiesta: "idRichiesta"}
                    variant="outlined"
                    //color="warning"
                    //required
                    size='large'
                    onFocus={(e) => e.target.select()} 
                    onChange={(e) => {                      
                      //console.log("AAAWWW",pratica.IdRichiesta,e.target.value);   
                      let ad=e.target.value;
                      ad=ad.replaceAll(";",",");
                      ad=ad.replaceAll("-",",");
                      ad=ad.replaceAll("+",",");                      
                      ad=ad.replaceAll(", ",",");                      
                      ad=ad.replaceAll(","," ");
                      //item.IdRichiesta=e.target.value; 
                      item.IdRichiesta=ad; 
                      setItemChanged(true); }}
                    inputProps={{style: {fontSize: 18}}} // font size of input text
                    InputLabelProps={ {style: {fontSize: 16}}} // font size of input label
                    FormHelperTextProps={{style: {fontSize: 16}}} // font size of input label
                    //focused
                    //helperText="Identifico ROL o ID Sime"
                />

                <TextField label="Ente Richiedente"
                    style={{marginLeft:"20px", width:"75%"}}
                    defaultValue={item.Ente!='' ? item.Ente: "Ente"}
                    variant="outlined"
                    onFocus={(e) => e.target.select()} 
                    //color="warning"
                    //required
                    size='large'
                    onChange={(e) => {                      
                      //console.log("AAAWWW",pratica.IdRichiesta,e.target.value);   
                      item.Ente=e.target.value; 
                      setItemChanged(true); }}
                    inputProps={{style: {fontSize: 18}}} // font size of input text
                    InputLabelProps={ {style: {fontSize: 16}}} // font size of input label
                    FormHelperTextProps={{style: {fontSize: 16}}} // font size of input label
                    //focused
                    //helperText="Identifico ROL o ID Sime"
                />
                </div>
                <div style={{display:'flex', marginTop:'20px',width:'100%'}}>   
                <TextField label="Localizzazione"
                    style={{marginLeft:"10px", width:"35%", minWidth:"150px", width:"100%",textTransform:'capitalize'}}
                    defaultValue={item.Localizzazione!='' ? item.Localizzazione: "Localizzazione"}
                    variant="outlined"
                    onFocus={(e) => e.target.select()} 
                    //color="warning"
                    //required
                    size='large'
                    onChange={(e) => {                      
                      //console.log("AAAWWW",pratica.IdRichiesta,e.target.value);    
                      item.Localizzazione=e.target.value;
                      setItemChanged(true); }}
                    inputProps={{style: {fontSize: 18}}} // font size of input text
                    InputLabelProps={ {style: {fontSize: 16}}} // font size of input label
                    FormHelperTextProps={{style: {fontSize: 16}}} // font size of input label
                    //focused
                    //helperText="Identifico ROL o ID Sime"
                />
                </div>
                
                <div style={{display:'flex', marginTop:'20px',width:'100%'}}>   
                <TextField label="Titolo Iniziativa"
                    style={{marginLeft:"10px", width:"100%", minWidth:"150px", width:"100%",textTransform:'capitalize'}}
                    defaultValue={item.Descrizione!='' ? item.Descrizione: "Descrizione"}
                    variant="outlined"
                    onFocus={(e) => e.target.select()} 
                    //color="warning"
                    //required
                    size='large'
                    rows="4"
                    onChange={(e) => {                      
                      //console.log("AAAWWW",pratica.IdRichiesta,e.target.value);    
                      item.Descrizione=e.target.value;
                      setItemChanged(true); }}
                    inputProps={{style: {fontSize: 18}}} // font size of input text
                    InputLabelProps={ {style: {fontSize: 16}}} // font size of input label
                    FormHelperTextProps={{style: {fontSize: 16}}} // font size of input label
                    multiline
                    //focused
                    //helperText="Identifico ROL o ID Sime"
                />                
              </div> 
              <div style={{display:'flex', marginTop:'20px',width:'100%'}}>                  
              
              <TextField label="Ipotesi Intervento €"
                    style={{marginLeft:"10px", width:"100%", minWidth:"150px", width:"100%",textTransform:'capitalize'}}
                    defaultValue={item.IpotesiIntervento!='' ? Euro.format(item.IpotesiIntervento): "€ 0.00 "}
                    variant="outlined"
                    onFocus={(e) => e.target.select()} 
                    //color="warning"
                    required
                    size='large'
                    onChange={(e) => {                      
                      //console.log("AAAWWW",pratica.IdRichiesta,e.target.value);    
                      item.IpotesiIntervento=e.target.value;
                      setItemChanged(true); }}
                    inputProps={{style: {fontSize: 18}}} // font size of input text
                    InputLabelProps={ {style: {fontSize: 16}}} // font size of input label
                    FormHelperTextProps={{style: {fontSize: 16}}} // font size of input label
                    //focused
                    //helperText="Identifico ROL o ID Sime"
                />           
               </div>
               <div style={{display:'flex', marginTop:'20px',width:'100%'}}>   

               <TextField label="Note"
                    style={{marginLeft:"10px", width:"100%", minWidth:"150px", width:"100%",textTransform:'capitalize'}}
                    defaultValue={item.Note!='' ? item.Note: "Note"}
                    variant="outlined"
                    onFocus={(e) => e.target.select()} 
                    //color="warning"
                    //required
                    size='large'
                    rows="4"
                    onChange={(e) => {                      
                      //console.log("AAAWWW",pratica.IdRichiesta,e.target.value);    
                      item.Note=e.target.value;
                      setItemChanged(true); }}
                    inputProps={{style: {fontSize: 18}}} // font size of input text
                    InputLabelProps={ {style: {fontSize: 16}}} // font size of input label
                    FormHelperTextProps={{style: {fontSize: 16}}} // font size of input label
                    multiline
                    //focused
                    //helperText="Identifico ROL o ID Sime"
                />                                             
              </div>
          </div>
           </DialogContent>
           <DialogActions>
             <Button onClick={saveDialogItem} style={{fontSize:18}}
             color="warning" size='large'>
               Salva
             </Button>
             <Button onClick={closeDialogPratica} style={{fontSize:18}}
             color="primary" size='large'>
               Chiudi
             </Button>
           </DialogActions>
         </Dialog>
          ):(<></>)}


         {dialogAmbito ? (
           <Dialog open={dialogAmbito} 
              onClose={closeDialogAmbito}            
              maxWidth={"xl"} fullWidth={true} >
            <DialogTitle className= "textBlackSedute" sx={{fontWeight:'bold'}}>Modifica Ambito</DialogTitle>
            <DialogContent>
            <div style={{width:"100%"}}>
              <div style={{display:'flex', marginTop:'10px',width:'100%'}}>          
         
                <TextField label="Nome Ambito"
                    style={{marginLeft:"10px", width:"100%"}}
                    defaultValue={item.Descrizione!='' ? item.Descrizione: "Nome Ambito"}
                    variant="outlined"
                    //color="warning"
                    required
                    onFocus={(e) => e.target.select()} 
                    size='large'
                    onChange={(e) => {                      
                      //console.log("AAAWWW",pratica.IdRichiesta,e.target.value);    
                      item.Descrizione=e.target.value; 
                      setItemChanged(true); }}
                    inputProps={{style: {fontSize: 18}}} // font size of input text
                    InputLabelProps={ {style: {fontSize: 16,color: !checkAttributo("Ambito","Descrizione", item.Descrizione) ? 'red' : 'black'}}} // font size of input label
                    FormHelperTextProps={{style: {fontSize: 16}}} // font size of input label
                    //focused
                    //helperText="Identifico ROL o ID Sime"
                />

               
                </div>                                                                   
          </div>
           </DialogContent>
           <DialogActions>
             <Button onClick={saveDialogItemNoPratica} style={{fontSize:18}}
             color="warning" size='large'>
               Salva
             </Button>
             <Button onClick={closeDialogAmbito} style={{fontSize:18}}
             color="primary" size='large'>
               Chiudi
             </Button>
           </DialogActions>
         </Dialog>
          ):(<></>)}

          {dialogDocumento ? (
           <Dialog open={dialogDocumento} 
              onClose={closeDialogDocumento}            
              maxWidth={"xl"} fullWidth={true} >
            <DialogTitle className= "textBlackSedute" sx={{fontWeight:'bold'}}>Modifica Documento</DialogTitle>
            <DialogContent>
            <div style={{width:"100%"}}>
              <div style={{display:'flex', marginTop:'10px',width:'100%'}}>          
         
                <TextField label="Nome Documento"
                    style={{marginLeft:"10px", width:"100%"}}
                    defaultValue={item.Descrizione!='' ? item.Descrizione: "Nome Documento"}
                    variant="outlined"
                    //color="warning"
                    required
                    onFocus={(e) => e.target.select()} 
                    size='large'
                    onChange={(e) => {                      
                      //console.log("AAAWWW",pratica.IdRichiesta,e.target.value);    
                      item.Descrizione=e.target.value; 
                      setItemChanged(true); }}
                    inputProps={{style: {fontSize: 18}}} // font size of input text
                    InputLabelProps={ {style: {fontSize: 16, color: !checkAttributo("Documento","Descrizione", item.Descrizione) ? 'red' : 'black'}}} // font size of input label
                    FormHelperTextProps={{style: {fontSize: 16}}} // font size of input label                    
                    //helperText="Identifico ROL o ID Sime"
                />
              </div>
              <div style={{display:'flex', marginTop:'10px',width:'100%'}}>         
              <TextField label="Note Documento"
                    style={{marginLeft:"10px", width:"100%"}}
                    defaultValue={item.Note!='' ? item.Note: "Note Documento"}
                    variant="outlined"
                    //color="warning"                    
                    size='large'
                    onFocus={(e) => e.target.select()} 
                    rows="4"
                    multiline
                    onChange={(e) => {                      
                      //console.log("AAAWWW",pratica.IdRichiesta,e.target.value);    
                      item.Note=e.target.value; 
                      setItemChanged(true); }}
                    inputProps={{style: {fontSize: 18}}} // font size of input text
                    InputLabelProps={ {style: {fontSize: 16}}} // font size of input label
                    FormHelperTextProps={{style: {fontSize: 16}}} // font size of input label                    
                    //helperText="Identifico ROL o ID Sime"
                />                       
                </div>                                                                   
          </div>
           </DialogContent>
           <DialogActions>
             <Button onClick={saveDialogItemNoPratica} style={{fontSize:18}}
             color="warning" size='large'>
               Salva
             </Button>
             <Button onClick={closeDialogDocumento} style={{fontSize:18}}
             color="primary" size='large'>
               Chiudi
             </Button>
           </DialogActions>
         </Dialog>
          ):(<></>)}

      {loading ? (
        <div style={{ width:"25%",   display: "flex",
        marginLeft: "auto", marginRight: "auto" }}>
         <ThreeDots color="#30720f" height='350' width='150'/>
         </div>
        ):(
        <div style={{ display: 'flex', textAlign:'left',height: '100%', backgroundColor:"white", width:'auto' , zIndex:100}}>
               {secondLevelButtons ? (
                <div>                  
                    <Tooltip title="Aggiungi Pratica" placement="left">
                    <div style={{ position: 'fixed', right: 60, bottom: 240, zIndex:100 }}>
                    <Fab aria-label="add2"                 
                        //variant="contained" 
                        size='medium'
                        disabled={isBloccataMissione()}
                        onClick={(event) => {onFabClick("pratica",event) }}                        
                        style={{
                            margin: 0,
                            background: "#A2EE7D",
                            top: 'auto',                     
                            right: 20,
                            bottom: 220,                      
                            left: 'auto',
                            position: 'fixed'}} >                    
                            <AddBoxIcon />                                                                                                        
                    </Fab>    
                    </div>  
                    </Tooltip>                  
                  
                    <Tooltip title="Aggiungi Documento" placement="left">
                    <div style={{ position: 'fixed', right: 60, bottom: 130,zIndex:100 }}>
                    <Fab aria-label="add2"                 
                        //variant="contained" 
                        size='medium'
                        onClick={(event) => {onFabClick("documento",event) }}
                        disabled={isBloccataMissione()}
                        style={{
                            margin: 0,
                            background: "#A2EE7D",
                            top: 'auto',                     
                            right: 20,
                            bottom: 110,                      
                            left: 'auto',
                            position: 'fixed'}} >                    
                            <NoteAddIcon />                        
                    </Fab>       
                    </div>
                </Tooltip>
                <Tooltip title="Aggiungi Ambito" placement="left">
                <div style={{ position: 'fixed', right: 60, bottom: 185, zIndex:100 }}>
                    <Fab aria-label="add3"                                   
                        size='medium'
                        onClick={(event) => {onFabClick("ambito",event) }}      
                        disabled={isBloccataMissione()}            
                        style={{
                            margin: 0,
                            background: "#A2EE7D",
                            top: 'auto',                     
                            right: 20,
                            bottom: 165,
                            //color: '0xFF0000',
                            left: 'auto',
                            position: 'fixed',}}>                    
                            <LibraryAddIcon />
                    </Fab>
                  </div>
                </Tooltip>   
                <Tooltip title="Aggiungi/Rimuovi Minigrant"  disableHoverListener={isBloccataMissione()} placement="left">
                <div style={{ position: 'fixed', right: 60, bottom: 295,zIndex:100 }}>
                    <Fab aria-label="add3"                                   
                        size='medium'
                        disabled={isBloccataMissione()}
                        onClick={(event) => {onFabClick("minigrant",event) }}                  
                        style={{
                            margin: 0,
                            background: "#A2EE7D",
                            top: 'auto',                     
                            right: 20,
                            bottom: 275,
                            //color: '0xFF0000',
                            left: 'auto',
                            position: 'fixed',}}>                    
                            <AccountBalanceIcon />
                    </Fab>
                    </div>
                </Tooltip>      
                
                  
            </div> ):(  <div> </div>)}
      
            {forthLevelButtons && localStorage.getItem("ruolo")=="5"? (
               <div >                                                 
                              <Tooltip title="Blocca/Sblocca Cultura" placement="left">
                              <div style={{ position: 'fixed', right: 125, bottom: 130,zIndex:100 }}>
                                  <Fab aria-label="add3"                                   
                                      size='medium'
                                      onClick={(event) => {onFabClick("cultura",event) }}                  
                                      style={{
                                          margin: 0,
                                          background: "#E42368",
                                          top: 'auto',                     
                                          right: 85,
                                          bottom: 110,
                                          //color: '0xFF0000',
                                          left: 'auto',
                                          position: 'fixed',}}>       
                                          {isBloccatoObiettivo("cultura") ?(             
                                          <VpnKeyOffIcon />):(                              
                                            <VpnKeyIcon />
                                          )}
                                  </Fab>
                                  </div>
                              </Tooltip>  
                              <Tooltip title="Blocca/Sblocca Persone" placement="left">
                              <div style={{ position: 'fixed', right: 125, bottom: 185,zIndex:100 }}>
                                  <Fab aria-label="add3"                                   
                                      size='medium'
                                      onClick={(event) => {onFabClick("persone",event) }}                  
                                      style={{
                                          margin: 0,
                                          background: "#F7B900",
                                          top: 'auto',                     
                                          right: 85,
                                          bottom: 165,
                                          //color: '0xFF0000',
                                          left: 'auto',
                                          position: 'fixed',}}>    
                                          {isBloccatoObiettivo("persone") ?(             
                                          <VpnKeyOffIcon />):(                              
                                            <VpnKeyIcon />
                                          )}                
                                          
                                  </Fab>
                                  </div>
                              </Tooltip>   
                              <Tooltip title="Blocca/Sblocca Pianeta" placement="left">
                              <div style={{ position: 'fixed', right: 125, bottom: 240,zIndex:100 }}>
                                  <Fab aria-label="add3"                                   
                                      size='medium'
                                      onClick={(event) => {onFabClick("pianeta",event) }}                  
                                      style={{
                                          margin: 0,
                                          background: "#028FD2",
                                          top: 'auto',                     
                                          right: 85,
                                          bottom: 220,
                                          //color: '0xFF0000',
                                          left: 'auto',
                                          position: 'fixed',}}>                    
                                          {isBloccatoObiettivo("pianeta") ?(             
                                          <VpnKeyOffIcon />):(                              
                                            <VpnKeyIcon />
                                          )}
                                  </Fab>
                                  </div>
                              </Tooltip>  
                              <Tooltip title="Blocca/Sblocca Direzione Pianificazione Studi" placement="left">
                              <div style={{ position: 'fixed', right: 125, bottom: 295,zIndex:100 }}>
                                  <Fab aria-label="add3"                                   
                                      size='medium'
                                      onClick={(event) => {onFabClick("pianificazione_studi",event) }}                  
                                      style={{
                                          margin: 0,
                                          background: "#FFD480",
                                          top: 'auto',                     
                                          right: 85,
                                          bottom: 275,
                                          //color: '0xFF0000',
                                          left: 'auto',
                                          position: 'fixed',}}>                    
                                          {isBloccatoObiettivo("direzione_pianificazione") ?(             
                                          <VpnKeyOffIcon />):(                              
                                            <VpnKeyIcon />
                                          )}                            
                                  </Fab>   
                                  </div>                 
                              </Tooltip>
                              <Tooltip title="Blocca/Sblocca Direzione Innovazione Impatto" placement="left">
                              <div style={{ position: 'fixed', right: 125, bottom: 350,zIndex:100 }}>
                                  <Fab aria-label="add3"                                   
                                      size='medium'
                                      onClick={(event) => {onFabClick("innovazione_impatto",event) }}                  
                                      style={{
                                          margin: 0,
                                          background: "#FFD480",
                                          top: 'auto',                     
                                          right: 85,
                                          bottom: 330,
                                          //color: '0xFF0000',
                                          left: 'auto',
                                          position: 'fixed',}}>                    
                                          {isBloccatoObiettivo("direzione_innovazione_impatto") ?(             
                                          <VpnKeyOffIcon />):(                              
                                            <VpnKeyIcon />
                                          )}                            
                                  </Fab>
                                  </div>
                              </Tooltip > 
                              <Tooltip title="Blocca/Sblocca Convenzione Progetti Speciali" placement="left">
                              <div style={{ position: 'fixed', right: 125, bottom: 405,zIndex:100 }}>
                                  <Fab aria-label="add3"                                   
                                      size='medium'
                                      onClick={(event) => {onFabClick("convenzioni_progetti_speciali",event) }}                  
                                      style={{
                                          margin: 0,
                                          background: "#DDDDDD",
                                          top: 'auto',                     
                                          right: 85,
                                          bottom: 385,
                                          //color: '0xFF0000',
                                          left: 'auto',
                                          position: 'fixed',}}>                                                
                                          {isBloccatoObiettivo("convenzioni_progetti_speciali") ?(             
                                          <VpnKeyOffIcon />):(                              
                                            <VpnKeyIcon />
                                          )}   
                                  </Fab>
                                  </div>
                              </Tooltip>  
                              <Tooltip title="Blocca Tutto" placement="left">
                              <div style={{ position: 'fixed', right: 125, bottom: 460,zIndex:100 }}>
                                  <Fab aria-label="add3"                                   
                                      size='medium'
                                      onClick={(event) => {onFabClick("tutto",event) }}                  
                                      style={{
                                          margin: 0,
                                          background: "#30720f",
                                          top: 'auto',                     
                                          right: 85,
                                          bottom: 440,
                                          //color: '0xFF0000',
                                          left: 'auto',
                                          position: 'fixed',}}>                    
                                          <VpnLockIcon />
                                  </Fab>
                                  </div>
                              </Tooltip>  
                                
                          </div> ):(  <div> </div>)}

            {forthLevelButtons && localStorage.getItem("ruolo")=="4"? (
                <div>                     
                {localStorage.getItem("indice").split(",").map((item, index) => {
                  console.log("MAPPING",item,index);
                  let element;
                  if(item ==1){                    
                    element= (
                      <Tooltip title="Blocca Cultura" placement="left" key={index}>
                      <div style={{ position: 'fixed', right: 130, bottom: 130+(index*55),zIndex:100 }}>
                      <Fab aria-label="add3"                                   
                          size='medium'
                          disabled={isBloccatoObiettivo("cultura")}
                          onClick={(event) => {onFabClick("cultura",event) }}                  
                          style={{
                              margin: 0,
                              background: "#E42368",
                              top: 'auto',                     
                              right: 85,
                              bottom: 110+(index*55),
                              //color: '0xFF0000',
                              left: 'auto',
                              position: 'fixed',}}>                                                                  
                              <VpnKeyIcon />
                      </Fab>
                      </div>
                  </Tooltip> 
                    );
                  }
                  else if (item==2){
                    
                      element= (
                        <Tooltip title="Blocca Persone" placement="left" key={index}>
                          <div style={{ position: 'fixed', right: 130, bottom: 130+(index*55),zIndex:100 }}>
                        <Fab aria-label="add3"                                   
                            size='medium'
                            disabled={isBloccatoObiettivo("persone")}
                            onClick={(event) => {onFabClick("persone",event) }}                  
                            style={{
                                margin: 0,
                                background: "#F7B900",
                                top: 'auto',                     
                                right: 85,
                                bottom: 110+(index*55),
                                //color: '0xFF0000',
                                left: 'auto',
                                position: 'fixed',}}>                                    
                                <VpnKeyIcon />                                
                        </Fab>
                        </div>
                    </Tooltip> 
                      );
                    }
                    else if (item==3){
                      element= (
                      <Tooltip title="Blocca Pianeta" placement="left" key={index}>
                        <div style={{ position: 'fixed', right: 130, bottom: 130+(index*55),zIndex:100 }}>
                      <Fab aria-label="add3"                                   
                          size='medium'
                          disabled={isBloccatoObiettivo("pianeta")}
                          onClick={(event) => {onFabClick("pianeta",event) }}                  
                          style={{
                              margin: 0,
                              background: "#028FD2",
                              top: 'auto',                     
                              right: 85,
                              bottom: 110+(index*55),
                              //color: '0xFF0000',
                              left: 'auto',
                              position: 'fixed',}}>                                  
                              <VpnKeyIcon />
                      </Fab>
                      </div>
                    </Tooltip>  
                     );
                    }
                    else if (item==4){
                      element= (
                      <Tooltip title="Blocca Direzione Pianificazione Studi" placement="left" key={index}>
                        <div style={{ position: 'fixed', right: 130, bottom: 130+(index*55),zIndex:100 }}>
                      <Fab aria-label="add3"                                   
                          size='medium'
                          disabled={isBloccatoObiettivo("direzione_pianificazione")}
                          onClick={(event) => {onFabClick("pianificazione_studi",event) }}                  
                          style={{
                              margin: 0,
                              background: "#FFD480",
                              top: 'auto',                     
                              right: 85,
                              bottom: 110+(index*55),
                              //color: '0xFF0000',
                              left: 'auto',
                              position: 'fixed',}}>                                                  
                              <VpnKeyIcon />                        
                      </Fab>               
                      </div>     
                    </Tooltip>
                   );
                    }
                    else if (item==5){
                      element= (
                        <Tooltip title="Blocca Direzione Innovazione Impatto" placement="left" key={index}>
                          <div style={{ position: 'fixed', right: 130, bottom: 130+(index*55),zIndex:100 }}>
                        <Fab aria-label="add3"                                   
                            size='medium'
                            disabled={isBloccatoObiettivo("direzione_innovazione_impatto")}
                            onClick={(event) => {onFabClick("innovazione_impatto",event) }}                  
                            style={{
                                margin: 0,
                                background: "#FFD480",
                                top: 'auto',                     
                                right: 85,
                                bottom: 110+(index*55),
                                //color: '0xFF0000',
                                left: 'auto',
                                position: 'fixed',}}>                                                    
                                <VpnKeyIcon />                                
                        </Fab>
                        </div>
                    </Tooltip >
                    );
                    }                    
                    return (element);
                      })}                                
            </div> ):(  <> </>)}

     
            <Tooltip title="Modifica" placement="bottom">
            <div style={{ position: 'fixed', right: 60, bottom:-5,zIndex:100 }}>
            <Fab aria-label="add"                 
                //variant="contained" 
                size="large" 
                onClick={(event) => {setSecondLevelButtons(!secondLevelButtons);setForthLevelButtons(false) }}
                style={{
                    //margin: 0,
                    background: "#30720f",
                    //background: "#CCCCCC",
                    //top: 'auto',
                    //left: 10,
                    right: 20,
                    bottom: 45,
                    color: '0xFFFFFF',
                    //left: 'auto',
                    position: 'fixed',}}>                  
                    <MoreHorizIcon />                    
            </Fab>  
            </div>
          </Tooltip>
          {localStorage.getItem("ruolo")=="5"? (
          <Tooltip title="Porta alla Fase successiva" placement="bottom">
            <div style={{ position: 'fixed', right: 190, bottom: -5, zIndex:100  }}>
            <Fab aria-label="add"                 
                //variant="contained" 
                size="large" 
                disabled={!isPossibleNextFase(data.Fase)}
                onClick={(event) => {onFabClick("cambiaFase",event) }}
                style={{
                    //margin: 0,
                    background: "#30720f",
                    //background: "#CCCCCC",
                    //top: 'auto',
                    //left: 10,
                    right: 150,
                    bottom: 45,
                    color: '0xFFFFFF',
                    zIndex: 10000000,
                    //left: 'auto',
                    position: 'fixed',}}>                  
                    {data.Fase=="0"?(
                                  <Filter2Icon />
                                ):
                                data.Fase=="1"?(
                                  <Filter3Icon />
                                ):
                                <Filter3Icon />
                                }            
            </Fab>  
            </div>
          </Tooltip>):(<></>)}

          {localStorage.getItem("ruolo")=="5"? (
          <Tooltip title="Genera Excel" placement="bottom">
            <div style={{ position: 'fixed', right: 255, bottom:-5,zIndex:100 }}>
            <Fab aria-label="add"                 
                //variant="contained" 
                size="large" 
                onClick={(event) => {onFabClick("excel",event) }}
                style={{
                    //margin: 0,
                    background: "#30720f",
                    //background: "#CCCCCC",
                    //top: 'auto',
                    //left: 10,
                    right: 215,
                    bottom: 45,
                    color: '0xFFFFFF',
                    zIndex: 10000000,
                    //left: 'auto',
                    position: 'fixed',}}>                  
                    <PrintIcon />                    
            </Fab>  
            </div>
          </Tooltip> ):(<></>)}

          {localStorage.getItem("ruolo")=="4"? (
          <Tooltip title="Genera Excel" placement="bottom">
            <div style={{ position: 'fixed', right: 190, bottom:-5,zIndex:100 }}>
            <Fab aria-label="add"                 
                //variant="contained" 
                size="large" 
                onClick={(event) => {onFabClick("excel",event) }}
                style={{
                    //margin: 0,
                    background: "#30720f",
                    //background: "#CCCCCC",
                    //top: 'auto',
                    //left: 10,
                    right: 150,
                    bottom: 45,
                    zIndex: 10000000,
                    color: '0xFFFFFF',
                    //left: 'auto',
                    position: 'fixed',}}>                  
                    <PrintIcon />                    
            </Fab>  
            </div>
          </Tooltip> ):(<></>)}
          
          <Tooltip title="Blocca" placement="bottom">
          <div style={{ position: 'fixed', right: 125, bottom:-5,zIndex:100 }}>
            <Fab aria-label="add"                 
                //variant="contained" 
                size="large" 
                onClick={(event) => {setForthLevelButtons(!forthLevelButtons);setSecondLevelButtons(false) }}
                style={{
                    //margin: 0,
                    background: "#30720f",
                    //background: "#CCCCCC",
                    //top: 'auto',
                    //left: 10,
                    zIndex: 10000000,
                    right: 85,
                    bottom: 45,
                    color: '0xFFFFFF',
                    //left: 'auto',
                    position: 'fixed',}}>                  
                    <LockPersonIcon />                    
            </Fab>  
            </div>
          </Tooltip>
        {/*<header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />               
    </header> */}  
       <Sidebar width='400px' style={{ height: '100%' }}>
      <Menu
        menuItemStyles={{
          button: ({ level, active, disabled }) => {
            // only apply styles on first level elements of the tree
            
            if (level === 0){
              return {
                //color: disabled ? '#f5d9ff' : '#d359ff',
                color: disabled ? '#f5d9ff' : '#000000',
                backgroundColor: active ? '#eecef9' : undefined,
                fontWeight:'bold',
                //fontSize:"18px"
              };
            }
          },
        }}
      >
        <SubMenu defaultOpen={canBeSeen(1)} label="CULTURA" icon={<img style={{width:"30px"}} src={cultura}  /> }>
          <div style={{ width:"100%",   display: "flex"}} >  
          {menuItemSelected==74 ? (          
          <MenuItem  rootStyles={{backgroundColor:'#F3F3F3', width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"cultura","Cultura",74)}> Cultura              
          </MenuItem>  
          ):(          
          <MenuItem   rootStyles={{backgroundColor:'#FFFFFF',width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"cultura","Cultura",74)}> Cultura </MenuItem>          
          )}
          {menuLocked[0]==3 ?(
          <WarningIcon fontSize='small' style={{ background: "#ffffff", color: "BF2B0C" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[0]==2 ?(
            <LockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[0]==1 ?(
            <EngineeringIcon fontSize='small' sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[0]==4 ?(
            <LockClockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          )          
          :(<></>)}
          </div>  
          <div style={{ width:"100%",   display: "flex"}} >
          {menuItemSelected==70 ? (
          <MenuItem  rootStyles={{backgroundColor:'#F3F3F3',width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"attrattivita","Attrattività",70)}> Attrattività</MenuItem>  
          ):(
          <MenuItem   rootStyles={{backgroundColor:'#FFFFFF',width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"attrattivita","Attrattività",70)}> Attrattività</MenuItem>  
          )}
           {menuLocked[1]==3 ?(
          <WarningIcon fontSize='small' style={{ background: "#ffffff", color: "BF2B0C" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[1]==2 ?(
            <LockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[1]==1 ?(
            <EngineeringIcon fontSize='small' sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[1]==4 ?(
            <LockClockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          )                  
          :(<></>)}
          </div>  
          
          <div style={{ width:"100%",   display: "flex"}} >
          {menuItemSelected==71 ? (
          <MenuItem  rootStyles={{backgroundColor:'#F3F3F3', width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"competenze","Competenze",71)}> Competenze</MenuItem>  
          ):(
          <MenuItem   rootStyles={{backgroundColor:'#FFFFFF', width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"competenze","Competenze",71)}> Competenze</MenuItem>  
          )}
          {menuLocked[2]==3 ?(
          <WarningIcon fontSize='small' style={{ background: "#ffffff", color: "BF2B0C" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[2]==2 ?(
            <LockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[2]==1 ?(
            <EngineeringIcon fontSize='small' sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[2]==4 ?(
            <LockClockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          )                  
          :(<></>)}
          </div>  

          <div style={{ width:"100%",   display: "flex"}} >
          {menuItemSelected==72 ? (
          <MenuItem  rootStyles={{backgroundColor:'#F3F3F3', width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"custodia","Custodia",72)}> Custodia</MenuItem>  
          ):(
          <MenuItem   rootStyles={{backgroundColor:'#FFFFFF', width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"custodia","Custodia",72)}> Custodia</MenuItem>  
          )}
         {menuLocked[3]==3 ?(
          <WarningIcon fontSize='small' style={{ background: "#ffffff", color: "BF2B0C" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[3]==2 ?(
            <LockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[3]==1 ?(
            <EngineeringIcon fontSize='small' sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[3]==4 ?(
            <LockClockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          )                  
          :(<></>)}
          </div>  

          <div style={{ width:"100%",   display: "flex"}} >
          {menuItemSelected==73 ? (
          <MenuItem  rootStyles={{backgroundColor:'#F3F3F3', width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"partecipazione","Partecipazione",73)}> Partecipazione</MenuItem>  
          ):(
          <MenuItem   rootStyles={{backgroundColor:'#FFFFFF', width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"partecipazione","Partecipazione",73)}> Partecipazione</MenuItem>  
          )}          
          {menuLocked[4]==3 ?(
          <WarningIcon fontSize='small' style={{ background: "#ffffff", color: "BF2B0C" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[4]==2 ?(
            <LockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[4]==1 ?(
            <EngineeringIcon fontSize='small' sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[4]==4 ?(
            <LockClockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          )                  
          :(<></>)}
          </div>  

        </SubMenu>
        <SubMenu defaultOpen={canBeSeen(2)} label="PERSONE" icon={<img style={{width:"30px"}} src={persone}  /> }>          
          <div style={{ width:"100%",   display: "flex"}} >
          {menuItemSelected==67 ? (
          <MenuItem  rootStyles={{backgroundColor:'#F3F3F3', width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"persone","Persone",67)}> Persone</MenuItem>  
          ):(
          <MenuItem   rootStyles={{backgroundColor:'#FFFFFF', width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"persone","Persone",67)}> Persone</MenuItem>  
          )}  
           {menuLocked[5]==3 ?(
          <WarningIcon fontSize='small' style={{ background: "#ffffff", color: "BF2B0C" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[5]==2 ?(
            <LockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[5]==1 ?(
            <EngineeringIcon fontSize='small' sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[5]==4 ?(
            <LockClockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          )                  
          :(<></>)}
          </div>  
          
          <div style={{ width:"100%",   display: "flex"}} >
           {menuItemSelected==62 ? (
          <MenuItem  rootStyles={{backgroundColor:'#F3F3F3', width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"abitare","Abitare",62)}> Abitare</MenuItem>  
          ):(
          <MenuItem   rootStyles={{backgroundColor:'#FFFFFF', width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"abitare","Abitare",62)}> Abitare</MenuItem>  
          )}  
          {menuLocked[6]==3 ?(
          <WarningIcon fontSize='small' style={{ background: "#ffffff", color: "BF2B0C" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[6]==2 ?(
            <LockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[6]==1 ?(
            <EngineeringIcon fontSize='small' sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[6]==4 ?(
            <LockClockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          )                  
          :(<></>)}
          </div>  

          <div style={{ width:"100%",   display: "flex"}} >
          {menuItemSelected==63 ? (
          <MenuItem  rootStyles={{backgroundColor:'#F3F3F3', width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"lavoro","Lavoro",63)}> Lavoro</MenuItem>  
          ):(
          <MenuItem   rootStyles={{backgroundColor:'#FFFFFF', width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"lavoro","Lavoro",63)}> Lavoro</MenuItem>  
          )}  
         {menuLocked[7]==3 ?(
          <WarningIcon fontSize='small' style={{ background: "#ffffff", color: "BF2B0C" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[7]==2 ?(
            <LockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[7]==1 ?(
            <EngineeringIcon fontSize='small' sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[7]==4 ?(
            <LockClockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          )                  
          :(<></>)}
          </div>  

          <div style={{ width:"100%",   display: "flex"}} >
           {menuItemSelected==64 ? (
          <MenuItem  rootStyles={{backgroundColor:'#F3F3F3', width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"educazione","Educazione",64)}> Educazione</MenuItem>  
          ):(
          <MenuItem   rootStyles={{backgroundColor:'#FFFFFF', width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"educazione","Educazione",64)}> Educazione</MenuItem>  
          )}                   
          {menuLocked[8]==3 ?(
          <WarningIcon fontSize='small' style={{ background: "#ffffff", color: "BF2B0C" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[8]==2 ?(
            <LockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[8]==1 ?(
            <EngineeringIcon fontSize='small' sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[8]==4 ?(
            <LockClockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          )                  
          :(<></>)}
          </div>  

          <div style={{ width:"100%",   display: "flex"}} >
          {menuItemSelected==65 ? (
          <MenuItem  rootStyles={{backgroundColor:'#F3F3F3', width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"comunita","Comunità",65)}> Comunità</MenuItem>  
          ):(
          <MenuItem   rootStyles={{backgroundColor:'#FFFFFF', width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"comunita","Comunità",65)}> Comunità</MenuItem>  
          )}  

          {menuLocked[9]==3 ?(
          <WarningIcon fontSize='small' style={{ background: "#ffffff", color: "BF2B0C" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[9]==2 ?(
            <LockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[9]==1 ?(
            <EngineeringIcon fontSize='small' sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[9]==4 ?(
            <LockClockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          )                  
          :(<></>)}
          </div>  

          <div style={{ width:"100%",   display: "flex"}} >
          {menuItemSelected==66 ? (
          <MenuItem  rootStyles={{backgroundColor:'#F3F3F3', width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"inclusione","Inclusione",66)}> Inclusione</MenuItem>  
          ):(
          <MenuItem   rootStyles={{backgroundColor:'#FFFFFF', width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"inclusione","Inclusione",66)}> Inclusione</MenuItem>  
          )}
          {menuLocked[10]==3 ?(
          <WarningIcon fontSize='small' style={{ background: "#ffffff", color: "BF2B0C" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[10]==2 ?(
            <LockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[10]==1 ?(
            <EngineeringIcon fontSize='small' sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[10]==4 ?(
            <LockClockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          )        
          :(<></>)}
          </div>              
        </SubMenu>
        <SubMenu defaultOpen={canBeSeen(3)} label="PIANETA" icon={<img style={{width:"30px"}} src={pianeta}  /> }>
        <div style={{ width:"100%",   display: "flex"}} >
        {menuItemSelected==85 ? (
          <MenuItem  rootStyles={{backgroundColor:'#F3F3F3', width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"pianeta","Pianeta",85)}> Pianeta</MenuItem>  
          ):(
          <MenuItem   rootStyles={{backgroundColor:'#FFFFFF', width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"pianeta","Pianeta",85)}> Pianeta</MenuItem>  
        )}  
          {menuLocked[11]==3 ?(
          <WarningIcon fontSize='small' style={{ background: "#ffffff", color: "BF2B0C" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[11]==2 ?(
            <LockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[11]==1 ?(
            <EngineeringIcon fontSize='small' sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[11]==4 ?(
            <LockClockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          )        
          :(<></>)}          </div> 

        <div style={{ width:"100%",   display: "flex"}} >
        {menuItemSelected==80 ? (
          <MenuItem  rootStyles={{backgroundColor:'#F3F3F3', width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"ricerca","Ricerca",80)}> Ricerca</MenuItem>  
          ):(
          <MenuItem   rootStyles={{backgroundColor:'#FFFFFF', width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"ricerca","Ricerca",80)}> Ricerca</MenuItem>  
        )}  
          {menuLocked[12]==3 ?(
          <WarningIcon fontSize='small' style={{ background: "#ffffff", color: "BF2B0C" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[12]==2 ?(
            <LockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[12]==1 ?(
            <EngineeringIcon fontSize='small' sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[12]==4 ?(
            <LockClockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          )        
          :(<></>)}
          </div> 

        <div style={{ width:"100%",   display: "flex"}} >
        {menuItemSelected==81 ? (
          <MenuItem  rootStyles={{backgroundColor:'#F3F3F3', width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"innovazione","Innovazione",81)}> Innovazione</MenuItem>  
          ):(
          <MenuItem   rootStyles={{backgroundColor:'#FFFFFF', width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"innovazione","Innovazione",81)}> Innovazione</MenuItem>  
        )}  
          {menuLocked[13]==3 ?(
          <WarningIcon fontSize='small' style={{ background: "#ffffff", color: "BF2B0C" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[13]==2 ?(
            <LockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[13]==1 ?(
            <EngineeringIcon fontSize='small' sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[13]==4 ?(
            <LockClockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          )        
          :(<></>)}
          </div> 
        <div style={{ width:"100%",   display: "flex"}} >
        {menuItemSelected==82 ? (
          <MenuItem  rootStyles={{backgroundColor:'#F3F3F3', width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"benessere","Benessere",82)}> Benessere</MenuItem>  
          ):(
          <MenuItem   rootStyles={{backgroundColor:'#FFFFFF', width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"benessere","Benessere",82)}> Benessere</MenuItem>  
        )}  
          {menuLocked[14]==3 ?(
          <WarningIcon fontSize='small' style={{ background: "#ffffff", color: "BF2B0C" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[14]==2 ?(
            <LockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[14]==1 ?(
            <EngineeringIcon fontSize='small' sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[14]==4 ?(
            <LockClockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          )        
          :(<></>)}
          </div> 
        <div style={{ width:"100%",   display: "flex"}} >
        {menuItemSelected==83 ? (
          <MenuItem  rootStyles={{backgroundColor:'#F3F3F3', width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"internazionalizzazione","Internazionalizzazione",83)}> Internazionalizzazione</MenuItem>  
          ):(
          <MenuItem   rootStyles={{backgroundColor:'#FFFFFF', width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"internazionalizzazione","Internazionalizzazione",83)}> Internazionalizzazione</MenuItem>  
        )}  
         {menuLocked[15]==3 ?(
          <WarningIcon fontSize='small' style={{ background: "#ffffff", color: "BF2B0C" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[15]==2 ?(
            <LockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[15]==1 ?(
            <EngineeringIcon fontSize='small' sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[15]==4 ?(
            <LockClockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          )        
          :(<></>)}
          </div> 
        <div style={{ width:"100%",   display: "flex"}} >
        {menuItemSelected==84 ? (
          <MenuItem  rootStyles={{backgroundColor:'#F3F3F3', width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"ambiente","Ambiente",84)}> Ambiente</MenuItem>  
          ):(
          <MenuItem   rootStyles={{backgroundColor:'#FFFFFF', width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"ambiente","Ambiente",84)}> Ambiente</MenuItem>  
        )}            
         {menuLocked[16]==3 ?(
          <WarningIcon fontSize='small' style={{ background: "#ffffff", color: "BF2B0C" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[16]==2 ?(
            <LockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[16]==1 ?(
            <EngineeringIcon fontSize='small' sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[16]==4 ?(
            <LockClockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          )        
          :(<></>)}
          </div> 
        </SubMenu>              
        <div style={{ width:"100%",   display: "flex"}} >         
        {menuItemSelected==90 ? (
          <MenuItem icon={<img style={{width:"30px"}} src={direzioni}  /> } rootStyles={{backgroundColor:'#F3F3F3',width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"direzione_pianificazione","DIREZIONE PIANIFICAZIONE, STUDI E VALUTAZIONE",90)}> DIREZIONE PIANIFICAZIONE, STUDI E VALUTAZIONE</MenuItem>  
          ):(
          <MenuItem icon={<img style={{width:"30px"}} src={direzioni}  /> }  rootStyles={{backgroundColor:'#FFFFFF',width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"direzione_pianificazione","DIREZIONE PIANIFICAZIONE, STUDI E VALUTAZIONE",90)}> DIREZIONE PIANIFICAZIONE, STUDI E VALUTAZIONE</MenuItem>  
        )}            
           {menuLocked[17]==3 ?(
          <WarningIcon fontSize='small' style={{ background: "#ffffff", color: "BF2B0C" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[17]==2 ?(
            <LockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[17]==1 ?(
            <EngineeringIcon fontSize='small' sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[17]==4 ?(
            <LockClockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          )        
          :(<></>)}
        
        </div>  
        <div style={{ width:"100%",   display: "flex"}} > 
          {menuItemSelected==95 ? (
          <MenuItem icon={<img style={{width:"30px"}} src={direzioni}  /> } rootStyles={{backgroundColor:'#F3F3F3',width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"direzione_innovazione_impatto","DIREZIONE INNOVAZIONE DI IMPATTO",95)}> DIREZIONE INNOVAZIONE DI IMPATTO</MenuItem>  
          ):(
          <MenuItem icon={<img style={{width:"30px"}} src={direzioni}  /> }  rootStyles={{backgroundColor:'#FFFFFF',width:'90%'}} onClick={(event)=>handleMenuItemClick(event,"direzione_innovazione_impatto","DIREZIONE INNOVAZIONE DI IMPATTO",95)}> DIREZIONE INNOVAZIONE DI IMPATTO</MenuItem>  
        )}            
           {menuLocked[18]==3 ?(
          <WarningIcon fontSize='small' style={{ background: "#ffffff", color: "BF2B0C" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[18]==2 ?(
            <LockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[18]==1 ?(
            <EngineeringIcon fontSize='small' sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[18]==4 ?(
            <LockClockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          )        
          :(<></>)}
          
        </div>  

        <div style={{ width:"100%",   display: "flex"}} >            
        {menuItemSelected==100 ? (            
            <MenuItem icon={<img style={{width:"30px"}} src={altro}   /> }onClick={(event)=>handleMenuItemClick(event,"convenzioni_progetti_speciali","CONVENZIONI, PROGETTI SPECIALI, ULTERIORI PRATICHE E DOCUMENTI",100)} rootStyles={{backgroundColor:'#F3F3F3',width:'90%'}}>
              CONVENZIONI, PROGETTI SPECIALI, ULTERIORI PRATICHE E DOCUMENTI</MenuItem>
          ):(
            <MenuItem icon={<img style={{width:"30px"}} src={altro} /> } onClick={(event)=>handleMenuItemClick(event,"convenzioni_progetti_speciali","CONVENZIONI, PROGETTI SPECIALI, ULTERIORI PRATICHE E DOCUMENTI",100)} rootStyles={{backgroundColor:'#FFFFFF',width:'90%'}} >
              CONVENZIONI, PROGETTI SPECIALI, ULTERIORI PRATICHE E DOCUMENTI</MenuItem>
        )}
           {menuLocked[19]==3 ?(
          <WarningIcon fontSize='small' style={{ background: "#ffffff", color: "BF2B0C" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[19]==2 ?(
            <LockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[19]==1 ?(
            <EngineeringIcon fontSize='small' sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          ):
          menuLocked[19]==4 ?(
            <LockClockIcon fontSize='small' style={{ background: "#ffffff", color: "30720f" }} sx={{paddingTop:'13px', paddingRight:'10px', paddingLeft:'5px',textAlign:'center', alignItems: 'center'}} />   
          )                  
          :(<></>)}       
        </div>  
      </Menu>
    </Sidebar>
    {/*<div style={{ display: 'flex', width:"100%",textAlign:'left',height: '100%', backgroundColor:"red" }}> */}
    <div style={{ display: 'flex', textAlign:'left',height: '100%', width:'100%', backgroundColor:"white" }}>
    {loading ? (        
          <div key={uuidv4()} style={{ width:"25%",   display: "flex", 
          marginLeft: "auto", marginRight: "auto" }}> 
           <ThreeDots color="#30720f" height='350' width='150'/>
          </div>    
    ):(
        <div  style={{ paddingRight:"50px",paddingLeft:"50px", marginTop:"5px", textAlign:'left',width:'100%', height: '100%', zIndex:0}}>
                
                <div style={{ display:'flex', paddingBottom:"20px", width:'100%',  backgroundColor:colorItemSelected, height:'50px'}}>
                { menuLocked[fromSectorToPosition(sector)]=="1" ?(                 
                  <EngineeringIcon fontSize='large' sx={{ position:"absolute", top:"95px",right:"65px"}} />                 
                ):
                menuLocked[fromSectorToPosition(sector)]=="2" ?(
                  <LockIcon fontSize='large' sx={{ position:"absolute", top:"95px",right:"65px"}} />
                ):
                menuLocked[fromSectorToPosition(sector)]=="3" ?(
                  <WarningIcon fontSize='large' sx={{ position:"absolute", top:"95px",right:"65px"}} />
                ):
                menuLocked[fromSectorToPosition(sector)]=="4" ?(
                  <LockClockIcon fontSize='large' sx={{ position:"absolute", top:"95px",right:"65px"}} />
                ):(
                  <></>
                )
                }  
                <p className= "textBlackSedute" style={{paddingLeft:"30px", width:"65%",  fontWeight: 'bold'}}> {conversionFase(data.Fase)} Indice della Seduta del {data.Seduta} - {printable}</p>                

                {mingrantAvailable ? (<p className= "textBlackSedute" style={{textAlign:'right', paddingRight:'100px', paddingLeft:"30px",width:"45%", fontWeight: 'bold'}}> Presenti minigrant</p>):(<></>)}                
              
                </div>

                { printable=='DIREZIONE PIANIFICAZIONE, STUDI E VALUTAZIONE' || printable=='DIREZIONE INNOVAZIONE DI IMPATTO' ?(
                <div style={{ display:'flex', marginBottom:"50px",paddingBottom:"20px", width:'100%',  backgroundColor:colorItemSelected, height:'50px'}}>                  
                  <p className= "textBlackSedute" style={{paddingLeft:"30px",  fontWeight: 'bold'}}> Totale direzione: € {Euro.format(calculateTotalCost(cards))}</p>                  
                </div>): 
                printable == 'CONVENZIONI, PROGETTI SPECIALI, ULTERIORI PRATICHE E DOCUMENTI' ?(
                  <div style={{ display:'flex', marginBottom:"50px",paddingBottom:"20px", width:'100%',  backgroundColor:colorItemSelected, height:'50px'}}>                  
                  <p className= "textBlackSedute" style={{paddingLeft:"30px", fontWeight: 'bold'}}> Totale Convenzioni, progetti speciali, ulteriori pratiche e documenti: € {Euro.format(calculateTotalCost(cards))}</p>                  
                </div>
                ):
                sector=='cultura' ||sector=='attrattivita' ||sector=='competenze'||sector=='custodia'||sector=='partecipazione' ? (
                  <div style={{ display:'flex', marginBottom:"50px",paddingBottom:"20px", width:'100%',  backgroundColor:colorItemSelected, height:'50px'}}>                  
                    <p className= "textBlackSedute" style={{paddingLeft:"30px", width:"45%", fontWeight: 'bold'}}> Totale obiettivo: € {totaleObiettivi[0]}</p>
                    <p className= "textBlackSedute" style={{paddingLeft:"30px", width:"45%", fontWeight: 'bold'}}> Totale missione: € {Euro.format(calculateTotalCost(cards))}</p>                  
                  </div>
                  ):
                  sector=='persone'||sector=='abitare'||sector=='lavoro'||sector=='educazione'||sector=='comunita'||sector=='inclusione' ? (
                    <div style={{ display:'flex', marginBottom:"50px",paddingBottom:"20px", width:'100%',  backgroundColor:colorItemSelected, height:'50px'}}>                  
                    <p className= "textBlackSedute" style={{paddingLeft:"30px", width:"45%",  fontWeight: 'bold'}}> Totale obiettivo: € {totaleObiettivi[1]}</p>
                    <p className= "textBlackSedute" style={{paddingLeft:"30px", width:"45%",  fontWeight: 'bold'}}> Totale missione: € {Euro.format(calculateTotalCost(cards))}</p>                  
                  </div>
                  ):                    
                  sector=='pianeta'||sector=='ricerca'||sector=='ambiente' ||sector=='benessere'||sector=='innovazione'||sector=='internazionalizzazione' ?(
                    <div style={{ display:'flex', marginBottom:"50px",paddingBottom:"20px", width:'100%',  backgroundColor:colorItemSelected, height:'50px'}}>                  
                    <p className= "textBlackSedute" style={{paddingLeft:"30px", width:"45%", fontWeight: 'bold'}}> Totale obiettivo: € {totaleObiettivi[2]}</p>
                    <p className= "textBlackSedute" style={{paddingLeft:"30px", width:"45%", fontWeight: 'bold'}}> Totale missione: € {Euro.format(calculateTotalCost(cards))}</p>                  
                    </div>):
                  (<></>)
                }

                {/* <img src={logo} className="App-logo" alt="logo" />  */}
                <IndexProvider>        
                <SortableContext.Provider value={{ openDialog, openMoveDialog, setItem, deleteItem,isBloccataMissione, isItemCoerente }}>          
                    <SortableTree
                        items={cards}                                                
                        onItemsChanged={onChanged}
                        TreeItemComponent={TreeItem}                                 
                        indentationWidth="50"      
                        disableSorting={isBloccataMissione()}
                    />                    
                    </SortableContext.Provider>
                </IndexProvider>
        </div>
    )}
    </div>
        </div>   
        )}
          <div>
        {alert1}        
        </div>
        </div>                
    );
  
    
};
export const useSortable = () => useContext(SortableContext);


const TreeItem = React.forwardRef((props, ref) => {    
       
    const { openDialog, openMoveDialog, setItem, deleteItem,isBloccataMissione, isItemCoerente } = useSortable(); 
  
    //console.log("TEST");
    const handleChildClick = function(e,props) {                       
      e.preventDefault();      
      setItem(props.item);      
      e.stopPropagation();    
      openDialog(props.item.Tipo);                
      //console.log('handleChildClick', e,props.item, props.item.Tipo); //,selectedIndex);
      //console.log("BLOCCO?",isBloccataMissione());
    };
  
    
    const handleMoveClick = function(e,props) {                 
      e.preventDefault();      
      setItem(props.item);      
      e.stopPropagation();    
      openMoveDialog(props.item.Tipo);                
      //console.log('handleMoveClick', e, props.item, props.item.Tipo); 
    };

    const handleDeleteClick = function(e,props) {                 
      e.preventDefault();      
      //console.log("QUI",props.item.id,props.item);
      setItem(props.item);      
      e.stopPropagation();    
      deleteItem(props.item.id);             
      //doPostTotaleObiettivi(sector);   
      //console.log('handleChildClick', e, props.item, props.item.Tipo); //,selectedIndex);
    };
  
/*
    //let Euro = new Intl.NumberFormat('it-IT', {
      let Euro = new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
    });
 */
    let Euro = new Intl.NumberFormat('it-IT', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return (
                 
      <SimpleTreeItemWrapper {...props} ref={ref} style={{ display: 'inherit', minWidth:window.innerWidth-500,width: '100%',textAlign:'left' }} >     
        {
        props.item.Tipo =='Pratica' ?(            
            <div style={{width:"100%"}}>
                <div style={{display:'flex', width:'100%'}}> <p className= "textBlackSedute" style={{paddingLeft:"10px",color:"black", marginTop:"10px",marginBottom:"10px", color: isItemCoerente(props.item) ? 'red' : 'black' }}> (Pratica 
                  {props.item.IdRichiesta !='' && props.item.IdRichiesta !='idRichiesta'  ?(
                  <>, {props.item.IdRichiesta}</>  ):(<></>)}) <b style={{marginLeft:"10px"}}> {props.item.Ente} </b></p>              
                  {props.item.Localizzazione !='' && props.item.Localizzazione !='Localizzazione'  ? (
                  <p className= "textBlackSedute" style={{marginTop:"10px",marginBottom:"10px", color: isItemCoerente(props.item) ? 'red' : 'black'}}>,  <b style={{marginLeft:"10px", textTransform:'capitalize', color: isItemCoerente(props.item) ? 'red' : 'black'}}> {props.item.Localizzazione} </b></p> ):(<></>)} 
                </div>
                <div style={{display:'flex',marginLeft:"10px", width:'100%'}}> <p className= "textBlackSedute" style={{color:"black",marginTop:"10px",marginBottom:"10px", whiteSpace: 'pre-line', color: isItemCoerente(props.item) ? 'red' : 'black'}}> <b> {props.item.Descrizione} </b></p>              
                </div>                
                <div style={{display:'inline-block',marginLeft:"10px", width:'100%'}}> <p className= "textBlackSedute" style={{color:"black",marginTop:"10px",marginBottom:"10px",color: isItemCoerente(props.item) ? 'red' : 'black'}}> <b>€ {Euro.format(props.item.IpotesiIntervento)} </b></p>
                {props.item.Note !='' && props.item.Note !='Note'  ?(
                  <p className= "textBlackSedute" style={{marginTop:"10px",marginBottom:"10px",whiteSpace: 'pre-line', color: isItemCoerente(props.item) ? 'red' : 'black'}}> * {props.item.Note}</p>  ):(<></>)}              
                </div>
            </div>
        ):
        props.item.Tipo =='Ambito'  ?(
            <div style={{display:'flex', width:'100%'}}> <p className= "textBlackSedute" style={{paddingLeft:"10px",color:"black", color: isItemCoerente(props.item) ? 'red' : 'black'}}>(Ambito) <b style={{marginLeft:"10px",color: isItemCoerente(props.item) ? 'red' : 'black'}}> {props.item.Descrizione} </b></p>           
            </div>
        ):
        props.item.Tipo =='Documento'  ?(
            <div style={{width:"100%"}}>
            <div style={{display:'flex', marginLeft:"10px", width:'100%'}}> <p className= "textBlackSedute" style={{marginTop:"10px",marginBottom:"10px", color: isItemCoerente(props.item) ? 'red' : 'black'}}>(Documento) <b style={{marginLeft:"10px",color: isItemCoerente(props.item) ? 'red' : 'black'}}>{props.item.Descrizione} </b>  </p>           
            </div>
            {props.item.Note !='' && props.item.Note !='Note' && props.item.Note !='Note Documento' ?(
            <div style={{display:'flex', marginLeft:"10px",width:'100%'}}>
            <p className= "textBlackSedute" style={{color:"black",marginTop:"10px",marginBottom:"10px",color: isItemCoerente(props.item) ? 'red' : 'black'}}><i >{props.item.Note}</i>  </p>           
            </div>):(
                <div></div>
            )
            }
            </div>
            
        ):      
        (<></>)
        }
        
          <div style={{display:'flex',border: "0px solid #d3d3d3"}}>

              <Tooltip title="Modifica" placement="top">
                <span>
                  <IconButton aria-label="delete" onClick={(event)=>handleChildClick(event,props)} disabled={isBloccataMissione()}>
                    {isBloccataMissione() ?(
                      <EditIcon  style={{ background: "#ffffff", color: "dddddd" }}/>
                    ):(
                      <EditIcon  style={{ background: "#ffffff", color: "000000" }}/>
                    )}
                    
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Sposta" placement="top">
                <span>
                  <IconButton aria-label="delete" onClick={(event)=>handleMoveClick(event,props)} disabled={isBloccataMissione()}>
                    {isBloccataMissione() ?(
                      <SendIcon  style={{ background: "#ffffff", color: "dddddd" }}/>
                    ):(
                      <SendIcon  style={{ background: "#ffffff", color: "30720f" }}/>
                    )
                    }
                    
                  </IconButton>
                </span>
              </Tooltip>
              
              <Tooltip title="Elimina" placement="top">
                <span>
                  <IconButton aria-label="delete" onClick={(event)=>handleDeleteClick(event,props)} disabled={isBloccataMissione()}>
                  {isBloccataMissione() ?(
                    <DeleteIcon  style={{ background: "#ffffff", color: "dddddd" }}/>
                  ):(
                    <DeleteIcon  style={{ background: "#ffffff", color: "BF2B0C" }}/>
                  )}
                  </IconButton>        
                </span>
              </Tooltip>
          </div>
        
      </SimpleTreeItemWrapper>
      
    );
  });