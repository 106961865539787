import React, { useState, useEffect, useContext } from "react";

import { useParams } from "react-router-dom";
import RouterContext from "../../context/RouterContext";
import {updateSeduta} from "./Utils";
import {properties} from "../../utils/properties";
import { useNavigate } from "react-router-dom";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export default function SpecificArgument() {
    
    let { idDossier, id } = useParams();
    const [subArgu, setSubArgu] = useState([]);
    const [schedaCard, setSchedaCard] = useState([]);

    const navigate = useNavigate();
    const { pageRouter, setPageRouter } = useContext(RouterContext);
    const meetingId=idDossier;

    const updateUI = function(jsonData) {                    
        const keySubArgument = Object.entries(jsonData)[5][1];    
        const valueSubArgument = Object.entries(keySubArgument);        
        var index16;
        valueSubArgument.forEach( (entry, index1) => {
                let key= entry[0];
                //let value= entry[1];
                if(key===id) {                                        
                    index16=index1;
                    console.log("index",entry, index16);
                }                
                                
        }); 
        
        const macroLabel = valueSubArgument[index16][1].macro;
        //console.log("if01",macroLabel);
        const arrFieldName = Object.keys(keySubArgument);     
        //console.log("m1",macroLabel,arrFieldName);   
        if (macroLabel === "ai" || macroLabel === "in_evidenza"|| macroLabel === "direzione" || macroLabel === "macro") {
            //var itemLength = valueSubArgument[id].cardIds.length;
            var itemLength = valueSubArgument[index16][1].cardIds.length;
            //console.log("m2",itemLength);  
            //console.log("m3",valueSubArgument[index16]);
            //console.log("m4",valueSubArgument[index16][1]);  
            var items = [];
            var schedaItems = [];
            //console.log("ttt",valueSubArgument[id]);
            for (var i = 0; i < itemLength; i++) {
                //var itemType = valueSubArgument[id].cardIds[i].type;
                var itemType = valueSubArgument[index16][1].cardIds[i].type;
                //var itemID = valueSubArgument[id].cardIds[i].item_id;
                var itemID = valueSubArgument[index16][1].cardIds[i].item_id;
                //console.log("t",itemType);
                var item = [];
                if (itemType === "premessa") {
                    var arrPremesse = Object.entries(
                        //valueSubArgument[id].premesse
                        valueSubArgument[index16][1].premesse
                    );
                    var premesseLength = arrPremesse.length;

                    for (var j = 0; j < premesseLength; j++) {
                        if (itemID === arrPremesse[j][0]) {
                            item = {
                                label: arrPremesse[j][1].label,
                                url: arrPremesse[j][1].url,
                                //color: valueSubArgument[id].color,
                                color: valueSubArgument[index16][1].color,
                                type: "premessa",
                            };
                        }
                    }
                    items.push(item);
                } else if (itemType === "container") {
                    var arrContainer = Object.entries(
                        //valueSubArgument[id].containers
                        valueSubArgument[index16][1].containers
                    );
                    var containLength = arrContainer.length;
                    //console.log("ac",arrContainer);
                    for (var k = 0; k < containLength; k++) {
                        if (itemID === arrContainer[k][0]) {
                            item = {
                                label: arrContainer[k][1].label,
                                value: arrContainer[k][1].value,
                                //color: valueSubArgument[id].color,
                                color: valueSubArgument[index16][1].color,
                                price: arrContainer[k][1].totale_complessivo,
                                subsize: arrContainer[k][1].numero_schede,
                                type: "container",
                                item_id: arrContainer[k][0],
                            };
                            //console.log("item11",item);
                            //console.log("item21",arrContainer[k][0]);                            
                        }
                    }
                    items.push(item);
                } else {
                    var objCard = Object.entries(jsonData)[4][1];
                    var arrCard = Object.entries(objCard);
                    var cardLength = arrCard.length;
                    var schedaItem = [];

                    for (var m = 0; m < cardLength; m++) {
                        if (itemID === arrCard[m][0]) {
                            var arrSearchCard = Object.entries(arrCard[m][1]);
                            var finalCard = Object.entries(
                                arrSearchCard[arrSearchCard.length - 1][1]
                            );
                            var finalCardItemSize = finalCard[0][1].length;
                            var argomento = "";
                            var sotto = "";
                            var init = "";
                            var po="";
                            var rs="";
                            var local = "";
                            var price = "";
                            var settore= "";
                            var tableItems = [];

                            for (var h = 0; h < finalCardItemSize; h++) {
                                if (
                                    finalCard[0][1][h].label === "Argomento" ||
                                    finalCard[0][1][h].label === "Obiettivo"
                                ) {
                                    argomento = finalCard[0][1][h].value;
                                } else if (finalCard[0][1][h].label === "Settore") {
                                    settore = finalCard[0][1][h].value;
                                }
                                else if (
                                    finalCard[0][1][h].label ===
                                        "Sotto-Argomento" ||
                                    finalCard[0][1][h].label === "Missione"
                                ) {
                                    //26/03/2023 per evitare di avere la label missione= "convenzioni, etc"
                                    if(finalCard[0][1][h].value !="CONVENZIONI, PROGETTI SPECIALI, ULTERIORI PRATICHE E DOCUMENTI")
                                    //26/03/2023
                                        sotto = finalCard[0][1][h].value;
                                } else if (
                                    finalCard[0][1][h].label === "Iniziativa" ||
                                    finalCard[0][1][h].label ===
                                        "Denominazione iniziativa"
                                ) {
                                    init = finalCard[0][1][h].value;
                                } else if (
                                    finalCard[0][1][h].label === "Progetto Operativo" ||
                                    finalCard[0][1][h].label ===
                                        "Progetto operativo"
                                ) {
                                    po = finalCard[0][1][h].value;
                                }else if (
                                    finalCard[0][1][h].label === "Ragione Sociale" ||
                                    finalCard[0][1][h].label ===
                                        "Ragione sociale"
                                ) {
                                    rs = finalCard[0][1][h].value;
                                }else if (
                                    finalCard[0][1][h].label ===
                                    "Localizzazione"
                                ) {
                                    local = finalCard[0][1][h].value;
                                } else if (
                                    finalCard[0][1][h].label ===
                                    "Importo proposto"
                                ) {
                                    price = finalCard[0][1][h].value;
                                } else {
                                    if (finalCard[0][1][h].table) {
                                        for (var t = 1; t < finalCard[0][1][h].table.length; t++) {
                            var tableItem = [];
                            tableItem = {
                                title: finalCard[0][1][h].table[t][3],
                                content: finalCard[0][1][h].table[t][4],
                                content2: finalCard[0][1][h].table[t][5],
                            };
                            //console.log("title",cardData[j]["table"][t][3]);
                            //console.log("cont",cardData[j]["table"][t][4]);
                            //console.log("cont2",cardData[j]["table"][t][5]);
                            tableItems.push(tableItem);
                            }                                       
                                    }
                                }
                            }
                            schedaItem = {
                                argomento: argomento,
                                sotto: sotto,
                                init: init,
                                po:po,
                                rs:rs,
                                local: local,
                                price: price,
                                //color: valueSubArgument[id].color,
                                color: valueSubArgument[index16][1].color,
                                table: tableItems,
                                id: itemID,
                                type: "scheda",
                                settore: settore,
                            };
                        }
                    }
                    //schedaItems.push(schedaItem);
                    //items.push(item);
                    items.push(schedaItem);
                }
            }
            //console.log("TEST",items);
            //setSubArgu(items);        

            //setSchedaCard(schedaItems);
        }

        //Topic that has sub list.
        if (macroLabel === "macro") {
            var subTopics = [];
            var index = [];
            //console.log("items0899",id, index16);
            //console.log("items0899",arrFieldName[index16]);
            //if (arrFieldName[id] === "macropersonepace") {
            if (arrFieldName[index16] === "macropersonepace") {
                for (var i = 0; i < arrFieldName.length; i++) {
                    if (
                        arrFieldName[i] === "educazione" ||
                        arrFieldName[i] === "occupazione" ||
                        arrFieldName[i] === "abitaresociale" ||
                        arrFieldName[i] === "comunitasolidali" ||
                        arrFieldName[i] === "territoriinclusivi" ||
                        arrFieldName[i] === "personeepace" ||
                        arrFieldName[i] === "risorseliberepersone"
                    ) {
                        index.push(i);
                    }
                }
            //} else if (arrFieldName[id] === "macroprosperita") {
            } else if (arrFieldName[index16] === "macroprosperita") {
                for (var i = 0; i < arrFieldName.length; i++) {
                    console.log("21",arrFieldName[i]);
                    if (
                        arrFieldName[i] === "ricercaformazione" ||
                        arrFieldName[i] === "innovazione" ||
                        arrFieldName[i] === "stilisani" ||
                        arrFieldName[i] === "opportunita" ||
                        arrFieldName[i] === "sostenibilita" ||
                        arrFieldName[i] === "prosperitaepianeta" ||
                        arrFieldName[i] === "risorselibereprosperita"
                    ) {
                        index.push(i);
                    }
                }
            } else {
                for (var i = 0; i < arrFieldName.length; i++) {
                    if (
                        arrFieldName[i] === "attrattivita" ||
                        arrFieldName[i] === "competenze" ||
                        arrFieldName[i] === "custodia" ||
                        arrFieldName[i] === "partecipazione" ||
                        arrFieldName[i] === "patrimonioepartecipazione" ||
                        arrFieldName[i] === "risorseliberepatrimonio"
                    ) {
                        index.push(i);
                    }
                }
            }

            for (var j = 0; j < index.length; j++) {
                //console.log("1234",valueSubArgument[index[j]][1]);
                //console.log("12",valueSubArgument[index[j]][0]);

                var subTopic = {
                    label: valueSubArgument[index[j]][1].label,
                    value: valueSubArgument[index[j]][1].value,
                    color: valueSubArgument[index[j]][1].color,
                    price: valueSubArgument[index[j]][1].totale_complessivo,
                    subsizeOp: valueSubArgument[index[j]][1].numero_pratiche_prog_int,
                    subsize: valueSubArgument[index[j]][1].numero_pratiche,
                    subTopicIndex: index[j],
                    containsMinigrant:
                        valueSubArgument[index[j]][1].containsMinigrant,
                    type: "sub-topic",
                    ref:valueSubArgument[index[j]][0],
                };
                //console.log("subtopic",subTopic);
                //console.log("subtopic2",valueSubArgument[index[j]]);
                //subTopics.push(subTopic);
                items.push(subTopic);
            }
            
            //console.log("TEST1", items);
            //setSubArgu(items);
        } 
    setSubArgu(items);              
    }



    const doPostSeduta2 = function() {
    //console.log("doing sedute post");
    var xhr2 = new XMLHttpRequest();                
    //xhr2.open('POST', properties.productionUrl+'/rest/checkSeduta',true);
    //xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
    xhr2.open('POST', properties.productionUrl+'/php_demo/core/rest/checkSeduta.php',true);
    xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
    xhr2.withCredentials = true;      
    xhr2.onload = function (e) {      
      if (xhr2.readyState === 4) {
        if (xhr2.status === 200) {                              
            //console.log("OK92",xhr2);
            var responseText = xhr2.responseText;                    
            var obj=JSON.parse(responseText);
            //console.log("OK93",obj);          
            if(obj.status==="204" ){ 
                console.log("OK DOPOSTSEDUTE2 204",xhr2);    
                const MeetingInfo = JSON.parse(localStorage.getItem("meetingContent"));                        
                updateUI(MeetingInfo);
            }              
            else{
                //status 201
                console.log("OK DOPOSTSEDUTE2 201",xhr2);  
                
                try{
                    localStorage.setItem("meetingContent", obj.content);
                    localStorage.setItem("meeting", meetingId);
                    localStorage.setItem("meetingVersion", obj.date);                                                                                                                                                                                        
                }
                catch(error) {
                    console.log("LOCALSTORAGE ISSUE", error);
                }                   
                const MeetingInfo = JSON.parse(obj.content);
                updateUI(MeetingInfo);
            }
        } 
        else {
          console.error("Error 1", xhr2.statusText);          
          alert("Errore recuperando la seduta");  
        }
      }
    };

    xhr2.onerror = function (e) {
      console.error("Error 22", xhr2.statusText);
    };
    
    let urlEncodedData = "idf=" + meetingId;
    if (localStorage.meeting===meetingId){                  
        const currentData=localStorage.getItem("meetingVersion");        
        urlEncodedData = urlEncodedData+ "&date=" + currentData;                    
        }    
    xhr2.send(urlEncodedData);
    }

    useEffect(() => {
        //console.log("SPEC_ARG",id);
        if( !window.cordova){
            //console.log ("2");
        //CHECK AUTHENTICATION
        var xhr = new XMLHttpRequest();                
        //xhr.open('POST', properties.productionUrl+'/rest/ping',true);
        xhr.open('POST', properties.productionUrl+'/php_demo/core/rest/ping.php',true);
        xhr.withCredentials = true;
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    
        xhr.onload = function (e) {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {                    
                    console.log("OK",xhr);  
                    updateSeduta(meetingId,doPostSeduta2,updateUI);                  
                } else {
                    console.error("Error 1", xhr);           
                    localStorage.clear();         
                    /*history.push({                
                    pathname: "/login",
                    });*/
                    navigate("/login");  
                }
            }
        };
    
        xhr.onerror = function (e) {
          console.error("Error 2", xhr);
          localStorage.clear();
          /*history.push({                
                    pathname: "/login",
                    });*/
            navigate("/login");  
        };
             
        let urlEncodedData = null; 
        xhr.send(urlEncodedData);
        //CHECK AUTHENTICATION
        }
        
        if (window.cordova)
        {
           updateSeduta(meetingId,doPostSeduta2,updateUI);
        }

        /*
       setPageRouter({
            router: history.location.pathname,
            homecolor: "#FF5A60",
            settingcolor: "#879497",
            homebordercolor: "#FF5A60",
            settingbordercolor: "#FFFFFF",
            hometitlecolor: "black",
            settingtitlecolor: "#879497",
        }); */
    }, []);

    const openPdf = (type, url, index,item_id,item) => {
        //console.log("item1111111",item);
        console.log("WUI",url);
        if (type === "premessa") { 
            window.open(url);
        }
        if (type === "sub-topic") { //MISSIONI
            /*history.push({          
                pathname: "/fascicoli/" + meetingId+"/proposteintervento/missioni/"+ item.ref,                
            });*/
            navigate("/fascicoli/" + meetingId+"/proposteintervento/missioni/"+ item.ref);  
        }
        if (type === "container") { //AMBITI
            /*history.push({                
                pathname: "/fascicoli/" + meetingId+"/proposteintervento/missioni/"+ id+"/"+ type+ "/" + item_id,
            });*/
            navigate("/fascicoli/" + meetingId+"/proposteintervento/missioni/"+ id+"/"+ type+ "/" + item_id);  
        }
    };

    const openScheda = (id) => {
        /*history.push({            
            pathname: "/fascicoli/" + meetingId+"/proposteintervento/schede/"+ id,            
        });*/
        navigate("/fascicoli/" + meetingId+"/proposteintervento/schede/"+ id);
    };
    return (
        <div className="content">
            {subArgu.map((item, index) =>
                item.type === "scheda" ? (
                        <div
                            className="scheda-item"
                            style={{borderRightColor: item.color, cursor: "pointer",}}
                            key={index}
                            onClick={() => openScheda(item.id)}
                        >
                            <div
                                className="common-list"
                                key={index}
                                style={{
                                    position: "relative",
                                    alignItems: "unset",
                                    display: "unset",
                                    position: "unset",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <div>
                                        <div
                                            style={{
                                                paddingLeft: "30px",
                                                textAlign: "left",
                                            }}
                                        >

                                        {item.argomento ? (
                                            <p className= "textGreySedute "
                                                style={{
                                                    //fontWeight: "bold",
                                                    //color: "#879497",
                                                    paddingLeft: "20px",
                                                    marginBottom: "0px",
                                                }}
                                            >
                                                Obiettivo:{" "}
                                                <span className= "textBlackSedute "
                                                    //style={{
                                                    //    fontWeight: "bold",
                                                    //    color: "black",
                                                    //}}
                                                >
                                                    {item.argomento}
                                                </span>
                                            </p>
                                            ): (<></> )}

                                            {item.settore ? (
                                            <p className= "textGreySedute "
                                                style={{
                                                    //fontWeight: "bold",
                                                    //color: "#879497",
                                                    paddingLeft: "20px",
                                                    marginBottom: "0px",
                                                }}
                                            >
                                                Settore:{" "}
                                                <span className= "textBlackSedute "
                                                    //style={{
                                                    //    fontWeight: "bold",
                                                    //    color: "black",
                                                    //}}
                                                >
                                                    {item.settore}
                                                </span>
                                            </p>
                                            ): (<></> )}
                                            
                                            {item.sotto ? (
                                            <p className= "textGreySedute "
                                                style={{
                                                    //fontWeight: "bold",
                                                    //color: "#879497",
                                                    paddingLeft: "20px",
                                                    marginBottom: "0px",
                                                }}
                                            >
                                                Missione:{" "}
                                                <span className= "textBlackSedute "
                                                    //style={{
                                                    //    fontWeight: "bold",
                                                    //    color: "black",
                                                    //}}
                                                >
                                                    {item.sotto}
                                                </span>
                                            </p>
                                            ): (<></>)}
                                          
                                        </div>
                                        <div
                                            style={{
                                                textAlign: "left",
                                                paddingRight: "100px",
                                                paddingLeft: "50px",
                                                marginTop: "16px",
                                                marginBottom: "16px",
                                            }}
                                        >
                                          <div  style={{
                                                display: "inline",                                                                                     
                                            }}>
                                                {item.init ? (
                                                    <p className= "textInLine textGreySedute"
                                                    style={{
                                                        //fontWeight: "bold",
                                                        //color: "#879497",
                                                        marginBottom: "0px",
                                                        //display: "inline",                                                

                                                    }}
                                                    >
                                                        Denominazione Iniziativa:&nbsp;
                                                    </p>
                                                ) : (
                                                    <p  className= "textInLine textGreySedute"
                                                    style={{
                                                        //fontWeight: "bold",
                                                        //color: "#879497",
                                                        marginBottom: "0px",
                                                        //display: "inline",
                                                       
                                                    }}
                                                    >
                                                        Progetto Operativo:&nbsp;
                                                    </p>
                                                )}
                                                {item.init ? (
                                                    <p className= "textInLine textBlackSedute"
                                                        style={{
                                                            //fontWeight: "bold",
                                                            //color: "black",
                                                            marginBottom: "0px",
                                                            //display: "inline",
                                                       
                                                        }}
                                                    >
                                                        {item.init}
                                                    </p>
                                                ) : (
                                                    <p className= "textInLine textBlackSedute"
                                                        style={{
                                                            //fontWeight: "bold",
                                                            //color: "black",
                                                            marginBottom: "0px",
                                                            //display: "inline",
                                                          
                                                        }}
                                                    >
                                                        {item.po}
                                                    </p>
                                                    )}
                                            </div>
                                              {item.rs ? (
                                                <p className= "textGreySedute"
                                                    //style={{
                                                    //    fontWeight: "bold",
                                                    //    color: "#879497",
                                                    //    textAlign: "left",
                                                    //}}
                                                >
                                                    Ragione Sociale:{" "}
                                                    <span className= "textBlackSedute"
                                                        //style={{
                                                        //    fontWeight: "bold",
                                                        //    color: "black",
                                                        //}}
                                                    >
                                                {item.rs}
                                            </span>
                                                </p>
                                            ) : (
                                                <></>
                                            )}


                                            <p className= "textGreySedute"
                                                //style={{
                                                //    fontWeight: "bold",
                                                //    color: "#879497",
                                                //    textAlign: "left",
                                                //}}
                                            >
                                                Localizzazione:{" "}
                                                <span className= "textBlackSedute"
                                                    //style={{
                                                    //    fontWeight: "bold",
                                                    //    color: "black",
                                                    //}}
                                                >
                                            {item.local}
                                        </span>
                                            </p>
                                            <p className= "textGreySedute"
                                                //style={{
                                                //    fontWeight: "bold",
                                                //    color: "#879497",
                                                //    textAlign: "left",
                                                //}}
                                            >
                                                Importo proposto:{" "}
                                                <span className= "textBlackSedute"
                                                    //style={{
                                                    //    fontWeight: "bold",
                                                    //    color: "black",
                                                    //}}
                                                >
                                            {item.price}
                                        </span>
                                            </p>
                                        </div>
                                    </div>

                                    <div
                                        style={{
                                            color: "#879497",
                                            display: "flex",
                                            alignItems: "center",
                                            height: "100%",
                                        }}
                                    >
                                        <div
                                            style={{
                                                paddingRight: "25px",
                                                color: "#879497",
                                                display: "flex",
                                            }}
                                        >
                                          
                                          <KeyboardArrowRightIcon sx={{ color: '#879497' }} fontSize="large"/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {item.table.length ? (
                                <p className= "textGreyTable"
                                    style={{
                                        marginTop: "0px",
                                        //fontWeight: "bold",
                                        //color: "#879497",
                                        //textAlign: "left",
                                        paddingLeft: "50px",
                                    }}
                                >
                                    Cosi Ripartito:
                                </p>
                            ) : (
                                <></>
                            )}
                            {item.table.map((itemlist, index) =>
                                index % 2 === 0 ? (
                                    <div
                                        style={{
                                            textAlign: "left",
                                            paddingLeft: "50px",
                                            //color: "#879497",
                                            //fontWeight: "bold",
                                            background: "#EFEFF6",
                                        }}
                                    >
                                        <p className= "textGreyTable"
                                            style={{
                                                display: "inline-block",
                                                width: "20%",
                                            }}
                                        >
                                            {itemlist.title}
                                        </p>
                                        <p className= "textGreyTable" style={{display: "inline-block",width: "25%" }}>
                                            {itemlist.content}
                                        </p>
                                        <p className= "textGreyTable" style={{display: "inline-block", width: "25%"}}>
                                            {itemlist.content2}
                                        </p>
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            textAlign: "left",
                                            paddingLeft: "50px",
                                            color: "#879497",
                                            //fontWeight: "bold",
                                        }}
                                    >
                                        <p className= "textGreyTable"
                                            style={{
                                                display: "inline-block",
                                                width: "20%",
                                            }}
                                        >
                                            {itemlist.title}
                                        </p>
                                        <p className= "textGreyTable" style={{display: "inline-block", width: "25%" }}>
                                            {itemlist.content}
                                        </p>
                                        <p className= "textGreyTable" style={{display: "inline-block", width: "25%"}}>
                                            {itemlist.content2}
                                        </p>
                                    </div>
                                )
                            )}
                        </div>
                ) :
                (

                <div
                    className="common-list"
                    style={{ position: "relative", cursor: "pointer", }}
                    key={index}
                    onClick={() =>
                        openPdf(item.type, item.url, item.subTopicIndex, item.item_id,item)
                    }
                >                
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            paddingLeft: "30px",
                        }}
                    >
                        {item.type === "premessa" ? (
                            <p className= "textBlackSedute"
                                style={{
                                    paddingLeft: "20px",
                                    //fontSize: "18px",
                                    //fontWeight: "bold",
                                }}
                            >
                                {item.label}
                            </p>
                        ) : (
                            <>
                                <p className= "textBlackSedute"
                                    style={{
                                        paddingLeft: "20px",
                                        //fontSize: "18px",
                                        //fontWeight: "bold",
                                    }}
                                >
                                    {item.label} {item.value}
                                </p>
                                {item.subsize ? (
                                    <p className= "textGreySedute"
                                        style={{
                                            margin: "0px 0px 15px 0px",
                                            paddingLeft: "20px",
                                            //fontWeight: "bold",
                                            //color: "#879497",
                                        }}
                                    >
                                        Numero Pratiche:{" "}
                                        <span className= "textBlackSedute"
                                            //style={{
                                            //    fontWeight: "bold",
                                            //    color: "black",
                                            //}}
                                        >
                                            {item.subsize}
                                        </span>
                                    </p>
                                ) : (
                                    <></>
                                )}
                                  {item.subsizeOp ? (
                                    <p className= "textGreySedute"
                                        style={{                                       
                                            margin: "0px 0px 15px 0px",    
                                            paddingLeft: "20px",
                                            //fontWeight: "bold",
                                            //color: "#879497",
                                        }}
                                    >
                                        Numero Pratiche Progetti Operativi:{" "}
                                        <span className= "textBlackSedute"
                                            //style={{
                                            //    fontWeight: "bold",
                                            //    color: "black",
                                            //}}
                                        >
                                            {item.subsizeOp}
                                        </span>
                                    </p>
                                ) : (
                                    <></>
                                )}
                                {item.price ? (
                                    <p className= "textGreySedute"
                                        style={{
                                            margin: "0px 0px 15px 0px",
                                            paddingLeft: "20px",
                                            //fontWeight: "bold",
                                            //color: "#879497",
                                        }}
                                    >
                                        Totale Complessivo:{" "}
                                        <span className= "textBlackSedute"
                                           // style={{
                                           //     fontWeight: "bold",
                                           //     color: "black",
                                           // }}
                                        >
                                            {item.price}
                                        </span>
                                    </p>
                                ) : (
                                    <></>
                                )}
                                {item.containsMinigrant ? (
                                    <p className= "textGreySedute"
                                        style={{
                                            margin: "0px 0px 15px 0px",
                                            paddingLeft: "20px",                                           
                                            //fontWeight: "bold",
                                            //color: "#879497",
                                        }}
                                    >
                                        {item.containsMinigrant}
                                    </p>
                                ) : (
                                    <></>
                                )}
                            </>
                        )}
                    </div>
                    <div
                        style={{
                            color: "#879497",
                            display: "flex",
                            alignItems: "center",
                            position: "absolute",
                            height: "100%",
                            right: "0px",
                        }}
                    >
                        <div
                            style={{
                                paddingRight: "25px",
                                color: "#879497",
                                display: "flex",
                            }}
                        >
                            
                            <KeyboardArrowRightIcon sx={{ color: '#879497' }} fontSize="large"/>
                        </div>
                        <div
                            style={{
                                marginLeft: "10px",
                                width: "10px",
                                height: "100%",
                                background: item.color,
                                borderBottomRightRadius: "8px",
                                borderTopRightRadius: "8px",
                            }}
                        ></div>
                    </div>
                </div>
            ))}
            {schedaCard.map((item, index) => (
                <div
                    className="scheda-item"
                    style={{ borderRightColor: item.color, cursor: "pointer", }}
                    key={index}
                    onClick={() => openScheda(item.id)}
                >
                    <div
                        className="common-list"
                        key={index}
                        style={{
                            position: "relative",
                            alignItems: "unset",
                            display: "unset",
                            position: "unset",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <div>
                                <div
                                    style={{
                                        paddingLeft: "30px",
                                        textAlign: "left",
                                    }}
                                >
                                    <p
                                        style={{
                                            fontWeight: "bold",
                                            color: "#879497",
                                            paddingLeft: "20px",
                                            marginBottom: "0px",
                                        }}
                                    >
                                        Obiettivo:{" "}
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                                color: "black",
                                            }}
                                        >
                                            {item.argomento}
                                        </span>
                                    </p>

                                      {item.sotto ? (
                                    <p
                                        style={{
                                            fontWeight: "bold",
                                            color: "#879497",
                                            paddingLeft: "20px",
                                            marginBottom: "0px",
                                        }}
                                    > Missione:{" "}
                                    <span
                                            style={{
                                                fontWeight: "bold",
                                                color: "black",
                                            }}
                                        >
                                            {item.sotto}
                                    </span>
                                    </p>
                        ) : (
                            <></>
                        )}
                                </div>
                                <div
                                    style={{
                                        textAlign: "left",
                                        paddingRight: "100px",
                                        paddingLeft: "50px",
                                    }}
                                >
                                    <div style={{
                                                display: "flex",
                                            }}>
                                      {item.init ? (
                            <p style={{
                                                    fontWeight: "bold",
                                                    color: "#879497",
                                                    marginBottom: "0px",
                                                }}
                                            >
                                Denominazione Iniziativa:&nbsp;
                            </p>
                        ) : (
                            <p style={{
                                                    fontWeight: "bold",
                                                    color: "#879497",
                                                    marginBottom: "0px",
                                                }}
                                            >
                                Progetto Operativo:&nbsp;
                            </p>
                        )}
                                        {item.init ? (
                                            <p
                                                style={{
                                                    fontWeight: "bold",
                                                    color: "black",
                                                    marginBottom: "0px",
                                                }}
                                            >
                                                {item.init}
                                            </p>
                                        ) : (
                                            <p
                                                style={{
                                                    fontWeight: "bold",
                                                    color: "black",
                                                    marginBottom: "0px",
                                                }}
                                            >
                                                {item.po}
                                            </p>                                   )}
                                    </div>
                                         {item.rs ? (
                                <p
                                 style={{
                                        fontWeight: "bold",
                                        color: "#879497",
                                        textAlign: "left",
                                }}
                                >
                                 Ragione Sociale:{" "}
                                            <span
                                                style={{
                                                    fontWeight: "bold",
                                                    color: "black",
                                                }}
                                            >
                                                {item.rs}
                                            </span>
                            </p>
                        ) : (
                            <></>
                        )}                            


                                    <p
                                        style={{
                                            fontWeight: "bold",
                                            color: "#879497",
                                            textAlign: "left",
                                        }}
                                    >
                                        Localizzazione:{" "}
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                                color: "black",
                                            }}
                                        >
                                            {item.local}
                                        </span>
                                    </p>
                                    <p
                                        style={{
                                            fontWeight: "bold",
                                            color: "#879497",
                                            textAlign: "left",
                                        }}
                                    >
                                        Importo proposto:{" "}
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                                color: "black",
                                            }}
                                        >
                                            {item.price}
                                        </span>
                                    </p>
                                </div>
                            </div>

                            <div
                                style={{
                                    color: "#879497",
                                    display: "flex",
                                    alignItems: "center",
                                    height: "100%",
                                }}
                            >
                                <div
                                    style={{
                                        paddingRight: "25px",
                                        color: "#879497",
                                        display: "flex",
                                    }}
                                >
                                   
                                   <KeyboardArrowRightIcon sx={{ color: '#879497' }} fontSize="large"/> 
                                </div>
                            </div>
                        </div>
                    </div>

                    {item.table.length ? (
                        <p
                              style={{
                                    marginTop: "0px",
                                    //fontWeight: "bold",
                                    color: "#879497",
                                    textAlign: "left",
                                    paddingLeft: "50px",
                                }}
                        >
                            Cosi Ripartito:
                        </p>
                    ) : (
                        <></>
                    )}
                     {item.table.map((itemlist, index) =>
                            index % 2 === 0 ? (
                                <div
                                    style={{
                                        textAlign: "left",
                                        paddingLeft: "50px",
                                        color: "#879497",
                                        //fontWeight: "bold",
                                        background: "#EFEFF6",
                                    }}
                                >
                                    <p
                                        style={{
                                            display: "inline-block",
                                            width: "20%",
                                        }}
                                    >
                                        {itemlist.title}
                                    </p>
                                    <p style={{ display: "inline-block",width: "25%" }}>
                                        {itemlist.content}
                                    </p>
                                    <p style={{ display: "inline-block", width: "25%" }}>
                                        {itemlist.content2}
                                    </p>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        textAlign: "left",
                                        paddingLeft: "50px",
                                        color: "#879497",
                                        //fontWeight: "bold",
                                    }}
                                >
                                    <p
                                        style={{
                                            display: "inline-block",
                                            width: "20%",
                                        }}
                                    >
                                        {itemlist.title}
                                    </p>
                                      <p style={{ display: "inline-block",width: "25%" }}>
                                        {itemlist.content}
                                    </p>
                                    <p style={{ display: "inline-block", width: "25%" }}>
                                        {itemlist.content2}
                                    </p>
                                </div>
                            )
                        )}
                    </div>
            ))}
        </div>
    );
}
