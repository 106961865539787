import React, { useState, useEffect, useContext } from "react";

import RouterContext from "../../context/RouterContext";
import "../../App.css";
import {updateSedute} from "./Utils";
import {properties} from "../../utils/properties";

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { useNavigate } from "react-router-dom";

export default function MeetingList() {
    var dateStyle = {
        display: "flex",
        paddingLeft: "40px",        
    };

    const [meetingData, setMeetingData] = useState([]);    
    const { pageRouter, setPageRouter } = useContext(RouterContext);    
    const navigate = useNavigate();

    const updateUI = function(jsonData) {      
        console.log("UPDATEUI-LV");
        var entireMeeting = Object.entries(jsonData);                    
        var orderMeeting = [];
        for (var i = entireMeeting.length - 1; i >= 0; i--) {
            var eachMeeting = {
                id: entireMeeting[i][0],
                order: entireMeeting[i][1]["order"],                
                date: entireMeeting[i][1]["dataSeduta"],                
                };        
            orderMeeting.push(eachMeeting);
        }        
        orderMeeting.sort((a, b) => a.order > b.order ? 1 : -1)
        setMeetingData(orderMeeting);
    }

    const doPostSedute2 = function() {
        console.log("doing sedute post LV");
        var xhr2 = new XMLHttpRequest();                
        //xhr2.open('POST', properties.productionUrl+'/rest/checkSeduteJson',true);
        xhr2.open('POST', properties.productionUrl+'/php_demo/core/rest/checkSeduteJsonCGPersone.php',true);
        xhr2.withCredentials = true;
        xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
        xhr2.onload = function (e) {      
            if (xhr2.readyState === 4) {
                if (xhr2.status === 200) {                              
                    //console.log("OK DOPOSTSEDUTE",xhr2);
                    var responseText = xhr2.responseText;                    
                    var obj=JSON.parse(responseText);
                    //console.log("OK93",obj);          
                    if(obj.status==="204" ){ 
                        console.log("OK DOPOSTSEDUTE 204",xhr2);
                        const jsonListSedute = JSON.parse(localStorage.getItem("listSeduteCGPersone"));
                        updateUI(jsonListSedute);
                    }              
                    else{
                        //status 201
                        console.log("OK DOPOSTSEDUTE 201",xhr2);                            
                        try{
                            localStorage.setItem("listSeduteCGPersone", obj.content);
                            localStorage.setItem("listSeduteVersionCGPersone", obj.date);                                                                                                                                          
                        }
                        catch(error) {
                            console.log("LOCALSTORAGE ISSUE", error);
                        }  
                        
                        const jsonListSedute = JSON.parse(obj.content);
                        updateUI(jsonListSedute);
                    }
                } 
                else {
                    //console.error("Error 1", xhr.statusText);          
                    alert("Errore recuperando la seduta");  
                }
            }
        };

        xhr2.onerror = function (e) {
            console.error("Error 22", xhr2.statusText);
        };
        
        let urlEncodedData = null; //"idf=" + meetingId;
        if (localStorage.listSeduteCGPersone!=='' &&localStorage.listSeduteCGPersone!==undefined){            
            //console.log( "QUINDI00");
            const currentData=localStorage.getItem("listSeduteVersionCGPersone");        
            urlEncodedData = "date=" + currentData;                    
        }            
        xhr2.send(urlEncodedData);
    }

    useEffect(() => {     
        console.log("USEEFFECT CV");
        console.log("Arrivo qui", document.referrer);   
        if(localStorage.getItem("new_ui")==="true"){
                navigate("/calendar_cg_persone");    
        }          
                    
        if(!window.cordova){
            
        //CHECK AUTHENTICATION
            var xhr = new XMLHttpRequest();                
            //xhr.open('POST', properties.productionUrl+'/rest/ping',true);
            xhr.open('POST', properties.productionUrl+'/php_demo/core/rest/ping.php',true);
            xhr.withCredentials = true;
            xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    
            xhr.onload = function (e) {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {                                      
                        console.log("OK",xhr);                                                
                        updateSedute("CGPersone",doPostSedute2, updateUI);
                } else {
                    console.error("Error 1", xhr);     
                    localStorage.clear();               
                    console.log("QWERTY 1",localStorage.getItem("name"));
              
                    navigate("/login");
                }
            }
            };
    
            xhr.onerror = function (e) {
                console.error("Error 2", xhr);                                
                localStorage.clear();       
                console.log("QWERTY 2",localStorage.getItem("name"));                
                navigate("/login");
            };
             
            let urlEncodedData = null; 
            xhr.send(urlEncodedData);
        //CHECK AUTHENTICATION
        }
        
        if (window.cordova)
        {
           updateSedute("CGPersone",doPostSedute2, updateUI);   
        }
                         
    }, []);



    const topicList = (id) => {
        console.log("called topic list LV",id);        
        let aa="/cg_persone/"+id;        
        navigate(aa);
    };

    return (
        <div className="content">
       
        {meetingData.map((item, index) => (
                <div className="scheda-item" style={{ borderRightColor: "#F0B323" }} > 
                <div
                    className="common-list"
                    key={index}
                    style={{
                                cursor: "pointer",
                            }}
                    onClick={() => topicList(item.id)}
                >
                    <div style={dateStyle}>
                    <p className= "textBlackSedute">
                        Seduta del {item.date}
                        </p>
                    </div>
                    <div
                        style={{
                            paddingRight: "25px",
                            color: "#879497",
                            display: "flex",
                        }}
                    >
                        {index === 0 ? (
                            <div
                                style={{
                                    //background: "#ff5a60",
                                    background: "#F0B323",
                                    marginRight: "40px",
                                    padding: "8px 10px",
                                    color: "white",
                                    borderRadius: "30px",
                                    fontSize: "13px",
                                }}
                            >
                                Nuova
                            </div>
                        ) : (
                            <></>
                        )}

                        <KeyboardArrowRightIcon sx={{ color: '#879497' }} fontSize="large"/>
                        
                    </div>
                </div>
                </div>
            ))}
        </div>
    );
}
