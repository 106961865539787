import React, { useState, useEffect, useContext } from "react";
//import { useHistory } from "react-router-dom";
//import { makeStyles } from '@material-ui/styles';
import RouterContext from "../../context/RouterContext";
import "../../App.css";
import {updateSedute} from "./Utils";
//import styles from "assets/jss/views/sessionManagerStyles"
import axios from 'axios';

import PublishIcon from '@mui/icons-material/Publish';
//import UpdateIcon from '@material-ui/icons/Update';

import DoneIcon from '@mui/icons-material/Done';

import {ThreeDots} from 'react-loader-spinner';

import {properties} from "../../utils/properties";

import { IconButton } from '@mui/material';

import SweetAlert from "react-bootstrap-sweetalert";
import { useNavigate } from "react-router-dom";
import '../../assets/bootstrap-custom.css';


export default function VerbaliApprovati() {
        
    const [meetingData, setMeetingData] = useState([]);
    const { pageRouter, setPageRouter } = useContext(RouterContext);
    const [loadingGen, setLoadingGen] = React.useState(false);   
    const [alert, setAlert] = React.useState(null)
    //const history = useHistory();
    const navigate = useNavigate();
    const showAlert = () => {
        console.log("showing alert");    
        setAlert(
          <SweetAlert
          warning
          style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
          title={"Impossibile caricare il file selezionato. Verificare che si tratti di un file in formato pdf e riprovare. Se il problema si riproponesse, contattare l'amministratore. " }
          text="Attenzione"
          //onConfirm={() => { history.go(-1) }}
          onConfirm={() => { setAlert(null) }}
          //onCancel={() => { history.go(-1) }}
          //onCancel={() => { navigate(-1) }}
          confirmBtnCssClass="custom-btn custom-btn-success" 
          cancelBtnCssClass="custom-btn custom-btn-cancel"
          //confirmBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText="Indietro"
          //cancelBtnText="No"
          //showCancel
        >
        </SweetAlert>
        );
    };
    


    const onFileChange= (e,item ) => {   
        console.log("ew3",e); 
        console.log("ew4",item);
        console.log("ONFILE CHANGE INIT ",loadingGen);  
        if (!loadingGen) { setLoadingGen(true);} 
        console.log("ONFILE CHANGE ",loadingGen);
        e.stopPropagation(); 
        //let endpoint = properties.productionUrl+"/php/core/rest/updateVerbali.php";           
        let endpoint = properties.productionUrl+"/php_demo/core/rest/updateVerbali.php";  
        let file=e.target.files[0];
        
        var bodyFormData=new FormData();            
        bodyFormData.append('UploadedFile', file);                
        bodyFormData.append('idf', item.id);
        console.log("PARAM",bodyFormData);
        axios({
            method: 'post',
            url: endpoint,
            data: bodyFormData, 
            withCredentials: true, 
            headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(function (response) {    
            console.log("ONFILE CHANGE THEN ",loadingGen);                    
            setLoadingGen(false);
            console.log("ONFILE CHANGE THEN ",loadingGen);                    
            console.log(response);
            e.target.files=null;
            e.target.value=null;
            doPostSedute2();
        })
        .catch(function (response) {        
            console.log("ONFILE CHANGE CATCH ",loadingGen);  
            console.log(response);
            setLoadingGen(false);
            console.log("ONFILE CHANGE CATCH ",loadingGen);  
            showAlert();
        }); 
    
    };
    


    const updateUI = function(jsonData) {      
        var entireMeeting = Object.entries(jsonData);                    
        var orderMeeting = [];
        for (var i = entireMeeting.length - 1; i >= 0; i--) {
            var eachMeeting = {
                id: entireMeeting[i][0],
                order: entireMeeting[i][1]["order"],                
                date: entireMeeting[i][1]["dataSeduta"],                
                };        
            orderMeeting.push(eachMeeting);
        }        
        orderMeeting.sort((a, b) => a.order > b.order ? 1 : -1)
        //setMeetingData(orderMeeting);
        doPostVerbali(orderMeeting);

    }


    const doPostVerbali = function(listaSedute) {
        console.log("doing sedute post");
        var xhr2 = new XMLHttpRequest();                
        
        //xhr2.open('POST', properties.productionUrl+'/php/core/rest/test01.php',true);
        xhr2.open('POST', properties.productionUrl+'/php_demo/core/rest/test01.php',true);
        xhr2.withCredentials = true;
        xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
        xhr2.onload = function (e) {
            if(xhr2.readyState === 4) {
                if (xhr2.status === 200) {                              
                    console.log("OK92",xhr2);
                    var responseText = xhr2.responseText;                                                            
                    if(responseText!="\n\nnull"){
                    var obj=JSON.parse(responseText);
                    console.log("ho tutte le info ON DOPOSTVERBALI",obj);
                    console.log("LISTA SEDUTE",listaSedute);
                    console.log("FILES",obj);
                    //updateUI(obj);   
                    
                    //DA QUI
                    var alfa=obj.split(/\r?\n/);
                    for(var d=1; d< alfa.length;d++) {                        
                        var beta= alfa[d].split(' ');
                        beta=beta.filter(String);
                        var val="";
                        for (var r=7;r < beta.length;r++){
                            val=val+" "+beta[r];
                        }
                        val=val.trim();
                        var found=false;
                        
                        if(beta[7]!= undefined) {
                            if(!beta[7].includes("approvazione")){
                                //var tmp22=beta[7].substring(0,4).c;
                                var tmp22= beta[7].substring(9,11).concat(beta[7].substring(7,9)).concat(beta[7].substring(3,7));                                                    
                                listaSedute.forEach( entry => {
                                    console.log("COMPARISON", tmp22, entry.id);            
                                    if(tmp22.startsWith(entry.id)) {
                                        entry.uploaded= true;
                                        entry.lastUpdate= beta[5]+" "+beta[6];                                        
                                        console.log("PR",entry);
                                    }
                                });                                
                            }                                                                                              
                        } //if undefined                                                            
                    } //fine for                    
                    console.log("WEI",listaSedute);
                    setMeetingData(listaSedute);
                    //A QUI

                    }
                } 
                else {                    
                    alert("Errore recuperando la lista dei verbali");  
                }
            }
        };

        xhr2.onerror = function (e) {
            console.error("Error 32", xhr2.statusText);
        };
               
        let urlEncodedData = "idv=all";                 
        xhr2.send(urlEncodedData);
    }


    const doPostSedute2 = function() {
        //console.log("doing sedute post");
        if (!loadingGen) { setLoadingGen(true);} 
        var xhr2 = new XMLHttpRequest();                
        //xhr2.open('POST', properties.productionUrl+'/rest/checkSeduteJson',true);
        xhr2.open('POST', properties.productionUrl+'/php_demo/core/rest/checkSeduteJson.php',true);
        xhr2.withCredentials = true;
        xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
        xhr2.onload = function (e) {      
            if (xhr2.readyState === 4) {
                if (xhr2.status === 200) {                              
                    //console.log("OK DOPOSTSEDUTE",xhr2);
                    var responseText = xhr2.responseText;                    
                    var obj=JSON.parse(responseText);
                    //console.log("OK93",obj);          
                    if(obj.status==="204" ){ 
                        console.log("OK DOPOSTSEDUTE 204",xhr2);
                        const jsonListSedute = JSON.parse(localStorage.getItem("listSedute"));
                        updateUI(jsonListSedute);
                    }              
                    else{
                        //status 201
                        console.log("OK DOPOSTSEDUTE 201",xhr2);    
                        
                        try{
                            localStorage.setItem("listSeduteVersion", obj.date);          
                            localStorage.setItem("listSedute", obj.content);                                                                                                                                                          
                        }
                        catch(error) {
                            console.log("LOCALSTORAGE ISSUE", error);
                        }   
                        
                        const jsonListSedute = JSON.parse(obj.content);
                        updateUI(jsonListSedute);
                    }
                    setLoadingGen(false); 
                } 
                else {
                    //console.error("Error 1", xhr.statusText);          
                    setLoadingGen(false);
                    alert("Errore recuperando la seduta");  
                }
            }
        };

        xhr2.onerror = function (e) {
            setLoadingGen(false);
            console.error("Error 22", xhr2.statusText);
        };
        
        let urlEncodedData = null; //"idf=" + meetingId;
        if (localStorage.listSedute!=='' &&localStorage.listSedute!==undefined){            
            //console.log( "QUINDI00");
            const currentData=localStorage.getItem("listSeduteVersion");        
            urlEncodedData = "date=" + currentData;                    
        }            
        xhr2.send(urlEncodedData);
    }

    useEffect(() => {        

        if(!window.cordova){
            
        //CHECK AUTHENTICATION
            var xhr = new XMLHttpRequest();                
            //xhr.open('POST', properties.productionUrl+'/rest/ping',true);
            xhr.open('POST', properties.productionUrl+'/php_demo/core/rest/ping.php',true);
            xhr.withCredentials = true;
            xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    
            xhr.onload = function (e) {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {                                      
                        console.log("OK",xhr);                                                
                        updateSedute("Comitato",doPostSedute2, updateUI);
                } else {
                    console.error("Error 1", xhr);     
                    localStorage.clear();               
                    //window.localStorage.clear();
                    console.log("QWERTY 1",localStorage.getItem("name"));                    
                    navigate("/login");  
                }
            }
            };
    
            xhr.onerror = function (e) {
                console.error("Error 2", xhr);                                
                //window.localStorage.clear();
                localStorage.clear();       
                console.log("QWERTY 2",localStorage.getItem("name"));
                
                    navigate("/login");  
            };
             
            let urlEncodedData = null; 
            xhr.send(urlEncodedData);
        //CHECK AUTHENTICATION
        }
                     
    }, []);

    const topicList = (item) => {
        //console.log("CLICK", item.id);
        if(item.uploaded){ //E' già stato caricato il file e posso aprirlo
            if (item.id.length==8){
                var x=item.id;
                var url= "CdG"+x.substring(4,8)+x.substring(2,4)+x.substring(0,2)+".pdf";
                url=properties.productionUrl+"/fileManager/files/verbali/"+url;
                console.log(url);
                window.open(url);
            }             
        }      
    };

    return (
        <div>
        {loadingGen ? (
            <div style={{ width:"25%",   display: "flex", 
            marginLeft: "auto", marginRight: "auto" }}> 
             <ThreeDots color="#30720f" height='350' width='150'/>
             </div> 
        ):(
        <div className="content">
            {meetingData.map((item, index) => (
                <div
                    className="common-list"
                    key={index}
                    style={{
                                cursor: "pointer",
                            }}
                    onClick={() => topicList(item)}
                >
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", paddingLeft: "0px",paddingBottom:"0px", }} >                    
                    <div style={{paddingLeft: "20px", textAlign: "left", marginBottom:"5px"}} > 
                        <div style={{display: "flex", paddingLeft: "0px"}}>                            
                            <p className= "textDataSedute" style={{ marginBottom: "8px",}}>
                                Seduta del {item.date}
                            </p>
                        </div>
                        {item.uploaded  ? (
                        <div style={{ paddingLeft: "15px",paddingTop: "5px", paddingBottom:"10px",paddingRight:"20px", margin:"0px"}}>  
                                <p className= "textGreySedute" style={{ margin: "0px",}}>
                                    Caricato il {item.lastUpdate}                                    
                                </p>
                                </div>    
                        ):( 
                            <div style={{ paddingLeft: "15px",paddingTop: "5px", paddingBottom:"10px",paddingRight:"20px", margin:"0px"}}>  
                            <p className= "textGreySedute" style={{ margin: "0px",}}>
                                Verbale non ancora caricato                                
                            </p>
                            </div>    
                        )
                        }                        
                    </div> 
                </div>  
                        
                        <div style={{ paddingRight: "0px", color: "#ff0000",display: "flex",}} >         
                        {item.uploaded  ? (
                                <div
                                    style={{                                    
                                        background: "#a2ee7d",
                                        marginRight: "10px",
                                        //padding: "10px 8px",
                                        padding: "15px 8px",
                                        color: "black",                                    
                                        borderRadius: "5px",
                                        fontSize: "13px",
                                    }}
                                >
                                    Caricato
                                </div>
                            ) : (
                                <></>
                            )}

                        {!item.uploaded  ? (
                            <div>
                            <input id={"file-upload"+index} type="file" onChange={(event)=> onFileChange(event,item)} style={{ display: "none" }} />    
                            <label htmlFor={"file-upload"+index}>                     
                                <IconButton color="primary" aria-label="upload" component="span" style={{ color: "black", background: "#FFFFFF",  }}  >
                                    <PublishIcon />                        
                                </IconButton>                                                                       
                            </label>
                            </div>
                        ) : (  
                            <div>  
                            <IconButton color="primary" aria-label="upload" component="span" style={{ color: "black", background: "#FFFFFF",  }}  >
                                    <DoneIcon />                        
                                </IconButton>                                             
                            </div>    
                        )}
                        </div>
                    </div>                
            ))}
        </div>
    )}
       {alert}     
    </div>     
    );
}
