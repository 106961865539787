import React, { createContext, useState, useContext } from 'react';

const IndexContext = createContext();

export const IndexProvider = ({ children }) => {
  const [selectedIndex, setSelectedIndex] = useState(-1);

  return (
    <IndexContext.Provider value={{ selectedIndex, setSelectedIndex }}>
      {children}
    </IndexContext.Provider>
  );
};

export const useIndexContext = () => useContext(IndexContext);