import React, { useState } from 'react';
import Header from "./components/layout/Header";
import LoginTab from "./components/meeting/LoginTab";
import ModifyTab from "./components/meeting/ModifyTab";
import ResetTab from "./components/meeting/ResetTab";
import ResetTabStage2 from "./components/meeting/ResetTabStage2";
import LastHandler from "./components/meeting/LastHandler";
import Error from "./components/meeting/Error";
import Rigenerator from "./components/meeting/Rigenerator";
import UpdateAttachments from "./components/meeting/UpdateAttachments";
import UpdateAmbiti from "./components/meeting/UpdateAmbiti";
import SedutaRaw from "./components/meeting/SedutaRaw";
import FreezeHandler from "./components/meeting/FreezeHandler";
import PubHandler from "./components/meeting/PubHandler";
import AttachmentsDownloader from "./components/meeting/AttachmentsDownloader";

import MeetingList from "./components/meeting/MeetingList";
import TopicList from "./components/meeting/TopicList";
import TopicSubList from "./components/meeting/TopicSubList";
import TopicDeepList from "./components/meeting/TopicDeepList";
import SpecificArgument from "./components/meeting/SpecificArgument";
import SpecificCard from "./components/meeting/SpecificCard";
import SpecificExtra from "./components/meeting/SpecificExtra";
import SpecificSubTopic from "./components/meeting/SpecificSubTopic";
import ScoreBoard from "./components/meeting/ScoreBoard";
import FinalEnd from "./components/meeting/FinalEnd";
import CalendarView from "./components/meeting/CalendarView";
import TopicList6 from "./components/meeting/TopicList6";
import TopicList3 from "./components/meeting/TopicList3";
import PreviewScheda from "./components/meeting/PreviewScheda";
import DettaglioCard from "./components/meeting/DettaglioCard";
import Card from "./components/meeting/Card";
import ScoreBoardCard from "./components/meeting/ScoreBoardCard";

import Search from "./components/meeting/Search";
import Init from "./components/meeting/Init";
import RouterContext from "./context/RouterContext";

import MeetingListConsiglio from "./components/meeting/MeetingListConsiglio";
import MeetingListCollegio from "./components/meeting/MeetingListCollegio";

import VerbaliApprovati from "./components/meeting/VerbaliApprovati";
import Presence from "./components/meeting/Presence";
import SessionManager from "./components/TreeViewSection/SessionManager";
import SessionManager2 from "./components/TreeViewSection/SessionManager2";
import SessionManager3 from "./components/TreeViewSection/SessionManager3";
import SessionManager101 from "./components/TreeViewSection/SessionManager101";
import SessionManager102 from "./components/TreeViewSection/SessionManager102";
import SessionManager103 from "./components/TreeViewSection/SessionManager103";
import SessionManager104 from "./components/TreeViewSection/SessionManager104";
import SessionManager105 from "./components/TreeViewSection/SessionManager105";

import FileManager from "./components/TreeViewSection/FileManager";
import FileManager2 from "./components/TreeViewSection/FileManager2";
import FileManager3 from "./components/TreeViewSection/FileManager3";
import FileManager101 from "./components/TreeViewSection/FileManager101";
import FileManager102 from "./components/TreeViewSection/FileManager102";
import FileManager103 from "./components/TreeViewSection/FileManager103";
import FileManager104 from "./components/TreeViewSection/FileManager104";
import FileManager105 from "./components/TreeViewSection/FileManager105";

import MeetingListCGPianeta from "./components/meeting/MeetingListCGPianeta";
import MeetingListCGCultura from "./components/meeting/MeetingListCGCultura";
import MeetingListCGPersone from "./components/meeting/MeetingListCGPersone";
import MeetingListCGFinanza from "./components/meeting/MeetingListCGFinanza";
import MeetingListCGStatuto from "./components/meeting/MeetingListCGStatuto";

import TopicListConsiglio from "./components/meeting/TopicListConsiglio";
import TopicListCollegio from "./components/meeting/TopicListCollegio";
import TopicListCGPianeta from "./components/meeting/TopicListCGPianeta";
import TopicListCGCultura from "./components/meeting/TopicListCGCultura";
import TopicListCGPersone from "./components/meeting/TopicListCGPersone";
import TopicListCGFinanza from "./components/meeting/TopicListCGFinanza";
import TopicListCGStatuto from "./components/meeting/TopicListCGStatuto";

import TopicSubListConsiglio from "./components/meeting/TopicSubListConsiglio";
import TopicSubListCollegio from "./components/meeting/TopicSubListCollegio";
import TopicSubListCGPianeta from "./components/meeting/TopicSubListCGPianeta";
import TopicSubListCGCultura from "./components/meeting/TopicSubListCGCultura";
import TopicSubListCGPersone from "./components/meeting/TopicSubListCGPersone";
import TopicSubListCGFinanza from "./components/meeting/TopicSubListCGFinanza";
import TopicSubListCGStatuto from "./components/meeting/TopicSubListCGStatuto";

import TopicDeepListConsiglio from "./components/meeting/TopicDeepListConsiglio";
import TopicDeepListCollegio from "./components/meeting/TopicDeepListCollegio";
import TopicDeepListCGPianeta from "./components/meeting/TopicDeepListCGPianeta";
import TopicDeepListCGCultura from "./components/meeting/TopicDeepListCGCultura";
import TopicDeepListCGPersone from "./components/meeting/TopicDeepListCGPersone";
import TopicDeepListCGFinanza from "./components/meeting/TopicDeepListCGFinanza";
import TopicDeepListCGStatuto from "./components/meeting/TopicDeepListCGStatuto";

import CalendarViewConsiglio from "./components/meeting/CalendarViewConsiglio";
import CalendarViewCollegio from "./components/meeting/CalendarViewCollegio";


import CalendarViewCGPianeta from "./components/meeting/CalendarViewCGPianeta";
import CalendarViewCGCultura from "./components/meeting/CalendarViewCGCultura";
import CalendarViewCGPersone from "./components/meeting/CalendarViewCGPersone";
import CalendarViewCGFinanza from "./components/meeting/CalendarViewCGFinanza";
import CalendarViewCGStatuto from "./components/meeting/CalendarViewCGStatuto";

import MeetingListAll from "./components/meeting/MeetingListAll";
import CalendarViewAll from "./components/meeting/CalendarViewAll";

import Presence2 from "./components/meeting/Presence2";
import Presence3 from "./components/meeting/Presence3";

import VerbaliCollegioApprovati from "./components/meeting/VerbaliCollegioApprovati";
import VerbaliConsiglioApprovati from "./components/meeting/VerbaliConsiglioApprovati";

import SearchV from "./components/meeting/SearchV";
import SearchP from "./components/meeting/SearchP";
import SearchConsiglio from "./components/meeting/SearchConsiglio";
import SearchCollegio from "./components/meeting/SearchCollegio";

import FileManagerDev from "./components/meeting/FileManagerDev"
import FileManagerView from "./components/meeting/FileManagerView"

import InitIndice from "./components/indice/InitIndice";
import CalendarIndice from "./components/indice/CalendarIndice";
import Indice from './components/indice/Indice';


import { BrowserRouter, MemoryRouter, Route, Routes, useLocation } from "react-router-dom";

import './App.css';


const App = () => {
  //console.log("loc",window);
  const [pageRouter, setPageRouter] = useState({
        router: "",
        homecolor: "",
        settingcolor: "",
        homebordercolor: "",
        settingbordercolor: "",
        hometitlecolor: "",
        settingtitlecolor: "",        
    });


  return (
    <BrowserRouter>
      <RouterContext.Provider value={{ pageRouter, setPageRouter }}>
          <div className="App">  
            <Header />        
            <Routes>  
              <Route exact path="/login" element={<LoginTab/>} />  
              <Route exact path="/changePassword" element={<ModifyTab/>} />  
              <Route exact path="/modify" element={<ModifyTab/>} />
              <Route exact path="/reset" element={<ResetTab/>} />
              <Route exact path="/reset-password" element={<ResetTabStage2/>} />  

              <Route exact path="/error/:errorCode" element={<Error/>} />        
              <Route exact path="/error" element={<Error/>} />

              <Route exact path="/rigenera/:idDossier" element={<Rigenerator/>} />    
            
              <Route exact path="/fascicoli" element={<MeetingList/>} />
              <Route exact path="/fascicoli/" element={<MeetingList/>} />                                      
              <Route exact path="/fascicoli/:idDossier" element={<TopicList/>} />            
              <Route exact path="/fascicoli/:idDossier/" element={<TopicList/>} />     
              <Route exact path="/init"  element={<Init/>} />

              <Route exact path="/fascicoli/last/summary" element={<LastHandler/>} />
              <Route exact path="/fascicoli/last" element={<LastHandler/>} />
              <Route exact path="/updating/last" element={<LastHandler/>} />  
              <Route exact path="/updatingAmbiti/last" element={<LastHandler/>} />
              <Route exact path="/raw/last" element={<LastHandler/>} />

              <Route exact path="/fascicoli/:idDossier/:indexSector" element={<TopicSubList/>} />
              <Route path="/fascicoli/:idDossier/:indexSector/:indexSubSector" element={<TopicDeepList/>} />
              <Route exact path="/fascicoli/:idDossier/proposteintervento/:id" element={<SpecificArgument/>} />
              <Route path="/fascicoli/:idDossier/proposteintervento/missioni/:id/:type/:item_id" element={<SpecificExtra/>} />
              <Route path="/fascicoli/:idDossier/proposteintervento/missioni/:id" element={<SpecificSubTopic/>} />
              <Route exact path="/fascicoli/:idDossier/proposteintervento/schede/:id" element={<SpecificCard/>} /> 
              <Route path="/fascicoli/:idDossier/proposteintervento/schede/:id/:refname" element={<FinalEnd/>} />
              <Route path="/fascicoli/:idDossier/proposteintervento/schede/:id/scoreboard" element={<ScoreBoard/>} />
              <Route exact path="/calendar" element={<CalendarView/>} />  
              <Route exact path="/calendar/" element={<CalendarView/>} /> 
              <Route exact path="/fascicoli/:idDossier/summary" element={<TopicList6/>} />
              <Route exact path="/fascicoli/:idDossier/summary/" element={<TopicList6/>} />
              <Route exact path="/fascicoli/:idDossier/preview/:odgkey" element={<TopicList3/>} />   
              <Route exact path="/search/#/" element={<Search/>} />
              <Route exact path="/search/#" element={<Search/>} />
              <Route exact path="/search" element={<Search/>} />
              <Route exact path="/#/search" element={<Search/>} />
              <Route exact path="/editor" element={<PreviewScheda/>} />
              <Route path="/schede/:idScheda/:refname" element={<DettaglioCard/>} />
              <Route exact path="/schede/:idScheda" element={<Card/>} />
              <Route path="/schede/:idScheda/scoreboard" element={<ScoreBoardCard/>} />
              <Route exact path="/updating/:idDossier" element={<UpdateAttachments/>} />
              <Route exact path="/updatingAmbiti/:idDossier" element={<UpdateAmbiti/>} />
              <Route exact path="/raw/:idDossier" element={<SedutaRaw/>} /> 
              <Route exact path="/blocca" element={<FreezeHandler/>} />
              <Route exact path="/consolida" element={<PubHandler/>} />    
              <Route exact path="/scaricaAllegati/:idDossier" element={<AttachmentsDownloader/>} />  

              <Route exact path="/verbali" element={<VerbaliApprovati/>} />
              <Route exact path="/verbaliConsiglio" element={<VerbaliConsiglioApprovati/>} />
              <Route exact path="/verbaliCollegio" element={<VerbaliCollegioApprovati/>} />

              <Route exact  path="/presenze3/:idDossier" element={<Presence3/>} />  
              <Route exact  path="/presenze2/:idDossier" element={<Presence2/>} />  
              <Route exact path="/presenze/:idDossier" element={<Presence/>} />   

              <Route exact path="/presenze3" element={<Presence3/>} />
              <Route exact path="/presenze2" element={<Presence2/>} /> 
              <Route exact path="/presenze" element={<Presence/>} />

              <Route exact path="/folders2/:idMeeting" element={<SessionManager2/>} />
              <Route path="/folders2" element={<SessionManager2/>} />
              <Route path="/issueManager2" element={<SessionManager2/>} />

              <Route exact path="/folders3/:idMeeting" element={<SessionManager3/>} />
              <Route path="/folders3" element={<SessionManager3/>} />
              <Route path="/issueManager3" element={<SessionManager3/>} />

              <Route exact path="/folders101/:idMeeting" element={<SessionManager101/>} />
              <Route path="/folders101" element={<SessionManager101/>} />
              <Route path="/issueManager101" element={<SessionManager101/>} />  

              <Route exact path="/folders102/:idMeeting" element={<SessionManager102/>} />
              <Route path="/folders102" element={<SessionManager102/>} />
              <Route path="/issueManager102" element={<SessionManager102/>} />  

              <Route exact path="/folders103/:idMeeting" element={<SessionManager103/>} />
              <Route path="/folders103" element={<SessionManager103/>} />
              <Route path="/issueManager103" element={<SessionManager103/>} />  

              <Route exact path="/folders104/:idMeeting" element={<SessionManager104/>} />
              <Route path="/folders104" element={<SessionManager104/>} />
              <Route path="/issueManager104" element={<SessionManager104/>} />  

              <Route exact path="/folders105/:idMeeting" element={<SessionManager105/>} />
              <Route path="/folders105" element={<SessionManager105/>} />
              <Route path="/issueManager105" element={<SessionManager105/>} />  

              <Route exact path="/folders/:idMeeting" element={<SessionManager/>} />   
              <Route path="/folders" element={<SessionManager/>} />   
              <Route path="/issueManager" element={<SessionManager/>} />

              <Route exact path="/files2/:idMeeting" element={<FileManager2/>} />
              <Route path="/files2" element={<FileManager2/>} />
              <Route path="/fileMgr2" element={<FileManager2/>} />

              <Route exact path="/files3/:idMeeting" element={<FileManager3/>} />
              <Route path="/files3" element={<FileManager3/>} />
              <Route path="/fileMgr3" element={<FileManager3/>} />

              <Route exact path="/files101/:idMeeting" element={<FileManager101/>} />
              <Route path="/files101" element={<FileManager101/>} />
              <Route path="/fileMgr101" element={<FileManager101/>} />

              <Route exact path="/files102/:idMeeting" element={<FileManager102/>} />
              <Route path="/files102" element={<FileManager102/>} />
              <Route path="/fileMgr102" element={<FileManager102/>} />

              <Route exact path="/files103/:idMeeting" element={<FileManager103/>} />
              <Route path="/files103" element={<FileManager103/>} />
              <Route path="/fileMgr103" element={<FileManager103/>} />

              <Route exact path="/files104/:idMeeting" element={<FileManager104/>} />
              <Route path="/files104" element={<FileManager104/>} />
              <Route path="/fileMgr104" element={<FileManager104/>} />

              <Route exact path="/files105/:idMeeting" element={<FileManager105/>} />
              <Route path="/files105" element={<FileManager105/>} />
              <Route path="/fileMgr105" element={<FileManager105/>} />

              <Route exact path="/files/:idMeeting" element={<FileManager/>} />     
              <Route path="/files" element={<FileManager/>} />     
              <Route path="/fileMgr" element={<FileManager/>} />     

              <Route exact path="/consiglio" element={<MeetingListConsiglio/>} />
              <Route exact path="/consiglio/" element={<MeetingListConsiglio/>} />

              <Route exact path="/collegio" element={<MeetingListCollegio/>} />
              <Route exact path="/collegio/" element={<MeetingListCollegio/>} />

              <Route exact path="/cg_pianeta" element={<MeetingListCGPianeta/>} />
              <Route exact path="/cg_pianeta/" element={<MeetingListCGPianeta/>} />
            
              <Route exact path="/cg_persone" element={<MeetingListCGPersone/>} />
              <Route exact path="/cg_persone/" element={<MeetingListCGPersone/>} />

              <Route exact path="/cg_cultura" element={<MeetingListCGCultura/>} />
              <Route exact path="/cg_cultura/" element={<MeetingListCGCultura/>} />

              <Route exact path="/cg_finanza" element={<MeetingListCGFinanza/>} />
              <Route exact path="/cg_finanza/" element={<MeetingListCGFinanza/>} />

              <Route exact path="/cg_statuto" element={<MeetingListCGStatuto/>} />
              <Route exact path="/cg_statuto/" element={<MeetingListCGStatuto/>} />

              <Route exact path="/cg_pianeta/:idDossier" element={<TopicListCGPianeta/>} />    
              <Route exact path="/cg_cultura/:idDossier" element={<TopicListCGCultura/>} />       
              <Route exact path="/cg_persone/:idDossier" element={<TopicListCGPersone/>} />    
              <Route exact path="/cg_finanza/:idDossier" element={<TopicListCGFinanza/>} />
              <Route exact path="/cg_statuto/:idDossier" element={<TopicListCGStatuto/>} />        
              <Route exact path="/collegio/:idDossier" element={<TopicListCollegio/>} />     
              <Route exact path="/consiglio/:idDossier" element={<TopicListConsiglio/>} />   

              <Route exact path="/consiglio/:idDossier/:indexSector" element={<TopicSubListConsiglio/>} />
              <Route exact path="/collegio/:idDossier/:indexSector" element={<TopicSubListCollegio/>} />
              <Route exact path="/cg_pianeta/:idDossier/:indexSector" element={<TopicSubListCGPianeta/>} />
              <Route exact path="/cg_cultura/:idDossier/:indexSector" element={<TopicSubListCGCultura/>} />
              <Route exact path="/cg_persone/:idDossier/:indexSector" element={<TopicSubListCGPersone/>} />
              <Route exact path="/cg_finanza/:idDossier/:indexSector" element={<TopicSubListCGFinanza/>} />
              <Route exact path="/cg_statuto/:idDossier/:indexSector" element={<TopicSubListCGStatuto/>} />  

              <Route path="/consiglio/:idDossier/:indexSector/:indexSubSector" element={<TopicDeepListConsiglio/>} />
              <Route path="/collegio/:idDossier/:indexSector/:indexSubSector" element={<TopicDeepListCollegio/>} />
              <Route path="/cg_pianeta/:idDossier/:indexSector/:indexSubSector" element={<TopicDeepListCGPianeta/>} />
              <Route path="/cg_cultura/:idDossier/:indexSector/:indexSubSector" element={<TopicDeepListCGCultura/>} />
              <Route path="/cg_persone/:idDossier/:indexSector/:indexSubSector" element={<TopicDeepListCGPersone/>} />
              <Route path="/cg_finanza/:idDossier/:indexSector/:indexSubSector" element={<TopicDeepListCGFinanza/>} />
              <Route path="/cg_statuto/:idDossier/:indexSector/:indexSubSector" element={<TopicDeepListCGStatuto/>} />

              <Route exact path="/calendarConsiglio" element={<CalendarViewConsiglio/>} />  
              <Route exact path="/calendarConsiglio/" element={<CalendarViewConsiglio/>} />  

              <Route exact path="/calendarCollegio" element={<CalendarViewCollegio/>} />  
              <Route exact path="/calendarCollegio/" element={<CalendarViewCollegio/>} /> 

              <Route exact path="/calendar_cg_pianeta" element={<CalendarViewCGPianeta/>} />  
              <Route exact path="/calendar_cg_pianeta/" element={<CalendarViewCGPianeta/>} /> 
              <Route exact path="/calendar_cg_cultura" element={<CalendarViewCGCultura/>} />  
              <Route exact path="/calendar_cg_cultura/" element={<CalendarViewCGCultura/>} />  
              <Route exact path="/calendar_cg_persone" element={<CalendarViewCGPersone/>} />  
              <Route exact path="/calendar_cg_persone/" element={<CalendarViewCGPersone/>} /> 
              <Route exact path="/calendar_cg_finanza" element={<CalendarViewCGFinanza/>} /> 
              <Route exact path="/calendar_cg_finanza/" element={<CalendarViewCGFinanza/>} /> 
              <Route exact path="/calendar_cg_statuto" element={<CalendarViewCGStatuto/>} /> 
              <Route exact path="/calendar_cg_statuto/" element={<CalendarViewCGStatuto/>} />

              <Route exact path="/sedute" element={<MeetingListAll/>} />
              <Route exact path="/sedute/" element={<MeetingListAll/>} />   

              <Route exact path="/calendarAll" element={<CalendarViewAll/>} />  
              <Route exact path="/calendarAll/" element={<CalendarViewAll/>} />  

              <Route exact path="/searchV/#/" element={<SearchV/>} />
              <Route exact path="/searchV/#" element={<SearchV/>} />
              <Route exact path="/searchV" element={<SearchV/>} />
              <Route exact path="/#/searchV" element={<SearchV/>} />

              <Route exact path="/searchP/#/" element={<SearchP/>} />
              <Route exact path="/searchP/#" element={<SearchP/>} />
              <Route exact path="/searchP" element={<SearchP/>} />
              <Route exact path="/#/searchP" element={<SearchP/>} />

              <Route exact path="/searchConsiglio/#/" element={<SearchConsiglio/>} />
              <Route exact path="/searchConsiglio/#" element={<SearchConsiglio/>} />
              <Route exact path="/searchConsiglio" element={<SearchConsiglio/>} />
              <Route exact path="/#/searchConsiglio" element={<SearchConsiglio/>} />

              <Route exact path="/searchCollegio/#/" element={<SearchCollegio/>} />
              <Route exact path="/searchCollegio/#" element={<SearchCollegio/>} />
              <Route exact path="/searchCollegio" element={<SearchCollegio/>} />
              <Route exact path="/#/searchCollegio" element={<SearchCollegio/>} />

              <Route exact path="/repo" element={<FileManagerDev />} />
              <Route exact path="/explore" element={<FileManagerView />} />

              <Route exact path="/indice" element={<InitIndice/>} />             
              <Route exact path="/indice/calendar" element={<CalendarIndice />} />
              <Route path="/indice/" element={<InitIndice/>} replace/>
              <Route exact path="/indice/init" element={<InitIndice/>} />            
              <Route exact path="/indice/indici/:idSeduta" element={<Indice/>} />     

              <Route path="/" element={<Init/>} replace/>
            </Routes>
          </div>
      </RouterContext.Provider>
    </BrowserRouter>    
  );
}

export default App;
