import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import RouterContext from "../../context/RouterContext";
import {updateSeduta} from "./Utils";
import {properties} from "../../utils/properties";
import { useNavigate } from "react-router-dom";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export default function TopicList() {    
    const [topicList, setTopicList] = useState([]);
    const [keyTopicList, setKeyTopicList] = useState([]);    
    const navigate = useNavigate();
    let { idDossier } = useParams();    
    const { pageRouter, setPageRouter } = useContext(RouterContext);    
    const meetingId = idDossier ;
    
    const updateUI = function(meetingData) {            
      const topicList = Object.entries(meetingData)[6][1];
      const valueTopicList = Object.values(topicList);
      const keyTopicList = Object.keys(topicList);
      setTopicList(valueTopicList);
      setKeyTopicList(keyTopicList);   
    }

    const doPostSeduta2 = function() {
    console.log("doing sedute post");
    var xhr2 = new XMLHttpRequest();                
    xhr2.open('POST', properties.productionUrl+'/php_demo/core/rest/checkSedutaCGPianeta.php',true);
    //xhr2.open('POST', properties.productionUrl+'/rest/checkSeduta',true);
    xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
    xhr2.withCredentials = true;
    xhr2.onload = function (e) {      
      if (xhr2.readyState === 4) {
        if (xhr2.status === 200) {                              
            //console.log("OK92",xhr2);
            var responseText = xhr2.responseText;                    
            var obj=JSON.parse(responseText);
            //console.log("OK93",obj);          
            if(obj.status==="204" ){ 
                console.log("OK DOPOSTSEDUTE 204",xhr2);
                const MeetingInfo = JSON.parse(localStorage.getItem("meetingContentCGPianeta"));                        
                updateUI(MeetingInfo);
            }              
            else{
                //status 201
                //console.log("OK95",obj.date);
                console.log("OK DOPOSTSEDUTE 201",xhr2);

                try{
                    localStorage.setItem("meetingContentCGPianeta", obj.content);
                    localStorage.setItem("meetingCGPianeta", meetingId);
                    localStorage.setItem("meetingVersionCGPianeta", obj.date);                                                                                                                                                             
                }
                catch(error) {
                    console.log("LOCALSTORAGE ISSUE", error);
                }   
                
                const MeetingInfo = JSON.parse(obj.content);
                updateUI(MeetingInfo);
            }
        } 
        else {
          console.error("Error 14", xhr2.statusText);          
          alert("Errore recuperando la seduta 9");
          /*history.replace({                                
            pathname: "/init",
        });*/
        navigate("/init",{ replace: true });  
        }
      }
    };

    xhr2.onerror = function (e) {
      console.error("Error 22", xhr2.statusText);
    };
    
    let urlEncodedData = "idf=" + meetingId;
    if (localStorage.meetingCGPianeta===meetingId){            
        //console.log( "QUINDICI");
        const currentData=localStorage.getItem("meetingVersionCGPianeta");        
        urlEncodedData = urlEncodedData+ "&date=" + currentData;                    
        }    
    //console.log("TOPICLIST",urlEncodedData);
    xhr2.send(urlEncodedData);
}


    useEffect(() => {
        if(!window.cordova){            
        //CHECK AUTHENTICATION
        var xhr = new XMLHttpRequest();                
        //xhr.open('POST', properties.productionUrl+'/rest/ping',true);
        xhr.open('POST', properties.productionUrl+'/php_demo/core/rest/ping.php',true);
        xhr.withCredentials = true;
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    
        xhr.onload = function (e) {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {      
                    console.log("OK",xhr);   
                    updateSeduta(meetingId,doPostSeduta2,updateUI);                                  
                } else {
                    console.error("Error 1", xhr);         
                    localStorage.clear();           
                    /*history.push({                
                    pathname: "/login",
                    });*/
                    navigate("/login");  
                }
            }
        };
    
        xhr.onerror = function (e) {
          console.error("Error 2", xhr);
          localStorage.clear();
          /*history.push({                
                    pathname: "/login",
                    });*/
                    navigate("/login");  
        };
             
        let urlEncodedData = null; 
        xhr.send(urlEncodedData);
        //CHECK AUTHENTICATION
        
        }
        
        if (window.cordova)
        {
            updateSeduta("CGPianeta/" + meetingId,doPostSeduta2,updateUI);
        }    
              
 
    }, []);

    const topicArgument = (index) => {
        var cntCard = topicList[index]["cardIds"].length;
        var valTobeUsed=keyTopicList[index];        
        if (cntCard) {
            var cardType = topicList[index]["cardIds"][0].item_id;
            if (cardType === "../../proposteintervento/") {                
               
                navigate("/cg_pianeta/" + meetingId + "/"+ valTobeUsed);  
            } else {                
               
                navigate("/cg_pianeta/" + meetingId + "/"+ valTobeUsed);  
            }
        } else {            
            //DA MODIFICARE PER GESTIRE COMPORTAMENTO 
            //IN CASO BOX NON ABBIA NULLA AL SUO INTERNO
            alert("Non sono stati caricati approfondimenti per l'argomento selezionato.");
            //history.push({
            //    pathname: "/fascicoli/" + meetingId,
            //});
        }
    };

    return (
        <div className="content">
            {topicList.map((item, index) => (
                <div
                    className="common-list"
                    key={index}                                        
                    onClick={() => topicArgument(index)}
                    style={{                    
                        cursor: "pointer",
                        paddingLeft: "40px",
                    }}
                >
                    <p className= "textBlackSedute">
                        {item.label}
                    </p>
                    <div
                        style={{
                            color: "#879497",
                            display: "flex",
                            alignItems: "center",                                            
                        }}
                    >
                        <div
                            style={{
                                paddingRight: "25px",
                                color: "#879497",
                                display: "flex",
                            }}
                        >
                            
                            
                        <KeyboardArrowRightIcon sx={{ color: '#879497' }} fontSize="large"/>
                        </div>
                        <div
                            style={{
                                marginLeft: "10px",
                                width: "10px",
                                height: "63px",
                                background: item.color,
                                borderBottomRightRadius: "8px",
                                borderTopRightRadius: "8px",
                            }}
                        ></div>
                    </div>
                </div>
            ))}
        </div>
    );
}
