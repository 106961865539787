import React, { useState, useEffect, useContext } from "react";

import { useParams } from "react-router-dom";

//import { makeStyles } from "@material-ui/core/styles";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import Typography from '@mui/material/Typography';
import RouterContext from "../../context/RouterContext";
import {updateSeduta} from "./Utils";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LinearProgress from '@mui/material/LinearProgress';

//import { withStyles } from "@material-ui/core/styles";
import { styled } from '@mui/material/styles';

import {properties} from "../../utils/properties";
import { useNavigate } from "react-router-dom";

/*const useStyles = makeStyles((theme) => ({
    root: {
        background: "#f5f5f5",
        padding: "30px 5%",
        minHeight: "calc(100vh - 136px)",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: "100%",
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));*/
const Root = styled('div')({
    background: "#f5f5f5",
    padding: "30px 5%",
    minHeight: "calc(100vh - 136px)",
  });
  
  const Heading = styled('h1')(({ theme }) => ({
    fontSize: theme.typography.pxToRem(10),
    flexBasis: "100%",
    flexShrink: 0,
  }));
  
  const SecondaryHeading = styled('h2')(({ theme }) => ({
    fontSize: theme.typography.pxToRem(10),
    color: theme.palette.text.secondary,
  }));

export default function ScoreBoard() {
    //path="/fascicoli/:idDossier/proposteintervento/schede/:id/scoreboard"
    let { idDossier, idScheda } = useParams();
    //const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const [scoreBoard, setScoreBoard] = useState([]);
    //const history = useHistory();
    const navigate = useNavigate();
    const { pageRouter, setPageRouter } = useContext(RouterContext);
    const meetingId = idDossier;
    

    /*const BorderLinearProgress = withStyles((theme) => ({
        root: {
            height: 10,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor:
                theme.palette.grey[theme.palette.type === "light" ? 300 : 700],
        },
        bar: {
            borderRadius: 5,
            backgroundColor: "#5cb85c",
        },
    }))(LinearProgress);*/
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 300 : 700],
        '& .MuiLinearProgress-bar': {
          borderRadius: 5,
          backgroundColor: '#5cb85c',
        },
      }));

    const updateUI = function(jsonData) {      
        //console.log("de",jsonData);               
        const keyValuScores = jsonData;
        //console.log("de",keyValuScores);           
                 
        var subScores = [];
        for (var i = 0; i < keyValuScores.scoreboard.scores.length; i++) {
            var subScore = [];
            var parentScoreName =
                keyValuScores.scoreboard.scores[i]["items1Level"][0][
                    "label"
                ];
            var parentScoreMark =
                keyValuScores.scoreboard.scores[i]["items1Level"][0][
                    "number"
                ];
            var childScores = [];
            for (var j = 0;j < keyValuScores.scoreboard.scores[i]["items2Level"].length;j++) {
                var childScore = {
                    childScoreName:
                        keyValuScores.scoreboard.scores[i]["items2Level"][j]["label"],
                    childScoreMark:
                        keyValuScores.scoreboard.scores[i]["items2Level"][j]["number"],
                };
                childScores.push(childScore);
            }
            subScore = {
                parentScoreName: parentScoreName,
                parentScoreMark: parentScoreMark,
                child: childScores,
            };
            subScores.push(subScore);
        }
        setScoreBoard(subScores);
        
    }

     const doPostSeduta2 = function() {
    
        var xhr2 = new XMLHttpRequest();                
        //xhr2.open('POST', properties.productionUrl+'/rest/checkScheda',true);
        xhr2.open('POST', properties.productionUrl+'/php_demo/core/rest/checkScheda.php',true);
        xhr2.withCredentials = true;
        xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
        xhr2.onload = function (e) {      
            if (xhr2.readyState === 4) {
                if (xhr2.status === 200) {                              
                    //console.log("OK92",xhr2);
                    var responseText = xhr2.responseText;                    
                    var obj=JSON.parse(responseText);
                    //console.log("OK93",obj);          
                    if(obj.status==="204" ){ 
                        console.log("OK DOPOSTSEDUTE2 204",xhr2);  
                        const SchedaInfo = JSON.parse(localStorage.getItem("schedaContent"));
                        updateUI(SchedaInfo);
                    }              
                    else{
                        //status 201
                        console.log("OK DOPOSTSEDUTE2 201",xhr2);  
                        try{
                            localStorage.setItem("schedaContent", obj.content);
                            localStorage.setItem("scheda", idScheda);
                            localStorage.setItem("schedaVersion", obj.date);                                                                                        
                        }
                        catch(error) {
                            console.log("LOCALSTORAGE ISSUE", error);
                        }  
                        
                        const SchedaInfo = JSON.parse(obj.content);
                        updateUI(SchedaInfo);
                    }
                } 
                else {
                    console.error("Error 1", xhr2.statusText);          
                    alert("Errore recuperando la seduta");  
                }
            }
        };

        xhr2.onerror = function (e) {
            console.error("Error 22", xhr2.statusText);
        };
    
        let urlEncodedData = "ids=" + idScheda;
        if (localStorage.scheda===idScheda){                        
            const currentData=localStorage.getItem("schedaVersion");        
            urlEncodedData = urlEncodedData+ "&date=" + currentData;                    
        }    
        xhr2.send(urlEncodedData);
    }


    useEffect(() => {
        if( !window.cordova){
            
        //CHECK AUTHENTICATION
        var xhr = new XMLHttpRequest();                
        //xhr.open('POST', properties.productionUrl+'/rest/ping',true);
        xhr.open('POST', properties.productionUrl+'/php_demo/core/rest/ping.php',true);
        xhr.withCredentials = true;
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    
        xhr.onload = function (e) {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {                    
                    console.log("OK",xhr);
                    updateSeduta(meetingId,doPostSeduta2,updateUI);
                } else {
                    console.error("Error 1", xhr);  
                    localStorage.clear();                  
                    /*history.push({                
                    pathname: "/login",
                    });*/
                    navigate("/login");  
                }
            }
        };
    
        xhr.onerror = function (e) {
          console.error("Error 2", xhr);
          localStorage.clear();
          /*history.push({                
                    pathname: "/login",
                    });*/
                    navigate("/login");  
        };
             
        let urlEncodedData = null; 
        xhr.send(urlEncodedData);
        //CHECK AUTHENTICATION
        }
        
        if (window.cordova)
        {
            updateSeduta(meetingId,doPostSeduta2,updateUI);
        }
/*
        setPageRouter({
            router: history.location.pathname,
            homecolor: "#879497",
            settingcolor: "#FF5A60",
            homebordercolor: "#FFFFFF",
            settingbordercolor: "#FF5A60",
            hometitlecolor: "#879497",
            settingtitlecolor: "black",
        });*/
    }, []);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <Root>
            {scoreBoard.map((item, index) => (
                <Accordion
                    expanded={expanded === item.parentScoreName}
                    onChange={handleChange(item.parentScoreName)}
                    key={index}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                         <Heading >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alingItems: "center",
                                }}
                            >
                                <p style={{  fontWeight: "normal"}} className="text">{item.parentScoreName}</p>
                                <p style={{  fontWeight: "normal"}} className="text">{item.parentScoreMark}/10</p>
                            </div>
                            <BorderLinearProgress
                                variant="determinate"
                                value={item.parentScoreMark * 10}
                            />
                        </Heading>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography >
                            <p  style={{  fontWeight: "normal", paddingLeft:"50px", textAlign:"left"}} >di cui:</p>
                            {item.child.map((subitem, index) => (
                                <div key={index} style={{ paddingLeft:"50px"}} >
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alingItems: "center",
                                        }}
                                    >
                                        <p className="text">{subitem.childScoreName}</p>
                                        <p className="text">{subitem.childScoreMark}/10</p>
                                    </div>
                                    <BorderLinearProgress
                                        variant="determinate"
                                        value={subitem.childScoreMark * 10}
                                    />
                                </div>
                            ))}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Root>
    );
}
