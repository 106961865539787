import {React,  useRef} from "react";
//import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
//import Calendar from 'rc-year-calendar';
//import 'rc-year-calendar/locales/rc-year-calendar.it';
import RouterContext from "../../context/RouterContext";
import {updateSedute} from "./Utils";
import {properties} from "../../utils/properties";
import multiMonthPlugin from '@fullcalendar/multimonth'
import FullCalendar from '@fullcalendar/react'
import itLocale from '@fullcalendar/core/locales/it'
import interactionPlugin from "@fullcalendar/interaction" 

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';


export default function CalendarView() {
  //const history = useHistory();
  var myRef= useRef()
  const navigate = useNavigate();
  const [meetingData, setMeetingData] = useState([]);
  const [bothRoles, setBothRoles] = useState(false);
  const [threeRoles, setThreeRoles] = useState(false);
  const [secondLevelButtons, setSecondLevelButtons]= useState(false);    
  const { pageRouter, setPageRouter } = useContext(RouterContext);
  const [startingYear, setStartingYear]= useState(0);
  var lastMeeting= useRef(null);
  
  const updateUI = function(jsonData) {      
        console.log("UPDATEUI-CV");
        var entireMeeting = Object.entries(jsonData);                    
        var orderMeeting = [];
        var colore='#30720f';
        for (var i = entireMeeting.length - 1; i >= 0; i--) {          
            colore='#008FD1';  
            var dateLabel=entireMeeting[i][0].substring(4, 8).concat("-").concat(entireMeeting[i][0].substring(2, 4)).concat("-").concat(entireMeeting[i][0].substring(0, 2));
            console.log(dateLabel);
            var eachMeeting = {
                //id: entireMeeting[i][0],
                name: 'Seduta del '.concat(entireMeeting[i][1]["dataSeduta"]),                              
                //location: entireMeeting[i][0],          
                //startDate: new Date(dateLabel),
                //endDate: new Date(dateLabel),
                //title: 'Seduta del '.concat(entireMeeting[i][1]["dataSeduta"]),              
                //date:new Date(dateLabel),
                //start:new Date(dateLabel),
                //end:new Date(dateLabel),
                //color: colore,  
                //backgroundColor: colore,
                //borderColor: colore,  
                //date: new Date(dateLabel),
                date:dateLabel,
                //date:"2024-02-27",
                //color: entireMeeting[i][1]["color"],
                //backgroundColor: entireMeeting[i][1]["color"],
                //borderColor: entireMeeting[i][1]["color"],    
                color: colore,
                //backgroundColor: colore,
                //borderColor: colore,   
            };            
            orderMeeting.push(eachMeeting);            
            if(lastMeeting.current===undefined) {
                            //console.log("1",lastMeeting, eachMeeting.startDate);
                lastMeeting.current=eachMeeting.startDate;
            }
            else{
                if (lastMeeting.current < eachMeeting.startDate){                
                    lastMeeting.current=eachMeeting.startDate;              
                }
            }
        }      
        //setStartingYear(lastMeeting.current.getFullYear());        
        /*var today={
            id: 0,
            name: 'Oggi',              
            location: '',          
            startDate: new Date(),
            endDate: new Date(),
            color: '#FF0000',                                          
        };
        orderMeeting.push(today);*/
        console.log("MEETING",orderMeeting);
        setMeetingData(orderMeeting);
  }
  
   const doPostSedute2 = function() {
        console.log("doing sedute post CV");
        var xhr2 = new XMLHttpRequest();                
        //xhr2.open('POST', properties.productionUrl+'/rest/checkSeduteJson',true);
        xhr2.open('POST', properties.productionUrl+'/php_demo/core/rest/checkSeduteJsonCGPianeta.php',true);               
      
        xhr2.withCredentials = true;
        xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
        xhr2.onload = function (e) {      
            if (xhr2.readyState === 4) {
                if (xhr2.status === 200) {                              
                    //console.log("OK92",xhr2);
                    var responseText = xhr2.responseText;                    
                    var obj=JSON.parse(responseText);
                    //console.log("OK93",obj, obj.status);          
                    if(obj.status==="204" ){                             
                        console.log("OK DOPOSTSEDUTE2 204",xhr2);                                                
                        const jsonListSedute = JSON.parse(localStorage.getItem("listSeduteCGPianeta"));
                        updateUI(jsonListSedute);
                        
                    }              
                    else{
                        //status 201
                        console.log("OK DOPOSTSEDUTE2 201",xhr2);  
                        
                        try{
                        localStorage.setItem("listSeduteCGPianeta", obj.content);
                        localStorage.setItem("listSeduteVersionCGPianeta", obj.date);   
                        }
                        catch(error) {
                            console.log("LOCALSTORAGE ISSUE", error);
                        }                          
                        const jsonListSedute = JSON.parse(obj.content);
                        updateUI(jsonListSedute);                        
                    }
                } 
                else {                    
                    console.log("Errore 3: Recuperando la lista sedute o non presenti sedute CdG per il ruolo dell'utente");                      
                }
            }
        };

        xhr2.onerror = function (e) {
            console.error("Error 22", xhr2.statusText);
        };
                
        let urlEncodedData = null; //"idf=" + meetingId;       

        if (localStorage.listSeduteCGPianeta!=='' &&localStorage.listSeduteCGPianeta!==undefined){            
        //console.log( "QUINDI00");
            const currentData=localStorage.getItem("listSeduteVersionCGPianeta");        
            urlEncodedData = "date=" + currentData;                    
        }  
    
        //console.log("CALVIEW",urlEncodedData);
        xhr2.send(urlEncodedData);
    }


 
    useEffect(() => {     
        console.log("USEEFFECT CV");    
        console.log("Arrivo qui", document.referrer);  
        if(localStorage.getItem("new_ui")=== undefined || localStorage.getItem("new_ui")=== null || localStorage.getItem("new_ui")=== "false"){                    
             navigate("/cg_pianeta",{ replace: true });  
        }              
                              
        if(!window.cordova){        
        //CHECK AUTHENTICATION
            var xhr = new XMLHttpRequest();                
            //xhr.open('POST', properties.productionUrl+'/rest/ping',true);
            xhr.open('POST', properties.productionUrl+'/php_demo/core/rest/ping.php',true);
            xhr.withCredentials = true;
            xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    
            xhr.onload = function (e) {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {                         
                    console.log("OK",xhr);                       
                    updateSedute("CGPianeta",doPostSedute2, updateUI);                     
                } else {
                    console.error("Error 1", xhr);   
                    localStorage.clear();                                     
                    navigate("/login");  
                }
            }
            };
    
            xhr.onerror = function (e) {
                console.error("Error 2", xhr);
                localStorage.clear();                
                navigate("/login");  
            };
             
            let urlEncodedData = null; 
            xhr.send(urlEncodedData);
        //CHECK AUTHENTICATION
        }
        
        if (window.cordova){           
            updateSedute("CGPianeta",doPostSedute2, updateUI);  
        }
       
    }, []);

   
    function formatDate(dateString) {
        const [year, month, day] = dateString.split("-");
        return `${day}${month}${year}`;
    }
   
    const topicList = (e) => {    
        console.log("called topic list CV",e);
    //const dateOk=formatDate(e.dateStr);        
    //    navigate("/cg_pianeta/"+ dateOk);  

        const dateOk=formatDate(e.dateStr);
        const clickedCell = e.jsEvent.target;          
        const cellBackgroundColor = window.getComputedStyle(clickedCell).backgroundColor;
        console.log("SSSSS",cellBackgroundColor);
        if (cellBackgroundColor === "rgb(0, 143, 209)") { // Pianeta    
            navigate("/cg_pianeta/"+ dateOk);  
        } else {
            console.log("Nessuna logica specifica per questo colore.");
        }
    };


 
  return (
    <div style={{marginTop:"10px",paddingRight:"20px",paddingLeft:"20px"}}>
           
    <FullCalendar      
            ref={myRef}
            plugins={[ multiMonthPlugin,interactionPlugin ]}
            initialView="multiMonthYear"
            locale={itLocale}
            //multiMonthMaxColumns= {4}
            //eventBackgroundColor={'red'}
            //eventBorderColor={'red'}
            //eventTextColor={'red'}
            //eventDisplay={'background'}
            //aspectRatio={1.7} 
            height="auto"            
            multiMonthMaxColumns= {4}            
            eventDisplay={'background'}
            contentHeight="auto"     
            aspectRatio={2.5} 
            
            eventDidMount={(info) => {              
                info.el.style.backgroundColor = info.event.extendedProps.color;
                info.el.style.borderColor = info.event.extendedProps.color;
                info.el.style.opacity = "0.8"; 
            }}

            eventColor="#30720E"
            headerToolbar={{
                //left: 'prev,next today',
                right: 'prev,next today',
                center: 'title',
                left: '',
            }}
            //eventColor={'red'}
            dateClick={e => topicList(e)}
            //dateClick={topicList}
            //events={events}    
            events={meetingData}    
            />
        {/*    <style>
                {`
                .fc-toolbar.fc-header-toolbar .fc-customLabel-button {
                    background: none;
                    border: none;
                    font-size: 1.5em;
                    color: green; 
                    cursor: default;
                    font-weight: bold;
                    padding: 0;
                    //pointer-events: none;
                }
                .fc-event,.fc-event-bg {
                    background-color: #30720E !important; 
                    opacity: 1 !important;  
                    border-color: #30720E !important;
                }
                `}
            </style>  */}

      </div>
  );
  
  }  
