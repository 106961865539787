import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import RouterContext from "../../context/RouterContext";
import "../../App.css";
import Typography from '@mui/material/Typography';
import Select,{ components }  from 'react-select';
import {getQueryString, getQueryStringValue, setQueryStringValue,setQueryStringWithoutPageReload } from "./queryString";
import {ThreeDots} from 'react-loader-spinner';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {properties} from "../../utils/properties";
import CircularProgress from "@mui/material/CircularProgress";

import { TextField, IconButton, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import { Radio, Box,FormControl, FormControlLabel, RadioGroup } from '@mui/material';



export default function UpdateAttachments() {

    const isMobile = window.matchMedia("(pointer: coarse) and (hover: none)").matches;
    const { searchQS } = useLocation();
    const { pageRouter, setPageRouter } = useContext(RouterContext);
    //const history = useHistory();    
    const navigate = useNavigate();
    const [resultData, setResultData] = useState([]);
    const [occurrences, setOccurrences]=useState();    
    const [loading, setLoading]= React.useState(true);
    const [loading2, setLoading2]= React.useState(false);
    const [viewVerbali,setViewVerbali]= React.useState(true);
    const [viewError,setViewError]= React.useState(true);
    const [viewResults,setViewResults]= React.useState(true);
    const [viewButtons,setViewButtons]= React.useState(true);
    const [viewPrevious,setViewPrevious]= React.useState(true);
    const [viewNext,setViewNext]= React.useState(true);        
    const [page, setPage] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [search, setSearch]=React.useState("");
    const [sort, setSort] = useState(0);
    const [tmpValue, setTmpValue] = useState(searchValue);


    var toBeRemoved=true;
    var pag2="";
    //var tmpValue="";

    /*const handleChange = (event) => {
        setTmpValue(event.target.value);
    };*/

    const handleClear = () => {
        setTmpValue('');
        setSearchValue('');
    };
    
    /*
    const handleRequestSearch = () => {
        handleSearch2(tmpValue);
    };
    */

    const handleSearchClick = () => {
        handleSearch2(tmpValue);
    };

    const handleInputChange = (event) => {
        setTmpValue(event.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch2(tmpValue);
        }
    };

    const OptionWithIcon = ({ innerProps, label, data }) => (
        <div {...innerProps} style={{ display: 'flex', alignItems: 'center' }}>
              <span>{label}</span>
              <span style={{ marginLeft: '5px', paddingTop: '5px' }}>{data.icon}</span>      
      </div>
    );

    var dateStyle = {
        display: "flex",
        paddingLeft: "40px",
    };
    function b64EncodeUnicode(str) {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
            function toSolidBytes(match, p1) {
                return String.fromCharCode('0x' + p1);
        }));
    }

    const handleSelect = (event) => {
        console.log("handleSelect", event);   
        //
        setPage(0);     
        if (event && event.value){
            console.log("HS1",event.value);                       
            setSort(event.value);
            handleSearchWithParams(0,search,event.value);
        }
    }

    const clearAll=()=>{
        setResultData([]);
        setViewResults(false);
        setViewButtons(false);
        setViewError(false);
        setLoading2(false);
    }

    const updatePage = () => {
        //console.log("updatePage called");
        pag2=parseInt(page)-10;
        setPage(pag2);        
        setSearchValue(search);
        //console.log("PREV",pag2,search);
        //handleSearchWithParams(pag2,search);
        handleSearchWithParams(pag2,search,sort);
    }
    const updatePageNext = () => {
        //console.log("updatePageNext called");
        pag2=parseInt(page)+10;
        setPage(pag2);
        //search2=search;
        setSearchValue(search);
        //console.log("NEXT",pag2,search);
        //handleSearchWithParams(pag2,search);
        handleSearchWithParams(pag2,search,sort);
    }


    

    const handleSearch2 = (searching) => {
        console.log("handleSearch2", searching);
        setSearchValue(searching);
        //RIPULISCO LA SITUAZIONE DI EVENTUALI PRECEDENTI RICHIESTE
        setResultData([]);
        setViewResults(false);
        setViewButtons(false);
        setViewError(false);
        setLoading2(true);
        
        var search21=searching;

        //setQueryStringValue("p","0");
        var qsAnto="?p=0";

        var qsvSort=getQueryStringValue("s");
        console.log("QSVSORT",qsvSort, sort);
        if(qsvSort==undefined||qsvSort==""){
            //setQueryStringValue("s","0");            
            setSort("0");
            qsvSort=0;
        }

        //setQueryStringValue("q",search21);
        qsAnto=qsAnto+"&s="+qsvSort;
        //setQueryStringValue("q",search21);
        qsAnto=qsAnto+"&q="+search21;

        console.log(qsAnto);
        setQueryStringWithoutPageReload(qsAnto);

        //console.log("WWWWWWWW", search21);
        var search3=searching;
        if(searching.includes(" ")){
            search3=searching.replaceAll(" ", " +");
            search3="+".concat(search3);
        }
        //console.log("handling search2", search3);
        setSearch(searching);

        setPage("0");
        //console.log("1",search21);        
        //console.log("3",search3);

        //let endpoint = properties.productionUrl+"/rest/doSearch";
        let endpoint = properties.productionUrl+"/php_demo/core/rest/doSearch.php"; 
        var bodyFormData=new FormData();

        bodyFormData.append('action', 'searchJson');
        //bodyFormData.append('action', 'searchVerbali');
        //bodyFormData.append('query', b64EncodeUnicode("+Accademia +Corale"));
        //bodyFormData.append('query', b64EncodeUnicode("+Tempia"));
        bodyFormData.append('query', b64EncodeUnicode(search3));
        bodyFormData.append('start', "0");
        bodyFormData.append('sort', qsvSort);
        //bodyFormData.append('query', b64EncodeUnicode("%2BFrancesco%20%2BProf.%20%2BMoccia"));
        //bodyFormData.append('query', b64EncodeUnicode("+Francesco +Prof. +Moccia"));
        if (!window.cordova)
        {
          axios({
              method: 'post',
              url: endpoint,
              data: bodyFormData,
              withCredentials: true, 
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then(function (response) {
              //console.log("RRR",response);              
              //console.log("SERE1",tm1);
              //console.log("RRR2",response.data);
              var tm2=response.data;
              tm2=tm2.replaceAll("\n1","");
              tm2=tm2.replaceAll("\n","");           
              var obj=JSON.parse(tm2);
              //var responseArray = Object.entries(response.data.response);
              //console.log("RRR3",obj);
              //console.log("RRR3",response.data.response);;
              //console.log("RRR4",obj.response);
              //console.log("RRR5",obj.highlighting);
              //console.log("#FOUND",obj.response.numFound);
              //console.log("#START",obj.response.start);
              //setQueryStringValue("s",obj.response.start);
              var all=false;
              if(obj.response.start>=10){
                  setViewPrevious(true);
                  all=true;
              }
              else
                  setViewPrevious(false);
              if(obj.response.start>=(obj.response.numFound-10))
                  setViewNext(false);
              else{
                  setViewNext(true);
                  all=true;
              }
              if(all)
                  setViewButtons(true);
              else
                  setViewButtons(false);

              //console.log("PRIMO1",obj.response.docs[0]);
              //var id2=obj.response.docs[0].id;
              //console.log("PRIMO1-1",id2);
              //console.log("PRIMO2",obj.highlighting[id2]);
              //const temp1= Object.entries(obj);
              //console.log("RRR6",temp1);
              //console.log("RRR4",response.data.highlighting);
              var results = [];
              for (var i = 0; i < obj.response.docs.length; i++) {
                
                  var a=JSON.stringify(obj.highlighting[obj.response.docs[i].id]);
                  a=a.trim();
                  console.log("PDPM",a);
                  a=a.replaceAll("],","");
                  a=a.replace(/\\n/g," ");
                  a=a.replace(/[\n\r]/g," ");
                  a=a.replace(/\n/g," ");
                  a=a.replaceAll("{","");
                  a=a.replaceAll("[","");
                  a=a.replaceAll("]","");
                  a=a.replaceAll("}","");
                  a=a.replaceAll(":\"",": ");
                  a=a.replaceAll("\"","");
                  a=a.replaceAll("<em>","<em style='color: #30720F;'>");   
                      //console.log("WERW",a);
                  var eachResult = {
                      idSeduta: obj.response.docs[i].IdSeduta,
                      dataSeduta: obj.response.docs[i].DataSeduta,
                      idRichiesta: obj.response.docs[i].IdRichiesta,
                      denominazione: obj.response.docs[i].DenominazioneIniziativa,
                      ipotesi: obj.response.docs[i].IpotesiIntervento,
                      ragioneSociale: obj.response.docs[i].RagioneSociale,                      
                      matching: a,
                  };
                results.push(eachResult);                    
              }
              console.log("POAT",results);
              setResultData(results);
              setOccurrences(obj.response.numFound);
              setViewResults(true);              
              setLoading2(false);
          })
          .catch(function (response) {
              //toast.error("Si è verificato un errore pubblicando la seduta!");
              //console.log("Esticazzi pensa", "ci sia stato un errore");
              setViewError(true);
              setLoading2(false);
          });
        }
        else {
            var onSuccess = function(response)
            {
              //console.log("RRR",response);             
              //console.log('aa');
              //console.log(response);
              var tm2=response;
              tm2=tm2.replaceAll("\n1","");
              tm2=tm2.replaceAll("\n","");
              var obj=JSON.parse(tm2);
              //console.log(obj);              
              var all=false;
              if(obj.response.start>=10){
                  setViewPrevious(true);
                  all=true;
              }
              else
                  setViewPrevious(false);
              if(obj.response.start>=(obj.response.numFound-10))
                  setViewNext(false);
              else{
                  setViewNext(true);
                  all=true;
              }
              if(all)
                  setViewButtons(true);
              else
                  setViewButtons(false);

              //console.log("PRIMO1",obj.response.docs[0]);
              //var id2=obj.response.docs[0].id;
              //console.log("PRIMO1-1",id2);
              //console.log("PRIMO2",obj.highlighting[id2]);
              //const temp1= Object.entries(obj);
              //console.log("RRR6",temp1);
              //console.log("RRR4",response.data.highlighting);
              var results = [];
              for (var i = 0; i < obj.response.docs.length; i++) {
                  var a=JSON.stringify(obj.highlighting[obj.response.docs[i].id]);
                      a=a.trim();
                      //a=a.replaceAll("],","\n");
                      a=a.replaceAll("],","");
                      a=a.replace(/\\n/g," ");
                      a=a.replace(/[\n\r]/g," ");
                      a=a.replace(/\n/g," ");
                      a=a.replaceAll("{","");
                      a=a.replaceAll("[","");
                      a=a.replaceAll("]","");
                      a=a.replaceAll("}","");
                      a=a.replaceAll(":\"",": ");
                      a=a.replaceAll("\"","");
                      //console.log("WERW",a);
                  var eachResult = {
                      idSeduta: obj.response.docs[i].IdSeduta,
                      dataSeduta: obj.response.docs[i].DataSeduta,
                      idRichiesta: obj.response.docs[i].IdRichiesta,
                      denominazione: obj.response.docs[i].DenominazioneIniziativa,
                      ipotesi: obj.response.docs[i].IpotesiIntervento,
                      ragioneSociale: obj.response.docs[i].RagioneSociale,                      
                      matching: a,
                  };
                      results.push(eachResult);                    
              }

              console.log("POAT1",results);
              setResultData(results);
              setOccurrences(obj.response.numFound);
              setViewResults(true);              
              setLoading2(false);
            }

            var onError = function() {}
            const bodyasString = new URLSearchParams(bodyFormData).toString();
            window.search(bodyasString,onSuccess, onError);
        }
    }


//const handleSearchWithParams = (p,q) => {
    const handleSearchWithParams = (p,q,s) => {
    //RIPULISCO LA SITUAZIONE DI EVENTUALI PRECEDENTI RICHIESTE
    setResultData([]);
    setViewResults(false);
    setViewButtons(false);
    setViewError(false);
    setLoading2(true);

    //console.log("handling search with params");

    //setQueryStringValue("p",p);
    //setQueryStringValue("q",q);
    //setQueryStringValue("s",s);

    var qsAnto="?p="+p;    
    qsAnto=qsAnto+"&s="+s;    
    qsAnto=qsAnto+"&q="+q;

    var var2=false;
    if(qsAnto!=getQueryString())
        setQueryStringWithoutPageReload(qsAnto);
        
    console.log("QSANTO",qsAnto, getQueryString(),var2);
    
    //setQueryStringWithoutPageReload(qsAnto);

    console.log("handling search with params",p,q,s);

    //console.log("WWWWWWWW", search21);
    var search3=q;
    if(q.includes(" ")){
        search3=q.replaceAll(" ", " +");
        search3="+".concat(search3);
    }
    //console.log("handling search2", search3);
    //console.log("p vale",p);
    setSearch(q);
    setPage(p);
    //console.log("1",search21);
    //console.log("2",search2);
    //console.log("3",search3);

    let endpoint = properties.productionUrl+"/php_demo/core/rest/doSearch.php"; 
    //let endpoint = properties.productionUrl+"/rest/doSearch";
    var bodyFormData=new FormData();

    bodyFormData.append('action', 'searchJson');    
    bodyFormData.append('query', b64EncodeUnicode(search3));    
    bodyFormData.append('start', p); 
    bodyFormData.append('sort', s);
    if (!window.cordova){
      axios({
          method: 'post',
          url: endpoint,
          data: bodyFormData,
          withCredentials: true, 
          headers: {'Content-Type': 'multipart/form-data' }
      })
      .then(function (response) {
          //console.log("RRR",response);          
          var tm2=response.data;
          tm2=tm2.replaceAll("\n1","");
          tm2=tm2.replaceAll("\n","");          
          var obj=JSON.parse(tm2);
          
          //console.log("RRR3",obj);
          //console.log("RRR3",response.data.response);;
          //console.log("RRR4",obj.response);
          //console.log("RRR5",obj.highlighting);
          //console.log("#FOUND",obj.response.numFound);
          //console.log("#START",obj.response.start);
          var all=false;          
          if(obj.response.start>=10){
              setViewPrevious(true);
              all=true;
          }
          else
              setViewPrevious(false);
          if(obj.response.start>=(obj.response.numFound-10))
              setViewNext(false);
          else{
              setViewNext(true);
              all=true;
          }

          if(all)
              setViewButtons(true);
          else
              setViewButtons(false);

          //console.log("PRIMO1",obj.response.docs[0]);
          //var id2=obj.response.docs[0].id;
          //console.log("PRIMO1-1",id2);
          //console.log("PRIMO2",obj.highlighting[id2]);          
          //console.log("RRR6",temp1);
          //console.log("RRR4",response.data.highlighting);
          var results = [];
          for (var i = 0; i < obj.response.docs.length; i++) {
              var a=JSON.stringify(obj.highlighting[obj.response.docs[i].id]);
                  a=a.trim();                  
                  a=a.replaceAll("],","");
                  a=a.replace(/\\n/g," ");
                  a=a.replace(/[\n\r]/g," ");
                  a=a.replace(/\n/g," ");
                  a=a.replaceAll("{","");
                  a=a.replaceAll("[","");
                  a=a.replaceAll("]","");
                  a=a.replaceAll("}","");
                  a=a.replaceAll(":\"",": ");
                  a=a.replaceAll("\"","");
                  //a=a.replaceAll("<em>","<em style={{fontWeight:'bold'}}>");
                  a=a.replaceAll("<em>","<em style='color: #30720F;'>");   
                  //console.log("WERW",a);
              var eachResult = {
                  idSeduta: obj.response.docs[i].IdSeduta,
                  dataSeduta: obj.response.docs[i].DataSeduta,
                  idRichiesta: obj.response.docs[i].IdRichiesta,
                  denominazione: obj.response.docs[i].DenominazioneIniziativa,
                  ipotesi: obj.response.docs[i].IpotesiIntervento,
                  ragioneSociale: obj.response.docs[i].RagioneSociale,                  
                  matching: a,
              };
                  results.push(eachResult);                  
          }

          console.log("POAT4",results);
          setResultData(results);
          setOccurrences(obj.response.numFound);
          setViewResults(true);          
          setLoading2(false);
      })
      .catch(function (response) {
          //toast.error("Si è verificato un errore pubblicando la seduta!");
          //console.log("Esticazzi pensa", "ci sia stato un errore");
          setViewError(true);
          setLoading2(false);
      });
    }
    else {
         var onSuccess = function(response){
           //console.log("RRR",response);                     
           var tm2=response;
           tm2=tm2.replaceAll("\n1","");
           tm2=tm2.replaceAll("\n","");           
           var obj=JSON.parse(tm2);           
           //console.log("RRR3",obj);
           //console.log("RRR3",response.data.response);;
           //console.log("RRR4",obj.response);
           //console.log("RRR5",obj.highlighting);
           //console.log("#FOUND",obj.response.numFound);
           //console.log("#START",obj.response.start);
           var all=false;           
           if(obj.response.start>=10){
               setViewPrevious(true);
               all=true;
           }
           else
               setViewPrevious(false);
           if(obj.response.start>=(obj.response.numFound-10))
               setViewNext(false);
           else{
               setViewNext(true);
               all=true;
           }

           if(all)
               setViewButtons(true);
           else
               setViewButtons(false);

           //console.log("PRIMO1",obj.response.docs[0]);
           //var id2=obj.response.docs[0].id;
           //console.log("PRIMO1-1",id2);
           //console.log("PRIMO2",obj.highlighting[id2]);           
           //console.log("RRR6",temp1);
           //console.log("RRR4",response.data.highlighting);
           var results = [];
           for (var i = 0; i < obj.response.docs.length; i++) {
               var a=JSON.stringify(obj.highlighting[obj.response.docs[i].id]);
                   a=a.trim();                   
                   a=a.replaceAll("],","");
                   a=a.replace(/\\n/g," ");
                   a=a.replace(/[\n\r]/g," ");
                   a=a.replace(/\n/g," ");
                   a=a.replaceAll("{","");
                   a=a.replaceAll("[","");
                   a=a.replaceAll("]","");
                   a=a.replaceAll("}","");
                   a=a.replaceAll(":\"",": ");
                   a=a.replaceAll("\"","");                   
               var eachResult = {
                   idSeduta: obj.response.docs[i].IdSeduta,
                   dataSeduta: obj.response.docs[i].DataSeduta,
                   idRichiesta: obj.response.docs[i].IdRichiesta,
                   denominazione: obj.response.docs[i].DenominazioneIniziativa,
                   ipotesi: obj.response.docs[i].IpotesiIntervento,
                   ragioneSociale: obj.response.docs[i].RagioneSociale,                   
                   matching: a,
               };
                   results.push(eachResult);                   
           }
           console.log("POAT7",results);
           setResultData(results);
           setOccurrences(obj.response.numFound);
           setViewResults(true);           
           setLoading2(false);
         }

         var onError = function() {}

         const bodyasString = new URLSearchParams(bodyFormData).toString();

         window.search(bodyasString,onSuccess, onError);


    }
}




    useEffect(() => {
        console.log("INTO UE");
        if( !window.cordova){
        //CHECK AUTHENTICATION
            var xhr = new XMLHttpRequest();
            //xhr.open('POST', properties.productionUrl+'/rest/ping',true);
            xhr.open('POST', properties.productionUrl+'/php_demo/core/rest/ping.php',true);
            xhr.withCredentials = true;
            xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

            xhr.onload = function (e) {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                    console.log("OK",xhr);

                } else {
                    console.error("Error 1", xhr);
                    localStorage.clear();
                    /*history.push({
                    pathname: "/login",
                    });*/
                    navigate("/login");  
                }
            }
            };

            xhr.onerror = function (e) {
                console.error("Error 2", xhr);
                localStorage.clear();
                /*history.push({
                    pathname: "/login",
                    });*/
                navigate("/login");  
            };


            let urlEncodedData = null;
            xhr.send(urlEncodedData);
        //CHECK AUTHENTICATION
        }
        
        var pag1=getQueryStringValue("p");
        if(pag1==undefined)
            pag1=0;
        if(pag1!=undefined){            
            pag2=pag1;
        }
        setPage(pag2);

        var s1=getQueryStringValue("s");        
        if(s1==undefined)
            s1=1;        
        if(isNaN(s1)){            
            s1=1;
        }
        setSort(s1);

        var qs1=getQueryStringValue("q");
        console.log("QS1 and S1",qs1, s1);
        //console.log("sette",qs1);
        if(qs1!=undefined){
            if(qs1.includes("%20")){
                setSearchValue(qs1.replaceAll("%20"," "));                
                //handleSearchWithParams(pag2,qs1.replaceAll("%20"," "));
                if(isNaN(s1)){
                    console.log("1- NAN S1",qs1, s1);
                    handleSearchWithParams(pag2,qs1.replaceAll("%20"," "),"1");
                }
                else{
                    console.log("1- !NAN S1",qs1, s1);
                    handleSearchWithParams(pag2,qs1.replaceAll("%20"," "),s1);
                }
            }
            else {                
                setSearchValue(qs1);
                //handleSearchWithParams(pag2,qs1);
                if(isNaN(s1)){
                    console.log("2- NAN S1",qs1, s1);
                    handleSearchWithParams(pag2,qs1,"1");
                }
                else{
                    console.log("2- !NAN S1",qs1, s1);
                    handleSearchWithParams(pag2,qs1,s1);
                }
            }            
        }
        else{
            //console.log("otto",qs1, undefined);
            clearAll();
        }

        const doPost2 = function() {
          //console.log("getting info");
          var xhr2 = new XMLHttpRequest();
          //xhr2.open('POST', properties.productionUrl+'/rest/getInfo3',true);
          xhr2.open('POST', properties.productionUrl+'/php_demo/core/rest/getInfo6.php',true);
          xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
          xhr2.withCredentials = true;
          xhr2.onload = function (e) {
            if (xhr2.readyState === 4) {
              if (xhr2.status === 200) {
                var responseText = xhr2.responseText;
                //console.log("OK LOGIN 33");
                var obj=JSON.parse(responseText);
                var ruolo = obj.Ruolo;

                if(ruolo =="1" ||ruolo=="2"||ruolo=="10"||ruolo=="12"||ruolo=="21"){
                    setViewVerbali(true);
                }
                else
                    setViewVerbali(false);
              } else {
                console.error("Error 13", xhr2.statusText);                
              }
            }
          };

          xhr2.onerror = function (e) {
            console.error("Error 1121", xhr2.statusText);
          };

          let urlEncodedData = null;
          xhr2.send(urlEncodedData);
      }

        if (!window.cordova)
        {
          doPost2();
        }
        else {
           var onSuccess = function(response)
           {
             console.log("OK LOGIN 33");
             var obj=JSON.parse(response);
             var ruolo = obj.Ruolo;

             if(ruolo =="1" ||ruolo=="2"||ruolo=="10"||ruolo=="12"||ruolo=="21"){
                 setViewVerbali(true);
             }
             else
                 setViewVerbali(false);
           }

           var onError = function() {}

           window.getInfo3(onSuccess, onError);
        }

        if (!window.cordova)
        {
          if(localStorage.getItem("ruolo")=="1" ||localStorage.getItem("ruolo")=="2"||localStorage.getItem("ruolo")=="10"||localStorage.getItem("ruolo")=="12"||localStorage.getItem("ruolo")=="21"){
              setViewVerbali(true);
          }
          else
              setViewVerbali(false);
        }
        else {
              setViewVerbali(false);
        }        
        setViewError(false);
        setViewResults(false);
        setViewButtons(false);
        setLoading(false);

    }, []);



function handleChange1(event, role) {
    //console.log("T1T2T3",event.target, role);
    var qst=getQueryStringValue("q");
    var sr1=getQueryStringValue("s");
    //console.log("QS",);
    if(role==="ve"){
        if(qst!=undefined){            
            navigate("/searchV"+"?p=0&s="+sr1+"&q="+qst);
        }
        else{            
            navigate("/searchV");
        }
    }
    if(role==="ai"){
        if(qst!=undefined){            
            navigate("/search"+"?p=0&s="+sr1+"&q="+qst);
        }
        else{            
            navigate("/search");
        }

    }
    if(role==="pr"){
        if(qst!=undefined){
            navigate("/searchP"+"?p=0&s="+sr1+"&q="+qst);    
        }
        else{            
            navigate("/searchP");
        }

    }

}

    const topicList = (item) => {        
        var uno=item.dataSeduta;
        uno= uno.toString().replace(/\//g, '');
        if(isMobile){            
            console.log("11",uno,item,"/fascicoli/" + uno+"/proposteintervento/schede/"+item.idRichiesta);
            navigate("/fascicoli/" + uno+"/proposteintervento/schede/"+item.idRichiesta);
        }
        else{
            var url12=properties.productionUrl+"/fascicoli/" + uno+"/proposteintervento/schede/"+item.idRichiesta;
            console.log("12",uno,item,properties.productionUrl,url12);
            window.open(url12);
        }
    };


    const customStyles = {
        control: (provided) => ({
          ...provided,
          border: 'none', // Remove border
          boxShadow: 'none', // Remove shadow
          backgroundColor: '#f5f5f5',
          textAlign:'right',
          alignItems:'right',
          //width: '200px',
          width: '100%',
        }),
        indicatorSeparator: () => ({
          display: 'none', // Remove the indicator separator
        }),
        dropdownIndicator: () => ({
          display: 'none', // Remove the dropdown arrow
        }),
        menu: (provided) => ({
          ...provided,
          width: '200px', 
          //width: '100%', 
          backgroundColor: '#f5f5f5',
          textAlign:'right',
          alignItems:'right',
        }),
        singleValue: (provided) => ({
            ...provided,        
            textAlign: 'right', // Align text to the right
          }),
      };
    
      
      const options = [
        { value: '0', label: 'Pertinenza', icon: <KeyboardArrowDownIcon fontSize="medium"/> },
        { value: '1', label: 'Pertinenza', icon: <KeyboardArrowUpIcon fontSize="medium"/> },
        { value: '2', label: 'Data', icon: <KeyboardArrowDownIcon fontSize="medium"/> },
        { value: '3', label: 'Data', icon: <KeyboardArrowUpIcon fontSize="medium"/> },
      ];
    
      //const defaultValue = options[0];
      const defaultValue = sort;
    
      
      function formatOptionLabel({ label, icon }) {
        return (
          <div style={{ display: 'flex', alignItems: 'center', textAlign:'right' }}>
            <span>{label}</span>
            <span style={{ marginLeft: '5px', paddingTop: '5px' }}>{icon}</span>      
          </div>
        );
      }
      



    return (
        <div key={uuidv4()}>
        {loading ? (
            <div key={uuidv4()} style={{ width:"25%",   display: "flex",
            marginLeft: "auto", marginRight: "auto" }}>
             <ThreeDots color="#30720f" height='350' width='150'/>
             </div>
        ):(
        <div className="content" >
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
            <TextField
                value={tmpValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder="Cerca"
                variant="outlined"
                autoFocus
                sx={{
                    width: '100%',
                    maxWidth: 600,
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '24px',
                        paddingRight: '10px',
                        paddingLeft: '16px',
                        backgroundColor: 'white',
                        boxShadow: 'none',  
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#ddd',                            
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent', 
                        },
                        '&.Mui-focused': {
                            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', 
                           
                        },
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#ddd',
                    },
                    '& .MuiInputBase-input': {
                        padding: '10px 0',
                    },
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={searchValue ? handleClear : handleSearchClick}
                                aria-label={searchValue ? 'clear' : 'search'}
                            >
                                {searchValue ? <ClearIcon /> : <SearchIcon />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </Box>

  {viewVerbali ?(
      <div style={{alignItems: "left", paddingTop:"15px",paddingLeft:"10px", textAlign:"left", margin: '0 auto', maxWidth: 600}} >
          <FormControl >
                {/*<FormLabel id="demo-radio-buttons-group-label">Supporto di ricerca</FormLabel>*/}
                <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="json"
                        name="radio-buttons-group">
                    <FormControlLabel value="json" onChange={(event) => handleChange1(event, "ai")} control={<Radio size="small" style ={{color: "#30720f"}}/>} label=
                     {<Typography style={{fontSize: "14px"}}> Attività Istituzionale</Typography>} >
                                            </FormControlLabel>
                    <FormControlLabel value="premesse" onChange={(event) => handleChange1(event, "pr")} control={<Radio size="small" style ={{color: "#30720f"}}/>} label=
                     {<Typography style={{fontSize: "14px"}}> Documenti di supporto (Attività Istituzionale)</Typography>}>
                                            </FormControlLabel>
                    <FormControlLabel value="verbali" onChange={(event) => handleChange1(event, "ve")} control={<Radio  size="small" style ={{color: "#30720f"}}/>} label=
                    {<Typography style={{fontSize: "14px"}}> Verbali</Typography>}>
                    </FormControlLabel>
                </RadioGroup>
            </FormControl>
      </div>
    ):(
        <div style={{alignItems: "left", paddingTop:"15px",paddingLeft:"10px", textAlign:"left", margin: '0 auto', maxWidth: 600}} >
            <FormControl >
              {/*<FormLabel id="demo-radio-buttons-group-label">Supporto di ricerca</FormLabel>*/}
              <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="json"
                      name="radio-buttons-group">
                  <FormControlLabel value="json" onChange={(event) => handleChange1(event, "ai")} control={<Radio size="small" style ={{color: "#30720f"}}/>} label=
                   {<Typography style={{fontSize: "14px"}}> Attività Istituzionale</Typography>} >
                                          </FormControlLabel>
                  <FormControlLabel value="premesse" onChange={(event) => handleChange1(event, "pr")} control={<Radio size="small" style ={{color: "#30720f"}}/>} label=
                   {<Typography style={{fontSize: "14px"}}> Documenti di supporto (Attività Istituzionale)</Typography>}>
                                          </FormControlLabel>                
              </RadioGroup>
            </FormControl>
        </div>
    )
  }
 {loading2 ? (
    <div style={{marginRight: "20px",}}>
   <CircularProgress size={42} style={{color: '#30710F'}}  sx={{  position: 'absolute',top: 0, left: -6, zIndex: 1,}}/>
   </div>
 ):( <></> )}

  {viewError ?(
      <div style={{alignItems: "left", paddingTop:"5px",paddingLeft:"5px", textAlign:"left", margin: '0 auto', maxWidth: 600}} >
           <div className="content"  >
          <p> La ricerca di {search} non ha prodotto risultati</p>
          </div>
      </div>
    ):(
    <></>
    )
  }
  {viewResults ?(
      <div style={{alignItems: "left", paddingTop:"5px",paddingLeft:"5px", textAlign:"left", margin: '0 auto'}} >

          <div className="content" style={{paddingBottom:"0px", paddingLeft:"0px", paddingRight:"0px"}} >
          <div style={{display: "flex", paddingLeft:"0px", textAlign:"left", margin: '0 auto'}} >
          <p> Risultati relativi a {search} (Circa {occurrences} risultati)</p>
          {toBeRemoved ?(
          <div style={{ paddingTop:"6px", textAlign:"right", marginLeft: "auto", marginRight: "0"}} >
            <Select styles={customStyles} 
                id="select01"
                isSearchable={false} // Disable search functionality
                blurInputOnSelect // Prevent input focus on select                               
                options={options}
                defaultValue ={options[getQueryStringValue("s")]}
                components={{
                    Option: OptionWithIcon,
                  }}            
                formatOptionLabel={formatOptionLabel}    
                onChange={(event) => handleSelect(event)}
            />
            </div>
          ):(<></>)}
        </div>
          {resultData.map((item, index) => (
                <div
                    className="common-list"
                    key={index}
                    style={{
                                cursor: "pointer",
                            }}
                    onClick={() => topicList(item)}
                >

            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }} key={uuidv4()} >
                <div style={{ paddingLeft: "30px", textAlign: "left", paddingRight:"20px"}} key={uuidv4()}>
                            <div className= "textBlackSedute" style={{ paddingRight: "20px",paddingLeft: "20px",paddingTop: "20px", paddingBottom:"10px", fontWeight: "bold"}} key={uuidv4()}>
                                <label key={uuidv4()}>
                                {item.denominazione}
                                </label>
                            </div>
                            <div className= "textBlackSedute" style={{ paddingRight: "20px",paddingLeft: "20px",paddingTop: "10px", paddingBottom:"20px"}} key={uuidv4()}>
                                <label key={uuidv4()}>
                                            Seduta del{" "}
                                            <span className= "textBlackSedute" dangerouslySetInnerHTML={{
                                                __html: item.dataSeduta +" -- "+ item.matching,}}/>
                                </label>
                                </div>
                </div>
                    </div>
                </div>
            ))}
        </div>
      </div>
    ):(
    <></>
    )
  }
{viewButtons ?(
    <div style={{
        display: "flex",
        alignItems: "center",
        width:"25%",
        marginLeft: "auto", marginRight: "auto",
        paddingTop:"0px", marginTop:"0px"
     }}>
       {viewPrevious ?(
        <div  onClick={updatePage}>
        <p style={{ cursor: "pointer", color: "#000000", paddingRight:"20px" }}>
           Precedenti
        </p>
        </div>
       ):(<></>)}

        <div  >
        <p  style={{ color: "#000000" }}>
        Risultati
        </p>
        </div>

       {viewNext ?(
        <div onClick={updatePageNext}>
        <p  style={{ cursor: "pointer", color: "#000000", paddingLeft:"20px" }}>
           Successivi
        </p>
        </div>
       ):(<></>)}
    </div>
    ):(
    <></>
    )
  }


        </div>

    )}</div>
    );
}
