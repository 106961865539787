import React, { Component,useEffect } from "react";
//import { useHistory, useParams, } from "react-router-dom";
import { useParams, } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import {ThreeDots} from 'react-loader-spinner';
import SweetAlert from "react-bootstrap-sweetalert";
import "../../App.css";
import Select from 'react-select';

import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
import { List } from 'react-virtualized';
import {properties} from "../../utils/properties";

import '../../assets/bootstrap-custom.css';

import dayjs from 'dayjs';
import 'dayjs/locale/it';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
//import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

//import { getJsonDate} from "../../utils/utils";
import {updateSedute} from "./Utils";


export default function Presence() {
  dayjs.locale('it');  
  const navigate = useNavigate();
  const [alert, setAlert] = React.useState(null)
  let {idDossier} = useParams();
  const [loading, setLoading]= React.useState(true);
  const [loading2, setLoading2]= React.useState();
  const [disabled, setDisabled]= React.useState(false);
  const [ComitatoList, setComitatoList] = React.useState([]);
  const [RevisoriList, setRevisoriList] = React.useState([]);
  const [ComitatoPresenze, setComitatoPresenze] = React.useState([]);
  
  const [RevisoriPresenze, setRevisoriPresenze] = React.useState([]);
  
  const [availableDates, setAvailableDates] = React.useState([]);
  const [verbaleDate, setVerbaleDate] = React.useState(null);
  
  let redirectId="current";

  const organiValues =[
    {value: "10", label:"Comitato di Gestione"},
    {value: "20", label:"Consiglio Generale"},
    {value: "30", label:"Collegio Sindacale"}
    //{value: "30", label:"Altri Orgai"}
];



  const MenuList = props => {
    const rows = props.children;
    const rowRenderer = ({ key, index, isScrolling, isVisible, style }) => (
      <div key={key} style={style}>{rows[index]}</div>
    );
  
    return (
      <List
        style={{ width: '100%' }}
        width={300}
        height={300}
        rowHeight={30}
        rowCount={rows.length}
        rowRenderer={rowRenderer}
      />
    )
  }

  const customStyles = {
    control: base => ({
      ...base,
      textAlign: 'left',
      height: 55,
      minHeight: 55
    })
  };

  function handleChange1(event, idDossier) {
    if (event && event.value){
       //console.log(event, idMeeting);
       console.log("P3",event, idDossier);
       if( event.value=="10"){
            //COMITATO DI GESTIONE
            console.log("REMAINS ON COMITATO DI GESTIONE");
           
       }
       else{
            if( event.value=="20"){
              //CONSIGLIO GENERALE
              //history.push({
                navigate("/presenze2");
            }
            else{
              if( event.value=="30"){
                //COLLEGIO SINDACALE
                console.log("GO TOWARDS CONSIGLIO GENERALE");
                //history.push({
                  navigate("/presenze3");
              }
            }
        }
     }
  }

  const showAlert = () => {
    console.log("showing alert");
    setAlert(
      <SweetAlert
      warning
      style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
      title={"Impossibile accedere al foglio delle presenze per la seduta " + idDossier}
      text="ciao"      
      onConfirm={() => { navigate(-1) }}
      onCancel={() => { navigate(-1) }}
      confirmBtnCssClass="custom-btn custom-btn-success" 
      cancelBtnCssClass="custom-btn custom-btn-cancel"      
      confirmBtnText="Indietro"      
    >
    </SweetAlert>
    );
};

  const handleSubmit = e => {
    e.preventDefault();    
    setLoading(true);
    //let endpoint = properties.productionUrl+"/rest/updatePresenzeField";
    let endpoint = properties.productionUrl+"/php_demo/core/rest/updatePresenzeField.php";                             
    var bodyFormData=new FormData();
    bodyFormData.append('idf', idDossier);
    bodyFormData.append('role', 'submit');
    bodyFormData.append('index', 1);
    bodyFormData.append('value', 1);

    if (!window.cordova)
    {
        axios({
                method: 'post',
                url: endpoint,
                data: bodyFormData,
                withCredentials: true,
                headers: {'Content-Type': 'multipart/form-data' }
            })
            .then(function (response) {
                console.log(response);
                setRevisoriList(response.data.Revisori);
                setComitatoList(response.data.Comitato);
                setComitatoPresenze(response.data.presenzeComitato);
                setRevisoriPresenze(response.data.presenzeRevisori);
                setDisabled(response.data.Submitted)
                setLoading(false);
            })
            .catch(function (response) {
                console.log("Si è verificato un errore aggiornando le presenze!");
            });

    }
    else {
      var onSuccess = function(r)
      {
        var response = JSON.parse(r);
        console.log(response);
        setRevisoriList(response.Revisori);
        setComitatoList(response.Comitato);
        setComitatoPresenze(response.presenzeComitato);
        setRevisoriPresenze(response.presenzeRevisori);
        setDisabled(response.Submitted)
        setLoading(false);
      }

      var onError = function() {}

      const bodyasString = new URLSearchParams(bodyFormData).toString();

      window.updatePresenze(bodyasString,onSuccess, onError);
    }

};




  const getPresenze = (issueName) => {
    //const issueName = `Seduta del ${format(new Date(_inviatationDate), 'dd-MM-yyyy')}`
    //console.log("getPresenze", issueName);
    if (!window.cordova)
    {
          console.log("GetPresenze", issueName);
          //let endpoint = properties.productionUrl+"/rest/getPresenze";
          let endpoint = properties.productionUrl+"/php_demo/core/rest/getPresenze.php";   
          var bodyFormData=new FormData();
          //console.log("H20",issueName);
          bodyFormData.append('idf', issueName);
          axios({
              method: 'post',
              url: endpoint,
              data: bodyFormData,
              withCredentials: true, 
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then(function (response) {
              console.log("GBC", response.data);
              setRevisoriList(response.data.Revisori);
              setComitatoList(response.data.Comitato);
              setComitatoPresenze(response.data.presenzeComitato);
              setRevisoriPresenze(response.data.presenzeRevisori);
              setDisabled(response.data.Submitted);
              console.log("AQUI",issueName);
              if(issueName.length==8){
                  //ANTO 12/11/24
                  var dateTmp=issueName.substring(2,4)+"/"+issueName.substring(0,2)+"/"+issueName.substring(4,8);
                  //console.log("NEXT1",dateTmp);
                  //var dateTmp=issueName.substring(0,2)+"/"+issueName.substring(2,4)+"/"+issueName.substring(4,8);
                  console.log("NEXT1",dateTmp);
                  setVerbaleDate(dateTmp);                  
              }
              else
                  setVerbaleDate(issueName);
             
              setLoading(false);
              //setDataBar(response.data.barChart.datasets);
              setAlert(null);
          })
          .catch(function (error) {
              if (error.response) {
                //console.log(error.response.data);
                if(error.response.status===404){
                  //10102022
                  idDossier=issueName;
                  //10102022
                  setLoading(false);
                  showAlert();
              }

              }
          }


          );
    }
    else {
          var onSuccess = function(r)
          {
            console.log('notte' + r);
            var tm2=r;
            tm2.replaceAll("\n1","");
            tm2=tm2.replaceAll("\n","");
            var response = JSON.parse(tm2);
            console.log("GBC", response.data);
            setRevisoriList(response.Revisori);
            setComitatoList(response.Comitato);
            setComitatoPresenze(response.presenzeComitato);
            setRevisoriPresenze(response.presenzeRevisori);
            setDisabled(response.Submitted);
            console.log("AQUI",issueName);
            if(issueName.length==8){
                //var dateTmp=issueName.substring(2,4)+"/"+issueName.substring(0,2)+"/"+issueName.substring(4,8);
                var dateTmp=issueName.substring(2,4)+"/"+issueName.substring(0,2)+"/"+issueName.substring(4,8);
                  //console.log("NEXT1",dateTmp);
                //  var dateTmp=issueName.substring(0,2)+"/"+issueName.substring(2,4)+"/"+issueName.substring(4,8);
                  console.log("NEXT2",dateTmp);
                setVerbaleDate(dateTmp);
            }
            else
                setVerbaleDate(issueName);
            //setBarAvailable(true);
           // formatDate(idDossier)
            setLoading(false);
            //setDataBar(response.data.barChart.datasets);
            setAlert(null);
          }

          var onError = function() {}

          window.getPresenze(issueName,onSuccess, onError);
    }
}

function handleChange3(event, role, id, index) {
    //console.log("WERW",event.target, id);
    setLoading2(id);
    let val=0;
    if(event.target.checked)
        val=1

    //let endpoint = properties.productionUrl+"/rest/updatePresenzeField";
    let endpoint = properties.productionUrl+"/php_demo/core/rest/updatePresenzeField.php";      
    var bodyFormData=new FormData();
    bodyFormData.append('idf', idDossier);
    bodyFormData.append('role', role);
    bodyFormData.append('index', index);
    bodyFormData.append('value', val);

    if (!window.cordova)
    {

      axios({
              method: 'post',
              url: endpoint,
              data: bodyFormData,
              withCredentials: true, 
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then(function (response) {
              //console.log(response);
              setRevisoriList(response.data.Revisori);
              setComitatoList(response.data.Comitato);
              setComitatoPresenze(response.data.presenzeComitato);
              setRevisoriPresenze(response.data.presenzeRevisori);
              setDisabled(response.data.Submitted)
              setLoading2();
          })
          .catch(function (response) {
            console.log("si èverificato un errore aggiornando il foglio presenze");
          });
      }
      else {

        var onSuccess = function(r)
        {
          //console.log(response);
          var response = JSON.parse(r);
          setRevisoriList(response.Revisori);
          setComitatoList(response.Comitato);
          setComitatoPresenze(response.presenzeComitato);
          setRevisoriPresenze(response.presenzeRevisori);
          setDisabled(response.Submitted)
          setLoading2();
        }

        var onError = function() {}

        const bodyasString = new URLSearchParams(bodyFormData).toString();

        window.updatePresenze(bodyasString,onSuccess, onError);

      }

  }


//04042022
function formatDate(date){
    return date.substring(0,2)+"/"+date.substring(2,4)+"/"+date.substring(4,8);
}
function formatDateYMA(date){
  return date.substring(4,8)+"-"+date.substring(2,4)+"-"+date.substring(0,2);
}

/*
const resolveCurrent2 = function(jsonList){
  console.log("RC",jsonList);
  var entireMeeting = Object.entries(jsonList);
  var orderMeeting = [];
  for (var i = entireMeeting.length - 1; i >= 0; i--) {
      var eachMeeting = {
          id: entireMeeting[i][0],
          order: entireMeeting[i][1]["order"],
          date: entireMeeting[i][1]["dataSeduta"],
          };
      orderMeeting.push(eachMeeting);
      //console.log("11",eachMeeting);
      orderMeeting.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
      console.log("SHIFT",orderMeeting.shift());
      if(eachMeeting.order=="0"){
        idDossier=eachMeeting.id;
        redirectId=eachMeeting.id;
        //history.replace({
          navigate("/presenze/"+redirectId,{ replace: true });
        //idDossier=redirectId;
        getPresenze(idDossier);
      }
      //setMeetingList(orderMeeting);
      //console.log("then",entireMeeting,orderMeeting);
  }
console.log("fine resolve current2")
}
*/

const resolveCurrent2 = function(jsonList){
  console.log("RC",jsonList);
  var entireMeeting = Object.entries(jsonList);
  var orderMeeting = [];
  for (var i = entireMeeting.length - 1; i >= 0; i--) {
      var eachMeeting = {
          id: entireMeeting[i][0],
          order: entireMeeting[i][1]["order"],
          date: entireMeeting[i][1]["dataSeduta"],
          };
      orderMeeting.push(eachMeeting);
      //console.log("11",eachMeeting);      
  }
  orderMeeting.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
  var meee=orderMeeting.shift();
  console.log("SHIFT",meee);  
  idDossier=meee.id;
  redirectId=meee.id;  
  navigate("/presenze/"+redirectId,{ replace: true });  
  getPresenze(idDossier);  
console.log("fine resolve current2")
}


const doPostSedute2 = function() {
  console.log("doing sedute post111");
  var xhr2 = new XMLHttpRequest();
  //xhr2.open('POST', properties.productionUrl+'/rest/checkSeduteJson',true);
  xhr2.open('POST', properties.productionUrl+'/php_demo/core/rest/checkSeduteJson.php',true);
  xhr2.withCredentials = true;
  xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
  xhr2.onload = function (e) {
      if (xhr2.readyState === 4) {
          if (xhr2.status === 200) {
              var responseText = xhr2.responseText;
              var obj=JSON.parse(responseText);
              var jsonListSedute;
              if(obj.status==="204" ){
                console.log("OK DOPOSTSEDUTE2 204",xhr2);
                  jsonListSedute = JSON.parse(localStorage.getItem("listSedute"));
              }
              else{
                  //status 201
                  console.log("OK DOPOSTSEDUTE2 201",xhr2);
                  console.log("OK95",obj.date);

                  try{
                    localStorage.setItem("listSedute", obj.content);
                    localStorage.setItem("listSeduteVersion", obj.date);
                  }
                  catch(error) {
                    console.log("LOCALSTORAGE ISSUE", error);
                  }
                  jsonListSedute = JSON.parse(obj.content);
              }
          //
          var entireMeeting = Object.entries(jsonListSedute);
          var meeting = [];
          var meetingDates=[];
          for (var i = entireMeeting.length - 1; i >= 0; i--) {
              var dateLabel=entireMeeting[i][0].substring(4, 8).concat("-").concat(entireMeeting[i][0].substring(2, 4)).concat("-").concat(entireMeeting[i][0].substring(0, 2));
              var eachMeeting = {
                  id: entireMeeting[i][0],
                  name: dateLabel.replace("/","-"),
                  location: entireMeeting[i][0],
                  startDate: dateLabel.replace("/",""),
                  color: '#30720f',
              };
              meeting.push(eachMeeting);
              //meetingDates.push(eachMeeting.startDate);
              meetingDates.push(dayjs(eachMeeting.startDate));
          }
          console.log("AvailableDates",meetingDates);
          setAvailableDates(meetingDates);
          //setOrderMeeting(meeting);
          console.log("2231",meeting);
          console.log("2232",meetingDates);
          //
          if(window.location.href.endsWith("/current")||window.location.href.endsWith("/current/")){
            resolveCurrent2(jsonListSedute);
          }
          else{
            if(idDossier!=null){
                console.log("IDS:"+idDossier,idDossier.length);
                if(idDossier.length==8)
                    getPresenze(idDossier);
                else
                    resolveCurrent2(jsonListSedute);
            }
            else{
                console.log("IDS is null");
                resolveCurrent2(jsonListSedute);
            }
          }
        }
          else {
              console.error("Error 1", xhr2.statusText);
              alert("Errore recuperando la lista delle sedute");
          }
        console.log("fine dopostsedute2");
      }
      
  };

  xhr2.onerror = function (e) {
      console.error("Error 22", xhr2.statusText);
  };

  let urlEncodedData = null; //"idf=" + meetingId;
  if (localStorage.listSedute!=='' &&localStorage.listSedute!==undefined){
      const currentData=localStorage.getItem("listSeduteVersion");
      urlEncodedData = "date=" + currentData;
  }
  xhr2.send(urlEncodedData);
}

const handleDateChange = (date) => {
    console.log("HDC1",date);
    var rr=date.format('DDMMYYYY');
    //console.log("HDC2",rr);
    navigate("/presenze/"+rr,{ replace: true });
    setLoading(true);
    getPresenze(rr);

  };


  const updateUI = function(jsonData) {
          var entireMeeting = Object.entries(jsonData);
          var meeting = [];
          var meetingDates=[];
          for (var i = entireMeeting.length - 1; i >= 0; i--) {
              var dateLabel=entireMeeting[i][0].substring(4, 8).concat("-").concat(entireMeeting[i][0].substring(2, 4)).concat("-").concat(entireMeeting[i][0].substring(0, 2));
              var eachMeeting = {
                  id: entireMeeting[i][0],
                  name: dateLabel.replace("/","-"),
                  location: entireMeeting[i][0],
                  startDate: dateLabel.replace("/",""),
                  color: '#30720f',
              };
              meeting.push(eachMeeting);
              //meetingDates.push(eachMeeting.startDate);
              meetingDates.push(dayjs(eachMeeting.startDate));
          }
          console.log("AvailableDates",meetingDates);
          setAvailableDates(meetingDates);
          //setOrderMeeting(meeting);
          console.log("2231",meeting);
          console.log("2232",meetingDates);
          //
          if(window.location.href.endsWith("/current")||window.location.href.endsWith("/current/")){
            resolveCurrent2(jsonData);
          }
          else{
            if(idDossier!=null){
                console.log("IDS:"+idDossier);
                if(idDossier.length==8)
                    getPresenze(idDossier);
                else
                    resolveCurrent2(jsonData);
            }
            else
                console.log("IDS is null");
                resolveCurrent2(jsonData);
          }
  }
   
  const shouldDisableDate = (date) => {
    return !availableDates.some(eventDate => eventDate.isSame(date, 'day'));
  };

  
  

useEffect(() => {  
  if(!window.cordova){
      //CHECK AUTHENTICATION
      var xhr = new XMLHttpRequest();
      //xhr.open('POST', properties.productionUrl+'/rest/ping',true);
      xhr.open('POST', properties.productionUrl+'/php_demo/core/rest/ping.php',true);
      xhr.withCredentials = true;
      xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

      xhr.onload = function (e) {
          if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                  //doPostSedute();
              console.log("OK",xhr);
              doPostSedute2();
          } else {
              console.error("Error 1", xhr);
              localStorage.clear();
              //history.push({
                navigate("/login");
          }
      }
      };

      xhr.onerror = function (e) {
          console.error("Error 2", xhr);
          localStorage.clear();
          //history.push({
            navigate("/login");
      };

      let urlEncodedData = null;
      xhr.send(urlEncodedData);
      //CHECK AUTHENTICATION
    } 
    if (window.cordova){
      updateSedute("Comitato",doPostSedute2, updateUI);
    }
 
},
   []);


  return (
    <div key={uuidv4()}>
    {loading ? (
      <div key={uuidv4()} style={{ width:"25%",   display: "flex",
      marginLeft: "auto", marginRight: "auto" }}>
       <ThreeDots color="#30720f" height='350' width='150'/>
       </div>
      ):(
        <div className="content">
            <Container maxWidth="xl">
            
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it" >
            <Grid container spacing={2}>
              <Grid item xs={8} sm={6} md={8}>
                <Box display="flex" alignItems="center" height="100%">
                  <Typography variant="body1" style={{fontWeight: "bold"}}>
                    Organo relativo alla riunione:
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4} sm={6} md={4}>             
                <Select key={uuidv4()}     
                    isSearchable= {false}                                  
                    component={{MenuList}}  
                    fullWidth                 
                    styles={customStyles}                 
                    onChange={(event) => handleChange1(event, idDossier)}
                    defaultValue={{
                      label: "Comitato di Gestione",
                      value: "10"
                    }}     
                    options={organiValues}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: '#a2ee7d',
                        primary: 'black',
                      },
                    })}   

                  /> 
              </Grid>

              <Grid item xs={8} sm={6} md={8}>
                    <Box display="flex" alignItems="center" height="100%">
                    <Typography variant="body1" style={{fontWeight: "bold"}}>
                    Data della riunione:
                    </Typography>
                    </Box>
              </Grid>
              <Grid item xs={4} sm={6} md={4}>                
                  <DatePicker
                      disableToolbar                                                                        
                      onKeyDown={(e) => {
                          e.preventDefault();
                      }}
                      value={dayjs(verbaleDate)}
                      shouldDisableDate={shouldDisableDate}
                      onChange={handleDateChange}
                      sx={{width:'100%', backgroundColor:"white", "& .MuiOutlinedInput-root": {
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "black"
                          },
                         
                      } }}
                        
                      slotProps={{                          
                          field: {
                              readOnly: true
                          },
                          day: ({ day, selectedDate, isInCurrentMonth }) => {
                            const isEventDate = availableDates.some((eventDate) => eventDate.isSame(day, 'day'));
                            const isSelected =  day.isSame(selectedDate, 'day');
                            
                            return {
                              sx: {
                                '&.Mui-selected': {
                                  backgroundColor: '#429916 !important', 
                                  color: 'white',
                                  fontWeight: 'bold',
                                  '&:hover': {
                                    backgroundColor: '#30720f !important', 
                                  },
                                },
                                ...(isEventDate && !isSelected && {
                                  backgroundColor: '#30720f', 
                                  color: 'white', 
                                  borderRadius: '50%',
                                  fontWeight: 'bold',
                                  '&:hover': {
                                    backgroundColor: '#133502', 
                                  },
                                }),                          
                              },
                             
                            };
                          }                                                
                      }}                                                             
                    />
               
                 
               </Grid>
               
               <Grid item xs={12}>
                <form className="form1" onSubmit={handleSubmit}>
                    <div className="input-group">
                        <div style={{ maxWidth: "100%",  paddingTop: "10px",width: "100%", alignItems: "left",margin: "auto"}}>
                        <Typography variant="body1" style={{textAlign: "left", fontWeight: "bold", paddingBottom:"5px",paddingTop:"15px"}}>
                        Elenco dei partecipanti alla seduta del Comitato di Gestione del {formatDate(idDossier)}:
                        </Typography>                       
                            <div className="common-list" key={uuidv4()} style = {{alignItems: "center", display: "block", height: "100%", paddingTop:"20px", justifyContent: "center"}}>
                                <p style={{ fontSize: "16px", letterSpacing: "1px", width: "100%", textAlign: "left", paddingLeft:"40px"}}>
                                    Componenti Comitato di Gestione
                                </p>
                                {ComitatoList.map((item, index) => (
                                    <Grid container spacing={3} alignItems="stretch">
                                        <Grid item xs={12} sm={5}>
                                            <p style={{ fontSize: "16px", letterSpacing: "1px", width: "100%", textAlign: "left", paddingLeft:"60px"}}>
                                            {item} </p>
                                        </Grid>

                                        <Grid item xs={12} sm={3}>
                                            <FormControlLabel key={uuidv4()} style={{paddingLeft:"20px", color:"black"}}control={<Checkbox key={uuidv4()} style ={{color: "#30720f"}} disabled={disabled} checked={(ComitatoPresenze[index]=== 1) ?? false}/>} label="Presente" onChange={(event) => handleChange3(event, "comitato",item, index)}/>
                                        </Grid>
                                        <Grid item xs={12} sm={1}>
                                            {loading2==item ? (
                                                <div style={{marginRight: "20px",marginTop:"5px"}}>
                                                <CircularProgress size={22} color= "success" />
                                                </div>
                                            ):( <></> )}
                                        </Grid>
                                    </Grid>
                                ))}
                                <br />
                            </div>
                            <div className="common-list" key={uuidv4()} style = {{alignItems: "center", display: "block", height: "100%", paddingTop:"20px", justifyContent: "center"}}>
                                <p style={{ fontSize: "16px", letterSpacing: "1px", width: "100%", textAlign: "left", paddingLeft:"40px"}}>
                                Componenti Collegio Sindacale
                                </p>
                                {RevisoriList.map((item, index) => (
                                    <Grid container spacing={3} alignItems="stretch">
                                        <Grid item xs={12} sm={5}>
                                        <p style={{ fontSize: "16px", letterSpacing: "1px", width: "100%", textAlign: "left", paddingLeft:"60px"}}>
                                        {item} </p>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <FormControlLabel key={uuidv4()} style={{paddingLeft:"20px", color:"black"}}control={<Checkbox key={uuidv4()} style ={{color: "#30720f"}} disabled={disabled} checked={(RevisoriPresenze[index]=== 1) ?? false}/>} label="Presente" onChange={(event) => handleChange3(event, "revisori",item, index)}/>
                                        </Grid>
                                        <Grid item xs={12} sm={1}>
                                        {loading2==item ? (
                                                <div style={{marginRight: "20px",marginTop:"5px"}}>
                                                <CircularProgress size={22} color= "success" />
                                                </div>
                                            ):( <></> )}
                                        </Grid>
                                    </Grid>
                                ))}
                            </div>
                        </div>
                        <br />
                        {!disabled ? (
                        <button className="button11" disabled={disabled} >Invia lista partecipanti</button>
                        ):(<></>)}
                        <br /><br />
                    </div>
                </form>
                        </Grid> 
            </Grid>
            </LocalizationProvider>
            
            </Container>
      </div>
      )}

            {alert}

      </div>
  );

  }
