import React, { useState, useEffect, useContext } from "react";

import RouterContext from "../../context/RouterContext";
import "../../App.css";
import {updateSedute} from "./Utils";
import {properties} from "../../utils/properties";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from "react-router-dom";

export default function MeetingListAll() {
    var dateStyle = {
        display: "flex",
        paddingLeft: "40px",        
    };

    const [meetingData, setMeetingData] = useState([]);
    const { pageRouter, setPageRouter } = useContext(RouterContext);    
    const navigate = useNavigate();

    const isInTheFuture = function(dateOfInterest) {      
        const date = new Date();
        let day = date.getDate();
        if(day<10) 
            day="0"+month;
        let month = date.getMonth() + 1;
        if(month<10) 
            month="0"+month;
        let year = date.getFullYear();
        let today= year+""+month+""+day;        
        let meetingDate= dateOfInterest.substring(4, 8)+dateOfInterest.substring(2, 4)+dateOfInterest.substring(0, 2);
        //console.log("VERIFICA DATE:", today, meetingDate, today < meetingDate);
        if (today < meetingDate)
            return "New";
        else    
            return undefined;
    }

    const updateUI = function(jsonData) {      
        console.log("UPDATEUI-LV");
        var entireMeeting = Object.entries(jsonData);                    
        var orderMeeting = [];
        var colore='#30720f';
        for (var i = entireMeeting.length - 1; i >= 0; i--) {
            //"Entrambi", "Tetrambi","ConsColl","ComiColl"
            if(entireMeeting[i][1]["organo"]==="Entrambi"){
                var eachMeeting = {
                    id: entireMeeting[i][0],
                    order: entireMeeting[i][1]["order"],
                    date: entireMeeting[i][1]["dataSeduta"],
                    organo: "Comitato",
                    //nuovo: entireMeeting[i][1]["nuovo"],
                    nuovo: isInTheFuture(entireMeeting[i][0]),
                    otherId: entireMeeting[i][0].substring(4, 8)+
                    entireMeeting[i][0].substring(2, 4)+
                    entireMeeting[i][0].substring(0, 2),
                    color: '#30720f'
                };        
                orderMeeting.push(eachMeeting);
                var eachMeeting2 = {
                    id: entireMeeting[i][0],
                    order: entireMeeting[i][1]["order"],
                    date: entireMeeting[i][1]["dataSeduta"],
                    organo: "Consiglio",
                    //nuovo: entireMeeting[i][1]["nuovo"],
                    nuovo: isInTheFuture(entireMeeting[i][0]),
                    otherId: entireMeeting[i][0].substring(4, 8)+
                    entireMeeting[i][0].substring(2, 4)+
                    entireMeeting[i][0].substring(0, 2),
                    color: '#BF360C'
                };        
                orderMeeting.push(eachMeeting2);
            }
            if(entireMeeting[i][1]["organo"]==="ComiColl"){
                var eachMeeting = {
                    id: entireMeeting[i][0],
                    order: entireMeeting[i][1]["order"],
                    date: entireMeeting[i][1]["dataSeduta"],
                    organo: "Comitato",
                    //nuovo: entireMeeting[i][1]["nuovo"],
                    nuovo: isInTheFuture(entireMeeting[i][0]),
                    otherId: entireMeeting[i][0].substring(4, 8)+
                    entireMeeting[i][0].substring(2, 4)+
                    entireMeeting[i][0].substring(0, 2),
                    color: '#30720f'
                };        
                orderMeeting.push(eachMeeting);
                var eachMeeting2 = {
                    id: entireMeeting[i][0],
                    order: entireMeeting[i][1]["order"],
                    date: entireMeeting[i][1]["dataSeduta"],
                    organo: "Collegio",
                    //nuovo: entireMeeting[i][1]["nuovo"],
                    nuovo: isInTheFuture(entireMeeting[i][0]),
                    otherId: entireMeeting[i][0].substring(4, 8)+
                    entireMeeting[i][0].substring(2, 4)+
                    entireMeeting[i][0].substring(0, 2),
                    color: '#666999'
                };        
                orderMeeting.push(eachMeeting2);
            }
            if(entireMeeting[i][1]["organo"]==="ConsColl"){                                
                var eachMeeting2 = {
                    id: entireMeeting[i][0],
                    order: entireMeeting[i][1]["order"],
                    date: entireMeeting[i][1]["dataSeduta"],
                    organo: "Consiglio",
                    //nuovo: entireMeeting[i][1]["nuovo"],
                    nuovo: isInTheFuture(entireMeeting[i][0]),
                    otherId: entireMeeting[i][0].substring(4, 8)+
                    entireMeeting[i][0].substring(2, 4)+
                    entireMeeting[i][0].substring(0, 2),
                    color: '#BF360C'
                };        
                orderMeeting.push(eachMeeting2);
                var eachMeeting = {
                    id: entireMeeting[i][0],
                    order: entireMeeting[i][1]["order"],
                    date: entireMeeting[i][1]["dataSeduta"],
                    organo: "Collegio",
                    //nuovo: entireMeeting[i][1]["nuovo"],
                    nuovo: isInTheFuture(entireMeeting[i][0]),
                    otherId: entireMeeting[i][0].substring(4, 8)+
                    entireMeeting[i][0].substring(2, 4)+
                    entireMeeting[i][0].substring(0, 2),
                    color: '#666999'
                };      
                orderMeeting.push(eachMeeting);  
            }
            if(entireMeeting[i][1]["organo"]==="Tetrambi"){
                var eachMeeting = {
                    id: entireMeeting[i][0],
                    order: entireMeeting[i][1]["order"],
                    date: entireMeeting[i][1]["dataSeduta"],
                    organo: "Comitato",
                    //nuovo: entireMeeting[i][1]["nuovo"],
                    nuovo: isInTheFuture(entireMeeting[i][0]),
                    otherId: entireMeeting[i][0].substring(4, 8)+
                    entireMeeting[i][0].substring(2, 4)+
                    entireMeeting[i][0].substring(0, 2),
                    color: '#30720f'
                };        
                orderMeeting.push(eachMeeting);
                var eachMeeting2 = {
                    id: entireMeeting[i][0],
                    order: entireMeeting[i][1]["order"],
                    date: entireMeeting[i][1]["dataSeduta"],
                    organo: "Consiglio",
                    //nuovo: entireMeeting[i][1]["nuovo"],
                    nuovo: isInTheFuture(entireMeeting[i][0]),
                    otherId: entireMeeting[i][0].substring(4, 8)+
                    entireMeeting[i][0].substring(2, 4)+
                    entireMeeting[i][0].substring(0, 2),
                    color: '#BF360C'
                };        
                orderMeeting.push(eachMeeting2);
                var eachMeeting3 = {
                    id: entireMeeting[i][0],
                    order: entireMeeting[i][1]["order"],
                    date: entireMeeting[i][1]["dataSeduta"],
                    organo: "Collegio",
                    //nuovo: entireMeeting[i][1]["nuovo"],
                    nuovo: isInTheFuture(entireMeeting[i][0]),
                    otherId: entireMeeting[i][0].substring(4, 8)+
                    entireMeeting[i][0].substring(2, 4)+
                    entireMeeting[i][0].substring(0, 2),
                    color: '#666999'
                };        
                orderMeeting.push(eachMeeting3);
            }
            
            if(entireMeeting[i][1]["organo"]==="Consiglio"){
                colore='#BF360C';                            
                var eachMeeting = {
                    id: entireMeeting[i][0],                    
                    order: entireMeeting[i][1]["order"],                
                    date: entireMeeting[i][1]["dataSeduta"],                
                    organo: entireMeeting[i][1]["organo"],
                    //nuovo: entireMeeting[i][1]["nuovo"],
                    nuovo: isInTheFuture(entireMeeting[i][0]),
                    otherId: entireMeeting[i][0].substring(4, 8)+
                    entireMeeting[i][0].substring(2, 4)+
                    entireMeeting[i][0].substring(0, 2),
                    color: colore
                };        
                orderMeeting.push(eachMeeting);
            }
            if(entireMeeting[i][1]["organo"]==="Comitato"||entireMeeting[i][1]["organo"]== undefined){                            
                colore='#30720f';        
                var eachMeeting = {
                    id: entireMeeting[i][0],                    
                    order: entireMeeting[i][1]["order"],                
                    date: entireMeeting[i][1]["dataSeduta"],                
                    organo: entireMeeting[i][1]["organo"],
                    //nuovo: entireMeeting[i][1]["nuovo"],
                    nuovo: isInTheFuture(entireMeeting[i][0]),
                    otherId: entireMeeting[i][0].substring(4, 8)+
                    entireMeeting[i][0].substring(2, 4)+
                    entireMeeting[i][0].substring(0, 2),
                    color: colore
                };
                orderMeeting.push(eachMeeting);
            }
            if(entireMeeting[i][1]["organo"]==="Collegio" ){                            
                colore='#666999';        
                var eachMeeting = {
                    id: entireMeeting[i][0],                    
                    order: entireMeeting[i][1]["order"],                
                    date: entireMeeting[i][1]["dataSeduta"],                
                    organo: entireMeeting[i][1]["organo"],
                    //nuovo: entireMeeting[i][1]["nuovo"],
                    nuovo: isInTheFuture(entireMeeting[i][0]),
                    otherId: entireMeeting[i][0].substring(4, 8)+
                    entireMeeting[i][0].substring(2, 4)+
                    entireMeeting[i][0].substring(0, 2),
                    color: colore
                };
                orderMeeting.push(eachMeeting);
            }
        }  
        //orderMeeting.sort((a, b) => a.order > b.order ? 1 : -1)
        orderMeeting.sort((a, b) => a.otherId < b.otherId ? 1 : -1)
        setMeetingData(orderMeeting);
    }


    //6
    const doPostSedute6 = function(jsonList) {
        console.log("doing sedute post 6");        

        var xhr2 = new XMLHttpRequest();                
        
        xhr2.open('POST', properties.productionUrl+'/php_demo/core/rest/checkSeduteJsonCollegio.php',true);
        xhr2.withCredentials = true;
        xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
        xhr2.onload = function (e) {      
            if (xhr2.readyState === 4) {
                if (xhr2.status === 200) {                              
                    //console.log("OK92",xhr2);
                    var responseText = xhr2.responseText;                    
                    var obj=JSON.parse(responseText);
                    console.log("OK93",obj, obj.status);          
                    if(obj.status==="204" ){                             
                        console.log("OK DOPOSTSEDUTE6 204",xhr2);   
                        var mergedObject =undefined;                     
                        var jsonListSedute = JSON.parse(localStorage.getItem("listSeduteCollegio"));
                        console.log("BRUT",jsonListSedute,jsonList);
                        if(jsonListSedute!=undefined && jsonListSedute!=null &&jsonListSedute!=""){                          
                            console.log("2COL",jsonListSedute, "33COL", jsonList);                        
                            
                            var uno=Object.entries(jsonList);
                            var die=Object.entries(jsonListSedute);
                            for(var i = 0, l = die.length; i < l; i++) {                               
                               if(die[i][1].order==0)
                               die[i][1].nuovo ="New";   
                            }
                            for(var i = 0, l = uno.length; i < l; i++) {                                
                                if(uno[i][1].order==0)
                               uno[i][1].nuovo ="New";   
                            }
                            
                            console.log("UnoUnoCOL",uno);
                            console.log("DueUnoCOL",die);
                            
                            for(var i = 0, l = die.length; i < l; i++) {
                                for(var j = 0, ll = uno.length; j < ll; j++) {                        
                                    if(die[i][1].dataSeduta === uno[j][1].dataSeduta) {
                                        //console.log("found2");
                                        if((uno[j][1]).organo=="Entrambi")
                                            die[i][1].organo="Tetrambi";
                                        if((uno[j][1]).organo=="Consiglio")
                                            die[i][1].organo="ConsColl";
                                        if((uno[j][1]).organo==undefined)
                                            die[i][1].organo="ComiColl";
                                        //die[i][1].organo="Entrambi";
                                        if(uno[j][1].nuovo=="New")
                                            die[i][1].nuovo="New";
                                    }                                   
                                }
                            }
                            jsonListSedute=Object.fromEntries(die);
                            jsonList=Object.fromEntries(uno);
                            console.log("PUS UNO COL",jsonListSedute);
                                                                                
                            mergedObject = {
                                ...jsonList,
                                ...jsonListSedute
                              };
                            console.log("212-COL",mergedObject);
                        }
                        else    
                            mergedObject= jsonList;
                        console.log("our coll",mergedObject);
                        
                        updateUI(mergedObject);
                    }              
                    else{
                        //status 201
                        console.log("OK DOPOSTSEDUTE4 201",xhr2);  
                        
                        try{
                            localStorage.setItem("listSeduteCollegio", obj.content);
                            localStorage.setItem("listSeduteVersionCollegio", obj.date);                                      
                        }
                        catch(error) {
                            console.log("LOCALSTORAGE ISSUE", error);
                        }  
                        
                        var jsonListSedute2 = JSON.parse(obj.content);
                        var mergedObject=undefined;
                        console.log("AQUI2",jsonListSedute2,jsonList);
                        if(jsonListSedute2!=undefined && jsonListSedute2!=null &&jsonListSedute2!=""){                            

                            var uno=Object.entries(jsonList);
                            var die=Object.entries(jsonListSedute2);
                            //console.log("Uno-",uno);
                            //console.log("Due-",die);
                            for(var i = 0, l = die.length; i < l; i++) {
                                //console.log("Due-------",die[i][1]);
                                if(die[i][1].order == 0)
                                    die[i][1].nuovo = "New";                                    
                            }
                            for(var i = 0, l = uno.length; i < l; i++) {                                
                                if(uno[i][1].order == 0)
                                    uno[i][1].nuovo = "New";    
                            }                            
                            console.log("Uno",uno);
                            console.log("Due",die);                            
                            for(var i = 0, l = die.length; i < l; i++) {
                                for(var j = 0, ll = uno.length; j < ll; j++) {
                                    //console.log("CHECK2", die[i] , uno[j]);
                                    if(die[i][1].dataSeduta === uno[j][1].dataSeduta) {
                                        //console.log("found2");
                                        //die[i][1].organo="Entrambi";                                           
                                        //
                                        if((uno[j][1]).organo=="Entrambi")
                                            die[i][1].organo="Tetrambi";
                                        if((uno[j][1]).organo=="Consiglio")
                                            die[i][1].organo="ConsColl";
                                        if((uno[j][1]).organo==undefined)
                                            die[i][1].organo="ComiColl";                                
                                        if(uno[j][1].nuovo=="New")
                                            die[i][1].nuovo="New";
                                        //
                                    }                                   
                                }
                            }
                            jsonListSedute2=Object.fromEntries(die);                            
                            jsonList=Object.fromEntries(uno);
                            console.log("PUS-COL",jsonListSedute2);
                            
                            mergedObject = {
                                ...jsonList,
                                ...jsonListSedute2
                              }; 
                              
                            console.log("412-COL",mergedObject);
                        }
                            //jsonListSedute2= jsonListSedute2.concat(jsonList);
                        else    
                            //jsonListSedute2= jsonList;                        
                            mergedObject=jsonList;
                        //console.log("our",jsonListSedute2);
                        console.log("our coll",mergedObject);
                        //updateUI(jsonListSedute2);
                        updateUI(mergedObject);
                    }
                } 
                else {
                    //console.error("Error 1", xhr.statusText);          
                    //alert("Errore recuperando la lista sedute");  
                    var mergedObject=undefined;
                    console.log("Errore 23: Recuperando la lista sedute o non presenti sedute per il ruolo dell'utente");  
                    if(jsonList!=undefined && jsonList!=null &&jsonList!=""){                       
                        mergedObject = jsonList;
                        console.log("413 COL",mergedObject);
                    }                        
                    else    
                        //jsonListSedute2= jsonList;                        
                        mergedObject=null;
                    //console.log("our",jsonListSedute2);
                    console.log("our coll 2",mergedObject);
                    //updateUI(jsonListSedute2);
                    updateUI(mergedObject);
                }
            }
        };

        xhr2.onerror = function (e) {
            console.error("Error 22", xhr2.statusText);
        };
        
        let urlEncodedData = null; //"idf=" + meetingId;
        if (localStorage.listSeduteCollegio!=='' &&localStorage.listSeduteCollegio!==undefined){            
            //console.log( "QUINDI00");
            const currentData=localStorage.getItem("listSeduteVersionCollegio");        
            urlEncodedData = "date=" + currentData;                    
        }       
        //console.log("CALVIEW",urlEncodedData);
        xhr2.send(urlEncodedData);
    }
    //6


    //4
    const doPostSedute4 = function(jsonList) {
            console.log("doing sedute post 4");        

            var xhr2 = new XMLHttpRequest();                
            xhr2.open('POST', properties.productionUrl+'/php_demo/core/rest/checkSeduteJsonConsiglio.php',true);
            xhr2.withCredentials = true;        
            xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
            xhr2.onload = function (e) {      
                if (xhr2.readyState === 4) {
                    if (xhr2.status === 200) {                              
                        //console.log("OK92",xhr2);
                        var responseText = xhr2.responseText;                    
                        var obj=JSON.parse(responseText);
                        //console.log("OK93",obj, obj.status);          
                        if(obj.status==="204" ){                             
                            console.log("OK DOPOSTSEDUTE4 204",xhr2);   
                            var mergedObject =undefined;                     
                            var jsonListSedute = JSON.parse(localStorage.getItem("listSeduteConsiglio"));
                            console.log("AQUI",jsonListSedute,jsonList);
                            if(jsonListSedute!=undefined && jsonListSedute!=null &&jsonListSedute!=""){                          
                                console.log("2",jsonListSedute, "33", jsonList);
                                //const tmp23=[...jsonListSedute, ...jsonList];
                                //console.log("1",tmp23);
                                
                                var uno=Object.entries(jsonList);
                                var die=Object.entries(jsonListSedute);
                                for(var i = 0, l = die.length; i < l; i++) {                               
                                   if(die[i][1].order==0)
                                   die[i][1].nuovo ="New";   
                                }
                                for(var i = 0, l = uno.length; i < l; i++) {                                
                                    if(uno[i][1].order==0)
                                   uno[i][1].nuovo ="New";   
                                }
                                
                                console.log("UnoUno",uno);
                                console.log("DueUno",die);
                                //console.log("1looking",uno);
                                //console.log("2looking2",die);
                                //console.log("3looking3",uno.length,die.length);
                                for(var i = 0, l = die.length; i < l; i++) {
                                    for(var j = 0, ll = uno.length; j < ll; j++) {
                                        //console.log("CHECK2", die[i] , uno[j]);
                                        if(die[i][1].dataSeduta === uno[j][1].dataSeduta) {
                                            //console.log("found2");
                                            die[i][1].organo="Entrambi";
                                            if(uno[j][1].nuovo=="New")
                                                die[i][1].nuovo="New";
                                        }                                   
                                    }
                                }
                                jsonListSedute=Object.fromEntries(die);
                                jsonList=Object.fromEntries(uno);
                                console.log("PUS UNO",jsonListSedute);
                                                                                    
                                mergedObject = {
                                    ...jsonList,
                                    ...jsonListSedute
                                  };
                                console.log("212",mergedObject);
                            }
                            else    
                                mergedObject= jsonList;
                            console.log("our1",mergedObject);
                            if(localStorage.getItem("ruolo")=="12" ||localStorage.getItem("ruolo")=="21")
                                doPostSedute6(mergedObject);
                            else
                                updateUI(mergedObject);
                            //updateUI(mergedObject);
                        }              
                        else{
                            //status 201
                            console.log("OK DOPOSTSEDUTE4 201",xhr2);  
                            
                            try{
                                localStorage.setItem("listSeduteConsiglio", obj.content);
                                localStorage.setItem("listSeduteVersionConsiglio", obj.date);                                      
                            }
                            catch(error) {
                                console.log("LOCALSTORAGE ISSUE", error);
                            }  
                            
                            var jsonListSedute2 = JSON.parse(obj.content);
                            var mergedObject=undefined;
                            console.log("AQUI2",jsonListSedute2,jsonList);
                            if(jsonListSedute2!=undefined && jsonListSedute2!=null &&jsonListSedute2!=""){                            

                                var uno=Object.entries(jsonList);
                                var die=Object.entries(jsonListSedute2);
                                //console.log("Uno-",uno);
                                //console.log("Due-",die);
                                for(var i = 0, l = die.length; i < l; i++) {
                                    //console.log("Due-------",die[i][1]);
                                    if(die[i][1].order == 0)
                                        die[i][1].nuovo = "New";                                    
                                }
                                for(var i = 0, l = uno.length; i < l; i++) {                                
                                    if(uno[i][1].order == 0)
                                        uno[i][1].nuovo = "New";    
                                }                            
                                console.log("Uno",uno);
                                console.log("Due",die);                            
                                for(var i = 0, l = die.length; i < l; i++) {
                                    for(var j = 0, ll = uno.length; j < ll; j++) {
                                        //console.log("CHECK2", die[i] , uno[j]);
                                        if(die[i][1].dataSeduta === uno[j][1].dataSeduta) {
                                            //console.log("found2");
                                            die[i][1].organo="Entrambi";                                                                              
                                        }                                   
                                    }
                                }
                                jsonListSedute2=Object.fromEntries(die);                            
                                jsonList=Object.fromEntries(uno);
                                console.log("PUS",jsonListSedute2);
                                
                                mergedObject = {
                                    ...jsonList,
                                    ...jsonListSedute2
                                  }; 
                                  
                                console.log("412",mergedObject);
                            }
                                //jsonListSedute2= jsonListSedute2.concat(jsonList);
                            else    
                                //jsonListSedute2= jsonList;                        
                                mergedObject=jsonList;
                            //console.log("our",jsonListSedute2);
                            console.log("our2",mergedObject);
                            //updateUI(jsonListSedute2);
                            if(localStorage.getItem("ruolo")=="12" ||localStorage.getItem("ruolo")=="21")
                                doPostSedute6(mergedObject);
                            else    
                                updateUI(mergedObject);
                            //updateUI(mergedObject);
                        }
                    } 
                    else {
                        //console.error("Error 1", xhr.statusText);          
                        //alert("Errore recuperando la lista sedute");  
                        var mergedObject=undefined;
                        console.log("Errore 2: Recuperando la lista sedute o non presenti sedute per il ruolo dell'utente");  
                        if(jsonList!=undefined && jsonList!=null &&jsonList!=""){                       
                            mergedObject = jsonList;
                            console.log("413",mergedObject);
                        }                        
                        else    
                            //jsonListSedute2= jsonList;                        
                            mergedObject=null;
                        //console.log("our",jsonListSedute2);
                        console.log("our3",mergedObject);
                        //updateUI(jsonListSedute2);
                        if(localStorage.getItem("ruolo")=="12" ||localStorage.getItem("ruolo")=="21")
                            doPostSedute6(mergedObject);
                        else
                            updateUI(mergedObject);
                    }
                }
            };

            xhr2.onerror = function (e) {
                console.error("Error 22", xhr2.statusText);
            };
            
            let urlEncodedData = null; //"idf=" + meetingId;
            if (localStorage.listSeduteConsiglio!=='' &&localStorage.listSeduteConsiglio!==undefined){            
                //console.log( "QUINDI00");
                const currentData=localStorage.getItem("listSeduteVersionConsiglio");        
                urlEncodedData = "date=" + currentData;                    
            }       
            //console.log("CALVIEW",urlEncodedData);
            xhr2.send(urlEncodedData);
    }
    //4

    const doPostSedute2 = function() {
        console.log("doing sedute post LV");
        var xhr2 = new XMLHttpRequest();                
        //xhr2.open('POST', properties.productionUrl+'/rest/checkSeduteJson',true);
        xhr2.open('POST', properties.productionUrl+'/php_demo/core/rest/checkSeduteJson.php',true);
        xhr2.withCredentials = true;
        xhr2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");    
        xhr2.onload = function (e) {      
            if (xhr2.readyState === 4) {
                if (xhr2.status === 200) {                              
                    //console.log("OK DOPOSTSEDUTE",xhr2);
                    var responseText = xhr2.responseText;                    
                    var obj=JSON.parse(responseText);
                    //console.log("OK93",obj);          
                    if(obj.status==="204" ){ 
                        console.log("OK DOPOSTSEDUTE 204",xhr2);
                        const jsonListSedute = JSON.parse(localStorage.getItem("listSedute"));
                        //updateUI(jsonListSedute);
                        doPostSedute4(jsonListSedute);
                    }              
                    else{
                        //status 201
                        console.log("OK DOPOSTSEDUTE 201",xhr2);                            
                        try{
                            localStorage.setItem("listSedute", obj.content);
                            localStorage.setItem("listSeduteVersion", obj.date);                                                                                                                                           
                        }
                        catch(error) {
                            console.log("LOCALSTORAGE ISSUE", error);
                        }  
                        
                        const jsonListSedute = JSON.parse(obj.content);
                        //updateUI(jsonListSedute);
                         doPostSedute4(jsonListSedute);
                    }
                } 
                else {
                    //console.error("Error 1", xhr.statusText);          
                    alert("Errore recuperando la seduta");  
                    doPostSedute4(null);
                }
            }
        };

        xhr2.onerror = function (e) {
            console.error("Error 22", xhr2.statusText);
        };
        
        let urlEncodedData = null; //"idf=" + meetingId;
        if (localStorage.listSedute!=='' &&localStorage.listSedute!==undefined){            
            //console.log( "QUINDI00");
            const currentData=localStorage.getItem("listSeduteVersion");        
            urlEncodedData = "date=" + currentData;                    
        }            
        xhr2.send(urlEncodedData);
    }

    useEffect(() => {     
        console.log("USEEFFECT CV");
        console.log("Arrivo qui", document.referrer);   
        if(localStorage.getItem("new_ui")==="true"){
                //navigate("/calendar",{ replace: true });  
                navigate("/calendarAll");
        }          
                                
        if(!window.cordova){
            
        //CHECK AUTHENTICATION
            var xhr = new XMLHttpRequest();                
            //xhr.open('POST', properties.productionUrl+'/rest/ping',true);
            xhr.open('POST', properties.productionUrl+'/php_demo/core/rest/ping.php',true);
            xhr.withCredentials = true;
            xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    
            xhr.onload = function (e) {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {                                      
                        console.log("OK",xhr);                                                
                        updateSedute("Entrambi",doPostSedute2, updateUI);
                } else {
                    console.error("Error 1", xhr);     
                    localStorage.clear();               
                    console.log("QWERTY 1",localStorage.getItem("name"));
              
                    navigate("/login");
                }
            }
            };
    
            xhr.onerror = function (e) {
                console.error("Error 2", xhr);                                
                localStorage.clear();       
                console.log("QWERTY 2",localStorage.getItem("name"));                
                navigate("/login");
            };
             
            let urlEncodedData = null; 
            xhr.send(urlEncodedData);
        //CHECK AUTHENTICATION
        }
        
        if (window.cordova){
            updateSedute("All",doPostSedute2, updateUI);    
        }
                         
    }, []);


    /*const topicList = (id) => {
        console.log("called topic list LV",id);        
        let aa="/fascicoli/"+id;        
        navigate(aa);
    };*/

    const topicList = (item) => {        
        if(item.organo==='Consiglio'){         
            navigate("/consiglio/"+item.id);
        }        
        else{            
            if(item.organo==='Collegio'){             
                navigate("/collegio/"+item.id);
            }        
            else{                            
                navigate("/fascicoli/"+item.id); 
            }
        }        
    };


    return (
        <div className="content">
       
   



            {meetingData.map((item, index) => (
                <div className="scheda-item" style={{ borderRightColor: item.color,  }} > 
                <div
                    className="common-list"
                    key={index}
                    style={{
                                cursor: "pointer",
                            }}
                    onClick={() => topicList(item)}
                >
                           <div style={{cursor: "pointer",display: "flex", paddingLeft: "40px" }}>
                        <p className= "textBlackSedute">
                        Seduta del {item.date}
                        </p>
                        {item.organo==="Consiglio" ?(
                        <p className="textDataSedute"> Consiglio Generale </p>
                        ):[item.organo==="Collegio" ?(
                            <p className="textDataSedute"> Collegio Sindacale </p>
                        ): (
                            <p className="textDataSedute"> Comitato di Gestione </p>
                        )
                    ]}
                      
                    </div>
                    <div
                        style={{
                            paddingRight: "25px",
                            color: "#879497",
                            display: "flex",
                        }}
                    >
                        {item.nuovo == 'New' ? (
                            <div>
                                {item.organo==="Consiglio" ?(
                                    <div
                                    style={{
                                        //background: "#ff5a60",
                                        //background: "#30720f",
                                        background: item.color,
                                        marginRight: "40px",
                                        padding: "8px 10px",
                                        color: "white",
                                        borderRadius: "30px",
                                        fontSize: "13px",
                                    }}
                                >Nuova </div>
                                    ):(
                                        <div
                                        style={{
                                            //background: "#ff5a60",
                                            //background: "#30720f",
                                            background: item.color,
                                            marginRight: "40px",
                                            padding: "8px 10px",
                                            color: "white",
                                            borderRadius: "30px",
                                            fontSize: "13px",
                                        }}
                                    >Nuova </div>
                                )}

                            </div>
                        ) : (
                            <></>
                        )}
                        
                        <KeyboardArrowRightIcon sx={{ color: '#879497' }} fontSize="large"/>
                    </div>
                </div>
                </div>
            ))}
        </div>
    );
}
