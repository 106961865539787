import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import {ThreeDots} from 'react-loader-spinner';

import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

import SweetAlert from "react-bootstrap-sweetalert";
import {properties} from "../../utils/properties";

//import styles from "assets/jss/views/sessionManagerStyles"
import '../../assets/bootstrap-custom.css';

//const useStyles = makeStyles(styles)

export default function FreezeHandler() {    
    const [alert, setAlert] = useState(null);
    //const history = useHistory();
    const navigate = useNavigate();
    //const classes = useStyles();
     
    const handleFreeze = () => {        
        //let endpoint = properties.productionUrl+"/rest/doCons";   
        let endpoint = properties.productionUrl+"/php_demo/core/rest/doCons.php";                             
        var bodyFormData=new FormData();                      
        bodyFormData.append('action', 'freeze');    
        axios({
            method: 'post',
            url: endpoint,
            data: bodyFormData, 
            withCredentials: true, 
            headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(function (response) {
            console.log(response);
            setAlert(null);
            /*history.push({                
                pathname: "fascicoli/last",
                });*/
                navigate("/fascicoli/last");  
        })
        .catch(function (response) {            
            toast.error("Si è verificato un errore bloccando la seduta!");            
        });
    }

    const handleUnfreeze = () => {        
        //let endpoint = properties.productionUrl+"/rest/doCons";                        
        let endpoint = properties.productionUrl+"/php_demo/core/rest/doCons.php";                             
        var bodyFormData=new FormData();                      
        bodyFormData.append('action', 'unfreeze');    
        axios({
            method: 'post',
            url: endpoint,
            data: bodyFormData, 
            withCredentials: true, 
            headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(function (response) {
            console.log(response);
            setAlert(null);
            /*history.push({                
                pathname: "fascicoli/last",
                });*/
            navigate("/fascicoli/last");  
        })
        .catch(function (response) {            
            toast.error("Si è verificato un errore sbloccando la seduta!");            
        });
    }

    const showAlertBlocca = () => {
        console.log("showing alert");
        setAlert(
          <SweetAlert
          warning
          style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
          title="Bloccare la seduta?"
          onConfirm={() => handleFreeze()}          
          onCancel={() => { navigate(-1) }}
          confirmBtnCssClass="custom-btn custom-btn-success" 
          cancelBtnCssClass="custom-btn custom-btn-cancel"          
          confirmBtnText="Si"
          cancelBtnText="No"
          showCancel
        >
        </SweetAlert>
        );
    };

    const showAlertSblocca = () => {
        console.log("showing alert");
        setAlert(
          <SweetAlert
          warning
          style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
          title="Sbloccare la seduta?"
          onConfirm={() => handleUnfreeze()}
          onCancel={() => { navigate(-1) }}
          confirmBtnCssClass="custom-btn custom-btn-success" 
          cancelBtnCssClass="custom-btn custom-btn-cancel"
          confirmBtnText="Si"
          cancelBtnText="No"
          showCancel
        >
        </SweetAlert>
        );
    };
  
    const handleisFreezed = () => {        
        
        console.log("handleisFreezed");        
        setAlert(null);    
        //let endpoint = properties.productionUrl+"/rest/doCons";                        
        let endpoint = properties.productionUrl+"/php_demo/core/rest/doCons.php";                             
        var bodyFormData=new FormData();                      
        bodyFormData.append('action', 'check');    
        axios({
            method: 'post',
            url: endpoint,
            data: bodyFormData, 
            withCredentials: true, 
            headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(function (response) {
            console.log(response);
            if(response.data.idf==undefined){
                //console.log(response.data.idf);
                //console.log(response.data.freezed);
                /*history.push({                
                    pathname: "/error/200",
                    });*/
                navigate("/error/200");  
            }
            else if(response.data.idf!=undefined && response.data.freezed!=undefined && response.data.freezed==false){
                //C'è un fascicolo non freezato
                showAlertBlocca();            
            }
            else if(response.data.idf!=undefined && response.data.freezed!=undefined && response.data.freezed==true){
                showAlertSblocca();
            }
        })
        .catch(function (response) {            
            toast.error("Si è verificato un errore pubblicando la seduta!");            
        });
    }




    useEffect(() => {            
        handleisFreezed();
    }, []);





return (
  <div key={uuidv4()}>         
            <div key={uuidv4()} style={{ width:"25%",   display: "flex", 
            marginLeft: "auto", marginRight: "auto" }}> 
             <ThreeDots color="#30720f" height='350' width='150'/>
             </div> 

             <div>
                {alert}        
            </div>
    </div>
    );
}
