import React, { useEffect,useState } from "react";
import axios from 'axios';

import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import {ThreeDots} from 'react-loader-spinner';
import {properties} from "../../utils/properties";
import SweetAlert from "react-bootstrap-sweetalert";
import { useNavigate } from "react-router-dom";


export default function Rigenerator() {    
    let {idDossier} = useParams();
    const [alert, setAlert] = useState(null);
    //const history = useHistory();
    const navigate = useNavigate();
    const resolveLast = function(){

        //axios.get(properties.productionUrl+'/rest/getLast?edited')
        let endpoint = properties.productionUrl+"/php_demo/core/rest/getLast.php?edited";                         
        axios({
            method: 'get',
            url: endpoint,                
            withCredentials: true,                 
        })
        .then(res => {
        const lastId = res.data;
        idDossier=res.data;
        rigeneraSeduta();
        //console.log("resolveLast",lastId);
        //console.log("rl2",window.location.href);            
        })
    }

    const rigeneraSeduta = function(){
        
        axios.get(properties.productionUrl+'/postIDSF?IDFNOWS='+idDossier).then(response => {                        
            console.log("THEN",response);
            console.log("i should go first");
        }).catch(({response}) => {            
            console.log("CATCH",response);
            
        }).finally(() => {
            console.log("FINALLY",);
            console.log("i should go second");
            /*history.replace({                
                pathname: "/fascicoli/"+idDossier,
                });*/
                setAlert(
                    <SweetAlert
                    success
                    closeOnClickOutside ={true}
                    showCancel ={false}
                    showConfirm={true}
                    style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}                    
                    title={"La seduta è stata rigenerata con successo!"}
                    onConfirm={() => {
                        setAlert(null); 
                        navigate("/fascicoli/"+idDossier,{ replace: true });                    
                    }}           
                    onCancel={() => {
                        setAlert(null);   
                        navigate("/fascicoli/"+idDossier,{ replace: true });                      
                    }}
                    confirmBtnText={"Continua"}    
                    focusCancelBtn= {true}                    
                    cancelBtnText={"No"}                       
                    confirmBtnCssClass="custom-btn custom-btn-success" 
                    cancelBtnCssClass="custom-btn custom-btn-cancel"                                                            
                  >
                  </SweetAlert>);
                //navigate("/fascicoli/"+idDossier,{ replace: true }); 
        });
    }


  useEffect(() => {    
    console.log(window.location.href); 
    console.log(idDossier);   
    if(window.location.href.endsWith("/last")||window.location.href.endsWith("/last/")){        
        console.log("ends with last");   
        resolveLast();            
    }
    else{
        console.log("ends with an id");   
        rigeneraSeduta();
    }

    }, []);





return (
  <div key={uuidv4()}> 
        
            <div key={uuidv4()} style={{ width:"25%",   display: "flex", 
            marginLeft: "auto", marginRight: "auto" }}> 
             <ThreeDots color="#30720f" height='350' width='150'/>
             </div> 
             {alert}   
    </div>
    );
}
